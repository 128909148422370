import React, { useState } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  SafeAreaView,
  Linking,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Header from "../components/header"; // Web Header
import NewMobileHeader from "../components/newMobileHeader"; // Mobile Header
import Footer from "../components/footer";
import i18n from "../components/i18n";

const Outlets = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 flex justify-start items-center w-full"
      >
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("outlets.outlets")} />

          <View className="flex w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-16 pb-10">
            {/* Web UI */}
            <View className="hidden md:flex !h-auto">
              <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
                {i18n.t("outlets.outlets")}
              </Text>

              <View className="mt-6">
                <View className="flex flex-row bg-[#D9D9D9]">
                  <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">
                    {i18n.t("outlets.restaurant_name")}
                  </Text>
                  <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">
                    {i18n.t("outlets.address")}
                  </Text>
                  <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">
                    {i18n.t("outlets.contact")}
                  </Text>
                  <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">
                    {i18n.t("outlets.email")}
                  </Text>
                </View>
                <View className="flex flex-row bg-white">
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_1_1")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_1_2")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_1_3")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL("shanghai@imperialtreasure.com");
                    }}
                    className="w-[25%] p-5"
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_1_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
                {/* <View className="flex flex-row bg-[#F6F6F6]">
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_2_1")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_2_2")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_2_3")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(
                        "hongqiao_finechinese@imperialtreasure.com"
                      );
                    }}
                    className="w-[25%] p-5"
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_2_4")}
                    </Text>
                  </TouchableOpacity>
                </View> */}
                <View className="flex flex-row bg-[#F6F6F6]">
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_3_1")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_3_2")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_3_3")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL("finechinese_igc@imperialtreasure.com");
                    }}
                    className="w-[25%] p-5"
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_3_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View className="flex flex-row bg-white">
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_4_1")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_4_2")}
                  </Text>
                  <Text className="w-[25%] p-5 text-base font-Crimson">
                    {i18n.t("outlets.outlets_4_3")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(
                        "fineteochew_taikoo@imperialtreasure.com"
                      );
                    }}
                    className="w-[25%] p-5"
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_4_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            {/* Mobile UI */}
            <View className="md:hidden">
              <View>
                <Text className="text-black text-xl mt-1 mb-2 font-Crimson md:text-center">
                  {i18n.t("outlets.outlets_1_1")}
                </Text>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_1_2")}
                </Text>
                <View className="text-sm text-[#494949]">
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL("shanghai@imperialtreasure.com");
                    }}
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_1_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_1_3")}
                </Text>
              </View>

              <View className="w-full h-[0.5px] bg-brandgold mt-4 mb-3"></View>

              {/* <View>
                <Text className="text-black text-xl mt-1 mb-2 font-Crimson md:text-center">
                  {i18n.t("outlets.outlets_2_1")}
                </Text>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_2_2")}
                </Text>
                <View className="text-sm text-[#494949]">
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(
                        "hongqiao_finechinese@imperialtreasure.com"
                      );
                    }}
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_2_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_2_3")}
                </Text>
              </View>

              <View className="w-full h-[0.5px] bg-brandgold mt-4 mb-3"></View> */}

              <View>
                <Text className="text-black text-xl mt-1 mb-2 font-Crimson md:text-center">
                  {i18n.t("outlets.outlets_3_1")}
                </Text>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_3_2")}
                </Text>
                <View className="text-sm text-[#494949]">
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL("finechinese_igc@imperialtreasure.com");
                    }}
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_3_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_3_3")}
                </Text>
              </View>

              <View className="w-full h-[0.5px] bg-brandgold mt-4 mb-3"></View>

              <View>
                <Text className="text-black text-xl mt-1 mb-2 font-Crimson md:text-center">
                  {i18n.t("outlets.outlets_4_1")}
                </Text>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_4_2")}
                </Text>
                <View className="text-sm text-[#494949]">
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(
                        "fineteochew_taikoo@imperialtreasure.com"
                      );
                    }}
                  >
                    <Text className="text-base underline font-Crimson">
                      {i18n.t("outlets.outlets_4_4")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <Text className="text-sm text-[#494949]">
                  {i18n.t("outlets.outlets_4_3")}
                </Text>
              </View>
            </View>
          </View>

          <Footer />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Outlets;
