import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  ImageBackground,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Header from "../components/header"; // Web Header
import NewMobileHeader from "../components/newMobileHeader"; // Mobile Header
import i18n from "../components/i18n";
import AsyncStorage from "../components/AsyncStorage";

const Reservation = () => {
  const navigation = useNavigation();

  return (
    // ** Only apply to CN and KR Region **
    <View className="flex-1 md:bg-[#efefef] w-full">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 items-center content-start w-full h-full"
      >
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full relative md:bg-transparent">
          <NewMobileHeader title="Reservation" />
          <MobileHeader title={i18n.t("mbHeader.reservations")} />
          <Header />
          <ScrollView className="w-full flex-1">
            <View className="items-center content-start">
              <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-6 mt-3 md:mb-24 pb-10">
                <View>
                  <Text className="text-black text-xl mt-2 mb-2 font-Crimson md:text-center">
                    {i18n.t("cn_reservation.store1_name")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store1_address")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store1_email")}
                  </Text>
                  <Text className="text-sm text-[#494949]">
                    {i18n.t("cn_reservation.store1_contact")}
                  </Text>

                  <View className="w-full h-[0.5px] bg-brandgold mt-4 mb-3"></View>

                  <Text className="text-black text-xl mt-2 mb-2 font-Crimson md:text-center">
                    {i18n.t("cn_reservation.store2_name")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store2_address")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store2_email")}
                  </Text>
                  <Text className="text-sm text-[#494949]">
                    {i18n.t("cn_reservation.store2_contact")}
                  </Text>

                  <View className="w-full h-[0.5px] bg-brandgold mt-4 mb-3"></View>

                  <Text className="text-black text-xl mt-2 mb-2 font-Crimson md:text-center">
                    {i18n.t("cn_reservation.store3_name")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store3_address")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store3_email")}
                  </Text>
                  <Text className="text-sm text-[#494949]">
                    {i18n.t("cn_reservation.store3_contact")}
                  </Text>

                  <View className="w-full h-[0.5px] bg-brandgold mt-4 mb-3"></View>

                  <Text className="text-black text-xl mt-2 mb-2 font-Crimson md:text-center">
                    {i18n.t("cn_reservation.store4_name")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store4_address")}
                  </Text>
                  <Text className="text-sm text-[#494949] mb-1">
                    {i18n.t("cn_reservation.store4_email")}
                  </Text>
                  <Text className="text-sm text-[#494949]">
                    {i18n.t("cn_reservation.store4_contact")}
                  </Text>
                </View>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Reservation;
