import React from "react";
import {
  View,
  Text,
  ImageBackground,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Header from "../components/header"; // Web Header
import NewMobileHeader from "../components/newMobileHeader"; // Mobile Header
import Footer from "../components/footer";
import i18n from "../components/i18n";

const FAQ = () => {
  const navigation = useNavigation();

  console.log("i18n.locale : ", i18n.locale)

  return (
    <View className="flex-1 md:bg-[#efefef] w-full">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 items-center content-start w-full h-full"
      >
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full relative md:bg-transparent">
          <NewMobileHeader />
          <MobileHeader title={i18n.t("aboutUs.aboutUs")} />
          <ScrollView className="w-full flex-1">
            <Header />
            <View className="items-center content-start">
              <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 md:mt-6 md:mb-12 pb-10">
                <View className="flex">
                  <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
                    {i18n.t("aboutUs.txt1")}
                  </Text>
                  <View className="w-full h-[0.5px] bg-brandgold my-3"></View>
                </View>

                <Text className="text-sm text-[#494949] md:mt-3  mb-6 ">
                  {i18n.t("aboutUs.txt2")}
                </Text>

                <Text className="text-sm text-[#494949] mt-1 mb-6 ">
                  {i18n.t("aboutUs.txt3")}
                </Text>

                <Text className="text-sm text-[#494949] mt-1 mb-6 ">
                  {i18n.t("aboutUs.txt4")}
                </Text>

              </View>
            </View>
          </ScrollView>
          <Footer />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default FAQ;
