import React, { useState, useEffect, useRef } from "react";
import { View, ScrollView, Text, Image, TextInput, TouchableOpacity, SafeAreaView, ActivityIndicator, Modal } from "react-native";
import { useNavigation } from "@react-navigation/native";
import InsideHead from "../components/insideHeader";
import NewMobileHeader from "../components/newMobileHeader";
import Footer from "../components/footer";
import AsyncStorage from "../components/AsyncStorage";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import moment from "moment";
import { WebView } from "react-native-webview";
import i18n from "../components/i18n";
import TransactionDetails from "./transactionDetails";
import { WechatPay, WechatPaySuccess, WechatPayFaild } from "../utils/wechatpay";
import QRCode from 'react-native-qrcode-svg';

const Profile = () => {
  const navigation = useNavigation();

  const [activiateOverlay, setActiviateOverlay] = useState(false);
  const [renewOverlay, setRenewOverlay] = useState(false);
  const [memberCode, setMemberCode] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [passwordOverlay, setPasswordOverlay] = useState(false);
  const [pPassword, setPPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pPasswordErr, setPPasswordErr] = useState(false);
  const [updatePasswordErr, setUpdatePasswordErr] = useState(false);
  const [spendingSlide, setSpendingSlide] = useState({
    spendingTotal: 15,
    spendingCount: 1,
    spendingPoint: 10,
  });
  const [couponStatus, setCouponStatus] = useState({
    issued: 0,
    used: 0,
    expired: 0,
  });
  const [memberStatus, setMemberStatus] = useState("");
  const [paymentWebview, setPaymentWebview] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [paymentDetail, setPaymentDetail] = useState({
    invoiceNo: "",
    desc: "",
    amount: "",
  });
  const [snackbarActiviated, setSnackbarActiviated] = useState(false);
  const [pointsRecord, setPointsRecord] = useState({
    balance: 0,
    converted: 0,
    earned: 0,
    last_update: "",
  });
  const [snackbarPasswordUpdated, setSnackbarPasswordUpdated] = useState(false);
  const [passwordFormatErr, setPasswordFormatErr] = useState(false);
  const [couponCount, setCouponCount] = useState({ cash: 0, food: 0 });
  const [spendDateDisplay, setspendDateDisplay] = useState(true);
  const [cycleExpiryMonth, setCycleExpiryMonth] = useState();
  const [currentTierSpent, setCurrentTierSpent] = useState(0);
  const [outstandingAmt, setOutstandingAmt] = useState(0);
  const [osAmtToDiamond, setOsAmtToDiamond] = useState(0);
  const [isShowRenewBtn, setIsShowRenewBtn] = useState(false);
  const [qrcodeOverlay, setQrcodeOverlay] = useState(false)
  const [paymentQrcodeLink, setPaymentQrcodeLink] = useState("null")

  const langMap = {
    mr: "先生",
    mrs: "太太",
    mdm: "女士",
    miss: "小姐",
    dr: "博士",
  };
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    spendCardDateFormat();

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberCode(loginDetail.code);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          console.log("Customer info :", customer);

          customer.spend_required = parseInt(customer.spend_required);
          customer.spend_this_tier = parseInt(customer.spend_this_tier);

          setMember(customer);

          let spending = spendingSlide;
          console.log("Spending details :", spending);
          let totalSpending = customer.spend_required + customer.spend_this_tier;
          // console.log("totalSpending :", totalSpending)
          let slidePercent = parseInt((customer.spend_this_tier / totalSpending) * 100);
          console.log("slidePercent :", slidePercent);

          if (slidePercent > 0) {
            spending.spendingTotal = slidePercent + 1;
          } else {
            spending.spendingTotal = 0;
          }

          if (slidePercent > 5) {
            spending.spendingPoint = slidePercent;
          } else {
            spending.spendingPoint = 0;
          }

          if (slidePercent > 15) {
            spending.spendingCount = slidePercent + 15;
          }

          let couponData = {
            code: loginDetail.code,
            offset: 0,
            limit: 9999,
            region: loginDetail.region,
          };

          // couponGet(couponData, (coupons) => {
          //   setCouponStatus({
          //     issued: coupons.general?.ISSUED,
          //     used: coupons.general?.USED,
          //     expired: coupons.general?.EXPIRED,
          //   });

          //   let cash = 0;
          //   let food = 0;

          //   console.log("coupon check:", coupons.coupons.filter(c => c.status == "ISSUED").length)

          //   coupons.coupons.map((item, index) => {
          //     if (item.status == "ISSUED") {
          //       if (item.category == "Food") {
          //         food++;
          //       }

          //       if (item.category == "Cash") {
          //         cash++;
          //       }
          //     }
          //   });

          //   setCouponCount({
          //     food: food,
          //     cash: cash,
          //   });
          // });

          let couponSummary = {
            code: loginDetail.code,
            region: loginDetail.region
          }

          console.log("get coupon summary")

          CouponGetSummary(couponSummary, (coupons) => {
            setCouponStatus({
              issued: coupons.active,
              used: 0,
              expired: 0
            });

            setCouponCount({
              food: coupons.other_active,
              cash: coupons.cash_active
            })
          })

          let pointsData = {
            code: loginDetail.code,
            region: loginDetail.region,
          };

          pointsGet(pointsData, (points) => {
            console.log("points :", points);
            setPointsRecord({
              balance: points.balance,
              converted: points.converted,
              earned: points.earned,
              last_update: points.last_update,
            });
          });

          let amtData = {
            code: loginDetail.code,
            region: loginDetail.region,
          };

          RenewAmtGet(amtData, (amt) => {
            let currentTierAmt = parseInt(amt.amount);
            let amtSpentInCurrentYear = parseInt(customer.spend_this_tier);
            console.log("amtSpentInCurrentYear :", amtSpentInCurrentYear);

            setCurrentTierSpent(amtSpentInCurrentYear);

            // console.log("currentTierSpent: ", currentTierSpent)

            if (amtSpentInCurrentYear >= currentTierAmt) {
              setOutstandingAmt(0);
            } else {
              let osAmtToMaintainCurrentTier = currentTierAmt - amtSpentInCurrentYear;
              console.log("osAmtToMaintainCurrentTier :", osAmtToMaintainCurrentTier);

              setOutstandingAmt(currencyFormat(osAmtToMaintainCurrentTier));
            }
          });

          // To return an o/s amt for upgrading to Diamond:
          let customerTier = customer.tier;
          let currentTierSpent = customer.spend_this_tier;

          osAmtToDiamondTier(customerTier, currentTierSpent);

          checkMemberState(customer.memberstatus, customer.expirydate);

          checkIdollarExpiryDate(customer.expirydate);

          showRenewBtn(customer.memberstatus, customer.expirydate);

          setIsLoading(false);
        });
      }
    });

    let flag = WechatPay.isWeChat();
    if (flag) {
      WechatPay.initStoreData().then((_re) => {
        WechatPay.checkUrlQuery((re) => {
          let needCheck = WechatPay.StorageData.isNeedCheckPaymend;
          WechatPay.StorageData.isNeedCheckPaymend = false;

          if (re === WechatPaySuccess || needCheck) {
            CheckPayment();
          }
        });
      });
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  const checkChange = (val) => {
    setRowSelect(val);
    console.log(val);
  };

  const checkMemberState = (memberStatus, expirydate) => {
    if (memberStatus == null || memberStatus == "null" || memberStatus == "") {
      setMemberStatus("expired");
    } else if (memberStatus == "ACTIVATED" || memberStatus == "CREATED") {
      setMemberStatus("active");

      let date1 = moment();
      let date2 = moment(expirydate);
      let difference = date1.diff(date2, "seconds");

      console.log(difference < 0);

      if (expirydate == null || expirydate == "null" || expirydate == "") {
        setMemberStatus("expired");
        // console.log("checkMemberState : expired");
      } else {
        if (difference < 0) {
          setMemberStatus("active");
          // console.log("checkMemberState : active");
        } else {
          setMemberStatus("expired");
          // console.log("checkMemberState : expired");
        }
      }
    } else if (memberStatus == "EXPIRED") {
      setMemberStatus("expired");
    }
  };

  const showRenewBtn = (memberStatus, expirydate) => {
    if (memberStatus == "ACTIVATED" || memberStatus == "CREATED") {
      const currentDate = moment();
      const expiriedDate = moment(expirydate);
      const differenceInMonths = expiriedDate.diff(currentDate, "months", true);

      if (differenceInMonths <= 1 && differenceInMonths >= 0) {
        setIsShowRenewBtn(true);
      } else {
        setIsShowRenewBtn(false);
      }
    } else if (memberStatus == "EXPIRED") {
      setIsShowRenewBtn(false);
    }
  };

  const confirmActive = () => {
    setIsLoading(true);
    WechatPay.getConfig(memberCode, true, (status, url) => {
      // getPaymentURL(code, (pay) => {

      // if(status === WechatPaySuccess){
      //   CheckPayment()
      // }
      // if(status === WechatPayFaild){
      //   alert("Payment failed - " + result.respDesc);
      // }
      if (status == "qrcode") {
        //set qrcode url
        //open qrcode image overlay
        setActiviateOverlay(false)
        setRenewOverlay(false)
        setPaymentQrcodeLink(url)
        setQrcodeOverlay(true)
        setIsLoading(false);
        return
      }

      setIsLoading(false);
      if (status == WechatPaySuccess) {
        // setPaymentURL(url);
        // setPaymentWebview(true);
        WechatPay.StorageData.isNeedCheckPaymend = true;
        window.location = url;
      } else {
        alert("Init payment failed");
        console.log("Init payment failed");
      }
    });
  };

  // To check with relevant invoice after payment success:
  const CheckPayment = () => {
    // setIsLoading(true);
    let invoiceNo = WechatPay.StorageData?.orderinfo?.invoiceno;
    if (!invoiceNo) {
      return;
    }
    let data = {
      invoiceNo,
    };

    console.log("checkPayment-data :", data); // return an invoice number

    checkPaymentResult(data, (result) => {
      if (result.orderStatus == "FINISHED" || result.paymentStatus == "SUCCESS") {
        // invoice payment success handling:
        let actFnc = null;

        if (memberStatus == "active") {
          actFnc = renewMember;
          console.log("renew member success");
        }

        if (memberStatus == "expired" && memberStatus.tier == null) {
          // setIsLoading(true);

          let data2 = {
            code: member.code,
          };
          // console.log("data2 :", data2);

          confirmCustomer(data2, (customer) => {
            // console.log("memberStatus-customer :", customer)
            if (customer.status == "success") {
              // console.log("member :", member)
              let store = {
                code: member.code,
                username: member.country_code + member.mobile.replace(/\s/g, ""),
                region: API_REGION,
              };
              console.log("confirmCustomer-store :", store);
              AsyncStorage.storeData("mCode", JSON.stringify(store));
              setIsLoading(false);
              navigation.push("Profile");
            } else {
              console.log("Confirm Customer failure");
              alert(customer.message);
              setIsLoading(false);
            }
          });
        }

        if (memberStatus == "expired") {
          actFnc = reactivateMember;
          console.log("reactive member success");
        }

        let data = {
          code: member.code,
        };
        console.log("member.code :", member.code);

        actFnc(data, (member) => {
          console.log("Renew/Reactive success");
        });
      } else {
        // payment failure
        setIsLoading(false);
        alert("Payment failed - " + result.respDesc || result.paymentStatus || result.status || "Error");
      }
    });
  };

  const closePaymentOverlay = () => {
    CheckPayment()
    setQrcodeOverlay(false)
  }

  const checkPaymentResult = (data, callback) => {
    let formData = new FormData();

    formData.set("method", "paymentChk");
    formData.set("region", API_REGION);
    formData.set("invoiceno", data.invoiceNo);

    console.log("checkPaymentResult-formData before :", formData);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const confirmCustomer = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "confirmCust");
    formData.append("code", data.code);
    formData.append("region", API_REGION);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const confirmUpdatePassword = () => {
    let errors = [];
    setPPasswordErr(false);
    setUpdatePasswordErr(false);
    setPasswordFormatErr(false);

    if (pPassword == "") {
      console.log("pPassword error");
      errors.push("err");
      setPPasswordErr(true);
    }

    if (password == "") {
      errors.push("err");
      console.log("Password error");
      setUpdatePasswordErr(true);
    } else if (password != confirmPassword) {
      errors.push("err");
      console.log("Confirm error");
      setUpdatePasswordErr(true);
    } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/)) {
      errors.push("err");
      setPasswordFormatErr(true);
    }

    if (!errors.length) {
      setIsLoading(true);

      let loginData = {
        mobile: member.country_code + member.mobile,
        password: pPassword,
      };

      loginCheck(loginData, (check) => {
        console.log("loginCheck :", check);

        if (check.status != "success") {
          console.log("login error");
          setPPasswordErr(true);
          setIsLoading(false);
        } else {
          let updateData = {
            code: member.code,
            password: password,
          };

          updatePassword(updateData, (update) => {
            if (update.status == "success") {
              setIsLoading(false);
              setPasswordOverlay(false);
              setPPassword("");
              setPassword("");
              setConfirmPassword("");
              setSnackbarPasswordUpdated(true);
            } else {
              console.log(update);
            }
          });
        }
      });
    }
  };

  const gender = (val) => {
    switch (val) {
      case "mr":
      case "dr":
        return "Male";
        break;
      case "mrs":
      case "miss":
        return "Female";
        break;
    }
  };

  const goCouponPage = () => {
    navigation.push("Coupon");
  };

  const getPaymentURL = (data, callback) => {
    //https://webform-uat.xgate.com/webapi/imperial/middleware.php?method=payment&region=SG

    let formData = new FormData();
    formData.set("method", "payment");
    formData.set("region", memberRegion);
    formData.set("type", "renew");

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const couponGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCoupon");
    formData.set("code", data.code);
    formData.set("offset", data.offset);
    formData.set("limit", data.limit);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const CouponGetSummary = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCouponSummary");
    formData.append("code", data.code);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const pointsGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getPoints");
    formData.append("code", data.code);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const loginCheck = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "login");
    formData.append("mobile", data.mobile);
    formData.append("password", data.password);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
        callback(false);
      });
  };

  const updatePassword = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "updateCust");
    formData.append("code", data.code);
    formData.append("customer_password", data.password);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const renewMember = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "renew");
    formData.append("code", data.code);
    formData.append("region", memberRegion);

    console.log("renewMember-formData :", formData);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const reactivateMember = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "reactivate");
    formData.append("code", data.code);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const dateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
  };

  const birthDateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD-MMM-YYYY");
    } else {
      return "N/A";
    }
  };

  const optinStatus = (status) => {
    if (status == 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const capitalize = (string) => {
    if (string) {
      return string.replace(/^./, (str) => str.toUpperCase());
    } else {
      return "";
    }
  };

  const viewMemberTier = (tier) => {
    if (tier) {
      if (tier.toLowerCase() == "silver") {
        return i18n.t("profile.silver_tier");
      } else if (tier.toLowerCase() == "gold") {
        return i18n.t("profile.gold_tier");
      } else if (tier.toLowerCase() == "diamond") {
        return i18n.t("profile.diamond_tier");
      } else {
        return tier;
      }
    }
  };

  const nextTier = (tier) => {
    console.log("tier:", tier);
    if (tier) {
      if (tier.toLowerCase() == "silver") {
        return i18n.t("profile.gold_member");
      } else if (tier.toLowerCase() == "gold") {
        return i18n.t("profile.diamond_member");
      } else if (tier.toLowerCase() == "diamond") {
        return i18n.t("profile.diamond_member");
      } else {
        return tier;
      }
    }
  };

  const osAmtToDiamondTier = (tier, currentTierSpent) => {
    console.log("inside osAmtToDiamondTier");
    console.log("tier check :", tier);
    // console.log("Amount spent in current year :", currentTierSpent);

    let amtSpentInCurrentYear = currentTierSpent;
    console.log("amtSpentInCurrentYear : ", amtSpentInCurrentYear);

    if (tier) {
      if (tier.toLowerCase() == "silver") {
        setOsAmtToDiamond(parseInt(28888 - amtSpentInCurrentYear));
      } else if (tier.toLowerCase() == "gold") {
        setOsAmtToDiamond(parseInt(28888 - amtSpentInCurrentYear));
      } else {
        setOsAmtToDiamond(0);
      }
    }
  };

  const RenewAmtGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getRenew");
    formData.append("code", data.code);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const currencyFormat = (str) => {
    if (str) {
      str = str.toString();
      return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "";
    }
  };

  const spendCardDateFormat = () => {
    if (i18n.locale == "en") {
      setspendDateDisplay(true);
    } else {
      setspendDateDisplay(false);
    }
  };

  const viewCardTierColor = (tier) => {
    if (tier) {
      if (tier.toLowerCase() == "silver") {
        return <Image source={require("../assets/silver-card.png")} className="w-[230px] h-[150px] block my-auto self-center" />;
      } else if (tier.toLowerCase() == "gold") {
        return <Image source={require("../assets/gold-card.png")} className="w-[230px] h-[150px] block my-auto self-center" />;
      } else if (tier.toLowerCase() == "diamond") {
        return <Image source={require("../assets/diamond-card.png")} className="w-[230px] h-[150px] block my-auto self-center" />;
      } else {
        return tier;
      }
    }
  };

  const checkIdollarExpiryDate = (expirydate) => {
    let idollarExpiryDate = moment(expirydate).add(3, "months");
    setCycleExpiryMonth(idollarExpiryDate);
  };
  return (
    <SafeAreaView className="min-h-full bg-[#F5F5F5]" pointerEvents={!isLoading ? "auto" : "none"}>
      {isLoading && (
        <View className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full" style={{ position: "fixed" }} pointerEvents={"none"}>
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}

      <InsideHead />
      <NewMobileHeader />

      <ScrollView className="flex-1 h-auto">
        <View className="w-full md:w-4/5 max-w-7xl m-auto mt-3 pr-4 hidden md:flex">
          <TouchableOpacity
            onPress={() => {
              navigation.push("Login");
            }}
          >
            <Text className="text-brandgold w-auto h-8 leading-8 font-normal text-right font-PingFangSC">{i18n.t("profile.logout")}</Text>
          </TouchableOpacity>
        </View>

        <View className="md:hidden w-full bg-white min-h-64">
          {/* Member status, ID */}
          <View className="flex flex-row items-center justify-between mx-4 my-2">
            <View className="flex flex-row my-1 font-PingFangSC">
              {memberStatus == "active" && (
                <>
                  <Text className="text-sm text-brandgold py-1 px-1 bg-[#bd9b6036] rounded-r-sm mr-2 w-16 text-center">{i18n.t("profile.active")}</Text>
                </>
              )}

              {memberStatus == "expired" && member.tier == "Silver" && member.show_renew == "true" && (
                <>
                  <Text className="text-sm text-[#111111] py-0 px-1 bg-[#F2F3F5] rounded-r-sm mr-2 w-16 text-center">{i18n.t("profile.expired")}</Text>
                </>
              )}

              {memberStatus == "expired" && member.tier == "Gold" && (
                <>
                  <Text className="text-sm text-[#111111] py-0 px-1 bg-[#F2F3F5] rounded-r-sm mr-2 w-16 text-center">{i18n.t("profile.expired")}</Text>
                </>
              )}

              {memberStatus == "expired" && member.tier == "Diamond" && (
                <>
                  <Text className="text-sm text-[#111111] py-0 px-1 bg-[#F2F3F5] rounded-r-sm mr-2 w-16 text-center">{i18n.t("profile.expired")}</Text>
                </>
              )}
            </View>

            <View className="items-end">
              <Text className="font-semibold text-black font-Crimson">
                {i18n.t("profile.menber_id")} {member.code}
              </Text>
            </View>
          </View>

          {/* Welcome msg */}
          <View className="flex flex-row items-center justify-center mx-3 mt-2 mb-1">
            <Text className="text-brandgold text-lg font-bold">
              Welcome, {member.first_name} {member.last_name}!
            </Text>
          </View>

          {/* Expiry date */}
          <View className="flex flex-row items-center justify-center mx-3">
            {spendDateDisplay && (
              <Text className="text-sm font-semibold text-black">
                {i18n.t("profile.expires_on")} {dateFormat(member.expirydate)}
              </Text>
            )}

            {spendDateDisplay == false && (
              <Text className="text-sm font-semibold text-black">
                {dateFormat(member.expirydate)} {i18n.t("profile.spend_date_format")}
              </Text>
            )}
          </View>

          {/* Card area */}
          <View className="flex flex-row items-center justify-center mt-4 ">
            <View className="w-[50%]">
              {viewCardTierColor(member.tier)}
              {/* <Image
                source={require("../assets/gold-card.png")}
                className="w-full h-[130px] block my-auto self-center"
              /> */}
            </View>
          </View>

          {/* {member.tier == "Silver" && memberStatus == "expired" && member.show_renew == "true" && (
            <View className="flex flex-row items-center justify-center mt-[-110px] mb-[60px] w-full">
              <View className="w-[80%]">
                <TouchableOpacity className="w-full h-10 bg-[#77232E] my-1 " onPress={() => setRenewOverlay(true)}>
                  <Text className="text-white font-semibold text-lg text-center leading-10">RENEW NOW</Text>
                </TouchableOpacity>
              </View>
            </View>
          )} */}

          {/* Spending area */}
          <View className="flex flex-row items-start justify-between mx-4 mt-4">
            <View className="w-1/2 flex flex-col items-start">
              <Text className="text-3xl font-semibold text-brandgold font-Crimson">
                CNY
                {member.spend_this_tier ? currencyFormat(member.spend_this_tier) : "0"}
              </Text>

              <View className="mt-1 mb-3 items-start">
                <Text className="font-bold text-lg font-Crimson">{i18n.t("profile.total_nett_spend")}</Text>

                <Text className="text-[12px] text-black font-NunitoSans font-semibold font-Crimson">{i18n.t("profile.current_membership")}</Text>
              </View>
            </View>

            <View className="w-1/2 flex flex-col items-end">
              {member.tier == "Diamond" ? <Text className="text-3xl font-semibold text-brandgold font-Crimson">CNY {outstandingAmt}</Text> : <Text className="text-3xl font-semibold text-brandgold font-Crimson">CNY {member.spend_required ? currencyFormat(member.spend_required) : "0"}</Text>}

              <View className="mt-1 mb-3 items-end">
                <Text className="font-bold text-lg font-Crimson">{i18n.t("profile.spend_required")}</Text>

                {member.tier == "Diamond" ? (
                  <Text className="text-[12px] text-black font-Crimson font-semibold font-Crimson">
                    {i18n.t("profile.to_maintain_to")}
                    {nextTier(member.tier)}
                  </Text>
                ) : (
                  <Text className="text-[12px] text-black font-Crimson font-semibold font-Crimson">
                    {i18n.t("profile.to_upgrade_to")}
                    {nextTier(member.tier)}
                  </Text>
                )}
              </View>
            </View>
          </View>

          {/* Box top */}
          <View className="w-full h-[20px]">
            <Image source={require("../assets/box-top.png")} className="w-full h-[20px] block my-auto self-center" />
          </View>

          {/* i-Dollar balance details */}
          <View className="bg-[#D8D8D9] min-h-16">
            <View className="min-h-16">
              <View className="flex flex-row justify-between mx-4">
                <View className="1/3 text-center mt-2 mb-1">
                  <Text className="text-2xl font-bold text-brandgold font-Crimson text-center">{pointsRecord.earned}</Text>
                  <Text className="font-semibold text-black font-Crimson">{i18n.t("profile.i_dollar_earned")}</Text>
                </View>

                <View className="1/3 text-center mt-2 mb-1">
                  <Text className="text-2xl font-bold text-brandgold font-Crimson text-center">{pointsRecord.converted}</Text>
                  <Text className="font-semibold text-black font-Crimson">{i18n.t("profile.i_dollar_converted")}</Text>
                </View>

                <View className="1/3 text-center mt-2 mb-1">
                  <Text className="text-2xl font-bold text-brandgold font-Crimson text-center">{pointsRecord.balance}</Text>
                  <Text className="font-semibold text-black font-Crimson">{i18n.t("profile.i_dollar_balance")}</Text>
                </View>
              </View>
            </View>

            <View className="flex flex-col items-center justify-center mt-2 mb-3">
              <Text className="text-gray-700 mb-1 font-Crimson">
                {i18n.t("profile.i_dollar_expired_date")} {dateFormat(cycleExpiryMonth)}{" "}
              </Text>
              <Text className="text-gray-700 font-Crimson">( i.e. {i18n.t("profile.i_dollar_expired_mth")} )</Text>
            </View>
          </View>

          <View className="bg-brandgold min-h-[250px]">
            <View className="flex flex-col items-center justify-start mx-10 my-4">
              <TouchableOpacity className="item-center w-full h-10 bg-black my-1" onPress={() => navigation.push("EditProfile")}>
                <Text className="text-white font-semibold text-lg text-center leading-10 font-Crimson">{i18n.t("profile.type_btn_profile")}</Text>
              </TouchableOpacity>

              <TouchableOpacity className="item-center w-full h-10 bg-black my-1" onPress={() => navigation.push("Coupon")}>
                <Text className="text-white font-semibold text-lg text-center leading-10 font-Crimson">{i18n.t("profile.type_btn_vouchers")}</Text>
              </TouchableOpacity>

              <TouchableOpacity className="item-center w-full h-10 bg-black my-1" onPress={() => navigation.push("Transaction")}>
                <Text className="text-white font-semibold text-lg text-center leading-10 font-Crimson">{i18n.t("profile.type_btn_transactions")}</Text>
              </TouchableOpacity>

              {/* No friend referral button for CN region */}
              {/* <TouchableOpacity
                className="item-center w-full h-10 bg-black my-1"
                onPress={() => navigation.push("FriendReferral")}
              >
                <Text className="text-white font-semibold text-lg text-center leading-10 font-Crimson">
                  {i18n.t("profile.type_btn_referral")}
                </Text>
              </TouchableOpacity> */}
            </View>
          </View>
        </View>

        {/* Web UI */}
        <View className="hidden md:flex">
          {/* Client Details Area */}
          <View className="hidden md:flex w-full md:w-4/5 max-w-7xl m-auto md:bg-white p-4 md:p-5 md:mt-3 flex flex-row relative mt-6">
            <View className="w-full">
              <View className="w-11/12 flex flex-row justify-start content-center items-center mb-2">
                <Text className="text-2xl md:text-3xl text-brandgold md:text-black font-semibold font-Crimson">
                  {member.first_name} {member.last_name}
                </Text>

                <TouchableOpacity className="px-4" onPress={() => navigation.push("EditProfile")}>
                  <Image className="w-[15px] h-[16px]" source={require("../assets/icons/icon-edit.png")} />
                </TouchableOpacity>

                {/* Renew - Button */}
                {isShowRenewBtn && memberStatus == "active" && member.tier_type == "Silver" && member.show_renew == "true" && (
                  <>
                    <TouchableOpacity className="w-auto h-8 max-h-8 bg-brandgold px-4 ml-5" onPress={() => setRenewOverlay(true)}>
                      <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.click_to_renew")}​</Text>
                    </TouchableOpacity>
                  </>
                )}
                {memberStatus == "expired" && member.tier_type !== "VIP Silver" && (
                  <>
                    <TouchableOpacity className="w-auto h-8 max-h-8 bg-brandgold px-4 ml-5" onPress={() => setActiviateOverlay(true)}>
                      <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.activation_now")}​</Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>

              <Text className="text-[10px] font-normal text-[#494949] md:hidden">
                {i18n.t("profile.menber_id")} {member.code}
              </Text>

              <View className="hidden md:flex flex-row font-PingFangSC">
                {memberStatus == "active" && (
                  <>
                    <Text className="text-sm text-brandgold py-0 px-2 bg-[#bd9b6036] rounded-r-sm mr-2">
                      {viewMemberTier(member.tier)}
                      {i18n.t("profile.member")}
                    </Text>
                    <Text className="text-sm text-brandgold py-0 px-2 bg-[#bd9b6036] rounded-r-sm mr-2">{i18n.t("profile.active")}</Text>
                  </>
                )}
                {memberStatus == "expired" && (
                  <>
                    <Text className="text-sm text-[#111111] py-0 px-2 bg-[#F2F3F5] rounded-r-sm mr-2">{i18n.t("profile.expired")}</Text>
                  </>
                )}
              </View>

              {/* User's info. */}
              <View
                className="hidden md:flex flex-row justify-start content-start md:justify-between
            lg:justify-start flex-wrap mt-5"
              >
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.salutation")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{capitalize(member.title)}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.menber_id")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{member.code}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.mobile")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                    +{member.country_code}
                    {member.mobile}
                  </Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.birth")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{birthDateFormat(member.birthday)}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.password")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{i18n.t("profile.ellipsis")}</Text>
                  <TouchableOpacity onPress={() => setPasswordOverlay(true)}>
                    <Text className="text-brandgold font-NunitoSans">{i18n.t("profile.change")}</Text>
                  </TouchableOpacity>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.gender")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{capitalize(member.gender)}</Text>
                </View>
                <View className="min-w-[500px] max-w-[570px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.email")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{member.email}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.cycle_start_date")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{dateFormat(member.cyclestartdate)}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.cycle_expiry_date")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{dateFormat(member.expirydate)}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.first_joined_date")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{dateFormat(member.regdate)}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.language_preference")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{capitalize(member.language).toUpperCase()}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.optin_sms")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{optinStatus(member.mobile_optin)}</Text>
                </View>
                <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                  <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">{i18n.t("profile.optin_email")}</Text>
                  <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">{optinStatus(member.email_optin)}</Text>
                </View>
              </View>
            </View>
          </View>

          <View className="w-full md:w-4/5 px-4 md:p-0 max-w-7xl m-auto flex-wrap justify-between md:mt-4 md:flex md:flex-row">
            {/* Spending Area */}
            <View className="w-full md:w-[32%] h-auto bg-white p-5 min-h-[200px] mb-2 md:mb-0">
              <View className="flex flex-row flex-wrap items-center justify-start">
                <Text className="text-xl font-semibold text-black font-Crimson">{i18n.t("profile.spend")}​</Text>

                {spendDateDisplay && (
                  <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                    {" "}
                    {i18n.t("profile.expires_on")} {dateFormat(member.expirydate)}
                  </Text>
                )}

                {spendDateDisplay == false && (
                  <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                    {" "}
                    {dateFormat(member.expirydate)} {i18n.t("profile.spend_date_format")}
                  </Text>
                )}
              </View>

              {/* Spend slider */}
              <View className="relative my-6">
                <View className="absolute top-0 left-0 w-full h-3 bg-[#EBEAED] rounded-r-[32px] rounded-l-[32px]">
                  <View className="absolute top-[2px] left-[2px] w-4/5 h-2 bg-brandgold rounded-r-[32px] rounded-l-[32px] max-w-[100%]" style={{ width: spendingSlide.spendingTotal + "%" }}></View>
                  {/* <Text
                  className="top-[1px] absolute text-[7px] text-white"
                  style={{ left: spendingSlide.spendingCount + "%" }}
                >
                  CNY{member.spend_this_tier}
                </Text> */}
                  <View className="top-[-2px] right-0 absolute w-4 h-4 bg-[#EBEAED] rounded-full"></View>
                  <View className="top-[-2px] absolute w-4 h-4 bg-brandgold rounded-full" style={{ left: "calc(" + spendingSlide.spendingPoint + "% - 16px)" }}></View>
                  <Text className="top-[1px] right-[17px] absolute text-[7px] text-[#84818A]">CNY{member.spend_this_tier + member.spend_required}</Text>
                  
                </View>
              </View>

              <View className="flex flex-row flex-wrap items-start justify-start">
                <View className="w-1/2">
                  <Text className="text-xl font-semibold text-black font-Crimson">
                    CNY
                    {member.spend_this_tier ? currencyFormat(member.spend_this_tier) : "0"}
                  </Text>
                  <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">{i18n.t("profile.total_nett_spend")}</Text>
                  <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">{i18n.t("profile.current_membership")}</Text>
                </View>
                <View className="w-1/2">
                  <Text className="text-xl font-semibold text-black font-Crimson">
                    CNY
                    {member.spend_required ? currencyFormat(member.spend_required) : "0"}
                  </Text>
                  <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">{i18n.t("profile.spend_required")}</Text>
                  <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                    {i18n.t("profile.to_upgrade_to")}
                    {nextTier(member.tier)}
                  </Text>
                </View>
              </View>
            </View>

            {/* i-Dollar Area */}
            <View className="w-full md:w-[32%] h-auto bg-white p-5 min-h-[170px] mb-2 md:mb-0">
              <View className="flex flex-row flex-wrap items-center justify-start">
                <Text className="text-xl font-semibold text-black font-Crimson mr-1">{i18n.t("profile.i_dollar")}</Text>
                <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.last_update")}
                  {dateFormat(pointsRecord.last_update)}
                </Text>
              </View>

              <View className="flex flex-row flex-wrap items-center justify-between mt-6">
                <View class="w-[33%] text-center">
                  <Text className="text-xl font-semibold text-black font-Crimson text-center">{pointsRecord.earned}</Text>
                  <Text className="text-xs font-normal text-[#84818A] font-NunitoSans text-center">{i18n.t("profile.i_dollar_earned")}</Text>
                </View>
                <View class="w-[33%] text-center">
                  <Text className="text-xl font-semibold text-black font-Crimson text-center">{pointsRecord.converted}</Text>
                  <Text className="text-xs font-normal text-[#84818A] font-NunitoSans text-center">{i18n.t("profile.i_dollar_converted")}</Text>
                </View>
                <View class="w-[33%] text-center">
                  <Text className="text-xl font-semibold text-black font-Crimson text-center">{pointsRecord.balance}</Text>
                  <Text className="text-xs font-normal text-[#84818A] font-NunitoSans text-center">{i18n.t("profile.i_dollar_balance")}</Text>
                </View>
              </View>

              <View className="mt-5">
                <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">{i18n.t("profile.i_dollar_automatically")}</Text>
              </View>
            </View>

            {/* Vouchers Area */}
            <View className="w-full md:w-[32%] h-auto bg-white p-5">
              <View className="flex flex-row flex-wrap items-center justify-start">
                <Text className="text-xl font-semibold text-black font-Crimson mr-1">{i18n.t("profile.vouchers")}</Text>
                <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {" "}
                  {couponStatus.issued} {i18n.t("profile.currently_active")}
                </Text>
              </View>

              <View className="flex flex-row lg:flex-row flex-wrap items-start lg:items-center justify-between">
                <TouchableOpacity className="w-1/2" onPress={() => goCouponPage()}>
                  <View className="flex flex-row items-start justify-start mt-5">
                    <View>
                      <Image className="mr-1 w-6 h-6" source={require("../assets/account_balance_wallet.png")} />
                    </View>
                    <View>
                      <Text className="text-xl font-semibold text-black font-Crimson">{i18n.t("profile.vouchers")}</Text>
                      <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                        {couponCount.cash} {i18n.t("profile.cash_vouchers")}
                      </Text>
                      <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                        {couponCount.food} {i18n.t("profile.food_vouchers")}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>

                <TouchableOpacity className="w-auto max-w-[150px] h-8 max-h-8 bg-brandgold mr-2 px-4 mt-5" onPress={() => goCouponPage()}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.click_to_view")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          {/* Transaction Record Area */}
          <View className="hidden md:flex">
            <TransactionDetails />
          </View>

          <View className="w-full md:w-4/5 max-w-7xl pl-4 pr-4 pt-2 pb-2 md:p-0 m-auto md:mt-3 md:hidden">
            <View className="bg-white p-4">
              <View className="flex md:flex-row flex-row items-center justify-between md:mb-6">
                <View>
                  <Text className="text-xl font-semibold text-black font-Crimson">{i18n.t("profile.transaction")}</Text>
                </View>

                {/* Mobile display - View button */}
                <TouchableOpacity
                  className="w-auto h-8 max-h-8 bg-brandgold mt-1 mr-3 px-4 "
                  onPress={() => {
                    navigation.push("Transaction");
                  }}
                >
                  <Text className="text-white w-full h-8 leading-8 text-center font-PingFangSC">{i18n.t("profile.view_more")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          {/* Friend Referral */}
          {/* <View className="w-full md:w-4/5 max-w-7xl pl-4 pr-4 pt-0 pb-4 m-auto md:mt-3 sm:hidden">
            <View className="bg-white p-4">
              <View className="flex flex-row md:flex-row items-center justify-between">
                <View>
                  <Text className="text-xl font-semibold text-black font-Crimson">
                    {i18n.t("profile.friend_referral")}
                  </Text>
                </View>

                <TouchableOpacity
                  className="w-auto h-8 max-h-8 bg-brandgold mr-3 px-4"
                  onPress={() => {
                    navigation.push("FriendReferral");
                  }}
                >
                  <Text className="text-white w-full h-8 leading-8 text-center font-PingFangSC">
                    {i18n.t("profile.add_friend")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View> */}
        </View>
      </ScrollView>
      <Footer />

      {qrcodeOverlay && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity className="absolute right-1 top-1" onPress={() => closePaymentOverlay()}>
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            <View className="">
              <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">{i18n.t("signUp.payment_head")}</Text>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
              <View className="align-middle text-center m-auto mt-3">
                <QRCode value={paymentQrcodeLink} />
              </View>
              <View className="align-middle text-center m-auto mt-3">
                <Text className="text-sm font-normal text-[#111111]">{i18n.t("signUp.payment_msg")}</Text>
              </View>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
              <View className="text-right flex items-end">
                <TouchableOpacity className="w-[162px] h-8 max-h-8 bg-brandgold mt-4" onPress={() => closePaymentOverlay()}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("signUp.payment_done")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )}

      {renewOverlay && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity className="absolute right-1 top-1" onPress={() => setRenewOverlay(false)}>
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            <View className="">
              <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">{i18n.t("profile.renew_membership")}</Text>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
              <Text className="text-base font-medium pt-6 pb-4">{i18n.t("profile.renew_membership_1")}</Text>
              <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_2")}</Text>
              <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_3")}</Text>
              <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_4")}</Text>
              <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_5")}</Text>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
              <View className="text-right flex items-end">
                <TouchableOpacity className="w-[162px] h-8 max-h-8 bg-brandgold mt-4" onPress={() => confirmActive()}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.confirm_to_renew")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )}

      {activiateOverlay && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity className="absolute right-1 top-1" onPress={() => setActiviateOverlay(false)}>
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            {memberStatus == "active" && (
              <View className="">
                <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">{i18n.t("profile.renew_membership")}</Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <Text className="text-base font-medium pt-6 pb-4">{i18n.t("profile.renew_membership_1")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_2")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_3")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_4")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.renew_membership_5")}</Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <View className="text-right flex items-end">
                  <TouchableOpacity className="w-[162px] h-8 max-h-8 bg-brandgold mt-4" onPress={() => confirmActive()}>
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.confirm_to_renew")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {memberStatus == "expired" && (
              <View className="">
                <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">{i18n.t("profile.activate_membership")}</Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <Text className="text-base font-medium pt-6 pb-4">{i18n.t("profile.activate_membership_1")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.activate_membership_2")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.activate_membership_3")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.activate_membership_4")}</Text>
                <Text className="text-sm font-normal text-[#111111]">- {i18n.t("profile.activate_membership_7")}</Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <View className="text-right flex items-end">
                  <TouchableOpacity className="w-[162px] h-8 max-h-8 bg-brandgold mt-4" onPress={() => confirmActive()}>
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.confirm_to_activate")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        </View>
      )}

      {passwordOverlay && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity className="absolute right-1 top-1" onPress={() => setPasswordOverlay(false)}>
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">{i18n.t("profile.change_password")}</Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-base font-medium pt-6 pb-4">{i18n.t("profile.previous_password")}</Text>
            <TextInput placeholder="" className="text-[#86909C] h-8 w-full border border-[#F2F3F5] bg-[#F2F3F5] px-4" secureTextEntry={true} value={pPassword} onChangeText={setPPassword}></TextInput>

            {pPasswordErr ? <Text className="color-red-500 my-1">{i18n.t("profile.previous_password_errMsg")}</Text> : null}

            <Text className="text-base font-medium pt-6 pb-4">{i18n.t("profile.new_password")}</Text>
            <TextInput placeholder="" className="text-[#86909C] h-8 w-full border border-[#F2F3F5] bg-[#F2F3F5] px-4" secureTextEntry={true} value={password} onChangeText={setPassword}></TextInput>

            {passwordFormatErr && <Text className="color-red-500 my-1">{i18n.t("profile.new_password_errMsg2")}</Text>}

            <Text className="text-base font-medium pt-6 pb-4">{i18n.t("profile.confirm_password")}</Text>
            <TextInput placeholder="" className="text-[#86909C] h-8 w-full border border-[#F2F3F5] bg-[#F2F3F5] px-4" secureTextEntry={true} value={confirmPassword} onChangeText={setConfirmPassword}></TextInput>

            {updatePasswordErr ? <Text className="color-red-500 my-1">{i18n.t("profile.new_password_errMsg1")}</Text> : null}

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
            <View className="text-right flex items-end">
              <TouchableOpacity className="w-[162px] h-8 max-h-8 bg-brandgold mt-4" onPress={() => confirmUpdatePassword()}>
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.change")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      <Modal animationType="slide" visible={paymentWebview}>
        <TouchableOpacity
          onPress={() => {
            setPaymentWebview(false);
          }}
          style={{ marginVertical: 15, alignItems: "center" }}
        >
          <Text>{i18n.t("profile.close")}</Text>
        </TouchableOpacity>

        <WebView
          source={{ uri: paymentURL }}
          style={{ marginTop: 0 }}
          onLoad={(syntheticEvent) => {
            const { nativeEvent } = syntheticEvent;
            console.log(nativeEvent);
            //this.url = nativeEvent.url;
          }}
          onNavigationStateChange={(navState) => {
            console.log("navState :", navState);
          }}
          scalesPageToFit={false}
          onMessage={(event) => {
            console.log(event);

            if (event.nativeEvent.data.paymentResult) {
              let resCode = event.nativeEvent.data.paymentResult.respCode;
              console.log("resCode :", resCode);

              if (resCode == "2000") {
                setIsLoading(true);

                setTimeout(() => {
                  setIsLoading(false);
                  setActiviateOverlay(false);
                  setPaymentWebview(false);
                  CheckPayment();
                  //submitForm();
                  console.log("Payment success");
                }, 3000);
              }
            }
          }}
        />
      </Modal>

      {snackbarActiviated && (
        <View className="bg-white right-5 top-11 min-w-[200px] h-10 border border-[#E5E6EB] flex flex-row justify-around items-center" style={{ position: "fixed" }}>
          <Image className="w-5 h-5" source={require("../assets/icons/icon-green-tick.png")} />
          <Text>{i18n.t("profile.member_activated")}</Text>
          <TouchableOpacity onPress={() => setSnackbarActiviated(false)}>
            <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
          </TouchableOpacity>
        </View>
      )}

      {snackbarPasswordUpdated && (
        <View className="bg-white right-5 top-11 min-w-[200px] h-10 border border-[#E5E6EB] flex flex-row justify-around items-center" style={{ position: "fixed" }}>
          <Image className="w-5 h-5" source={require("../assets/icons/icon-green-tick.png")} />
          <Text>{i18n.t("profile.password_updated")}</Text>
          <TouchableOpacity onPress={() => setSnackbarPasswordUpdated(false)}>
            <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
          </TouchableOpacity>
        </View>
      )}
    </SafeAreaView>
  );
};

export default Profile;
