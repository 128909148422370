import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import queryString from "query-string";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import AsyncStorage from "../components/AsyncStorage";

const PaymentCallback = () => {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const token = atob(parsed.t).split('|');
    const member_code = token[0];
    const invoice_no = token[1];
    const user_name = token[2];    

    setIsLoading(true);

    let data = {
      invoiceNo: invoice_no,
    };

    console.log("checkPayment-data :", data);

    checkPaymentResult(data, (result) => {
      if (result.respCode == "0000") {
        FinishSignUp(member_code, user_name);
      } else {
        setIsLoading(false);
        alert("Payment failed - " + result.respDesc);
        navigation.push("SignUp");
      }
    });    

  }, []);

  const FinishSignUp = (code, user) => {
    setIsLoading(true);

    console.log("FinishSignUp-code:", code);

    let data2 = {
      code: code,
    };

    confirmCustomer(data2, (customer) => {
      if (customer.status == "success") {
        let store = {
          code: code,
          username: user,
          region: API_REGION,
        };

        console.log("confirmCustomer-store :", store)

        AsyncStorage.storeData("mCode", JSON.stringify(store));
        setIsLoading(false);
        navigation.push("Profile");
      } else {
        alert(customer.message);
        setIsLoading(false);
      }
    });
  };

  const confirmCustomer = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "confirmCust");
    formData.append("code", data.code);
    formData.append("region", API_REGION);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const checkPaymentResult = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "paymentChk");
    formData.set("region", API_REGION);
    formData.set("invoiceno", data.invoiceNo);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  return (
    <View className="md:bg-[#efefef]">
        <Text>Loading...</Text>
    </View>
  );
};

export default PaymentCallback;
