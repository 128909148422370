import React, { useState, useRef, useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  TextInput,
  Modal,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import IntlPhoneInput from "../components/IntlPhoneInput";
import MobileHeader from "../components/mHeader";
import NewMobileHeader from "../components/newMobileHeader"; // Mobile Header
import Footer from "../components/footer";
import InsideHeader from "../components/insideHeader";
import AsyncStorage from "../components/AsyncStorage";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import i18n from "../components/i18n";

let timer = () => { };

const UpdateMobile = () => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [phoneUniqueErr, setPhoneUniqueErr] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [token, setToken] = useState("");
  const [tokenErr, setTokenErr] = useState(false);
  const [otpCount, setOtpCount] = useState(60);
  const [disableItem, setDisableItem] = useState(false);
  const [otpSign, setOtpSign] = useState("");
  const [successOverlay, setSuccessOverlay] = useState(false);

  useEffect(() => {
    startTimer();
    return () => clearTimeout(timer);
  });

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          console.log("customerGet :", customer);

          setMember(customer);

          setIsLoading(false);
        });
      }
    });
  }, []);

  const sendToken = () => {
    let errors = [];
    setPhoneErr(false);
    setPhoneUniqueErr(false);

    setIsLoading(true);

    if (!validPhone) {
      errors.push("err");
      setPhoneErr(true);
    }

    if (!errors.length) {
      let data = {
        mobile: dialCode + phone.replace(/\s/g, ""),
      };

      checkUnique(data, (unique) => {
        if (unique.status == "success") {
          let data = {
            channel: "sms",
            target: phone.replace(/\s/g, ""),
            country_code: dialCode,
          };

          sendOTP(data, (otp) => {
            if (otp.status == "success") {
              setOtpSign(otp.signature);
              setDisableItem(true);
              setOtpStatus(1);
              start();
            }
          });

          setIsLoading(false);
        } else {
          setPhoneUniqueErr(true);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  const updateMobile = () => {
    setTokenErr(false);

    if (token == "") {
      setTokenErr(true);
    } else {
      setIsLoading(true);

      let data = {
        channel: "sms",
        target: phone.replace(/\s/g, ""),
        country_code: dialCode,
        sign: otpSign,
        code: token,
      };

      verifyOTP(data, (otp) => {
        if (otp.status == "success") {
          let updateData = {
            code: member.code,
            phone: phone.replace(/\s/g, ""),
            country_code: dialCode,
          };

          updateCust(updateData, (update) => {
            if (update.status == "success") {
              let store = {
                code: member.code,
                username: dialCode + phone.replace(/\s/g, ""),
                region: API_REGION,
              };

              AsyncStorage.storeData("mCode", JSON.stringify(store));

              setSuccessOverlay(true);
              setIsLoading(false);
            } else {
              alert("Update Error");
              setIsLoading(false);
            }
          });
        } else {
          setTokenErr(true);
          setIsLoading(false);
        }
      });
    }
  };

  const startTimer = () => {
    timer = setTimeout(() => {
      if (otpCount <= 0) {
        clearTimeout(timer);
        setDisableItem(false);
        return false;
      }
      setOtpCount(otpCount - 1);
    }, 1000);
  };

  const start = () => {
    setOtpCount(60);
    clearTimeout(timer);
    startTimer();
  };

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const onChangeText = ({
    dialCode,
    unmaskedPhoneNumber,
    phoneNumber,
    isVerified,
  }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setPhone(unmaskedPhoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const updateCust = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "updateCust");
    formData.append("code", data.code);
    formData.append("mobilenocc", data.phone);
    formData.append("country_code", data.country_code);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const checkUnique = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "checkDup");
    formData.set("mobile", data.mobile);
    formData.set("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const sendOTP = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "sendotp");
    formData.set("channel", data.channel);
    formData.set("target", data.target);
    formData.set("region", memberRegion);

    if (data.country_code) {
      formData.set("country_code", data.country_code);
    }

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const verifyOTP = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "verifyotp");
    formData.set("channel", data.channel);
    formData.set("target", data.target);
    formData.set("sign", data.sign);
    formData.set("code", data.code);
    formData.set("region", memberRegion);

    if (data.country_code) {
      formData.set("country_code", data.country_code);
    }

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  return (
    <SafeAreaView className="min-h-full bg-white md:bg-[#F5F5F5]">
      {isLoading && (
        <View
          className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-screen"
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHeader />
      <NewMobileHeader />
      <MobileHeader
        title={i18n.t("updateMobile.title")}
        backTo={"EditProfile"}
      />
      <ScrollView className="flex-1 h-auto">
        <View className="hidden md:flex md:w-4/5 max-w-7xl m-auto mt-6 mb-4 flex-row items-center">
          <Image
            className="w-4 h-4 mr-1"
            source={require("../assets/icons/icon-home.png")}
          />
          <TouchableOpacity onPress={() => navigation.push("Profile")}>
            <Text className="text-sm font-normal text-[#4E5969] font-PingFangSC">
              {i18n.t("updateMobile.member_profile")}
            </Text>
          </TouchableOpacity>
          <Text> / </Text>
          <Text className="font-PingFangSC">
            {i18n.t("updateMobile.updateMobile")}
          </Text>
        </View>
        <View className="flex w-full md:w-4/5 max-w-7xl m-auto bg-white p-5 flex-col">
          <View>
            <Text className="hidden md:flex text-2xl font-semibold text-black font-Crimson">
              {i18n.t("updateMobile.updateMobile")}
            </Text>
          </View>
          <View className="flex flex-col flex-wrap items-start justify-between pb-4">
            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
              {i18n.t("updateMobile.new_mobile")}
            </Text>
            <View className="w-full flex flex-row justify-around">
              <IntlPhoneInput
                onChangeText={onChangeText}
                defaultCountry={API_REGION}
                containerStyle={{
                  flex: 1,
                  flexDirection: "row",
                  width: "60%",
                  paddingHorizontal: 0,
                }}
                flagStyle={{ display: "none" }}
                phoneInputStyle={{
                  color: "#86909C",
                  height: 36,
                  borderWidth: 1,
                  borderColor: "#F2F3F5",
                  backgroundColor: "#F2F3F5",
                  paddingHorizontal: 15,
                  minWidth: "30px"
                }}
                dialCodeTextStyle={{
                  color: "#86909C",
                  height: 36,
                  lineHeight: 36,
                  width: "100%",
                  borderWidth: 1,
                  borderColor: "#F2F3F5",
                  backgroundColor: "#F2F3F5",
                  paddingHorizontal: 15,
                }}
                placeholder={phone}
              />
              <TouchableOpacity
                className="h-9 w-[130px] bg-[#F2F3F5] px-4 ml-3"
                onPress={() => sendToken()}
                disabled={disableItem}
              >
                {disableItem && (
                  <Text className="text-gray-400 w-full text-sm h-9 leading-9 text-center font-semibold font-PingFangSC">
                    {i18n.t("updateMobile.sent")}({otpCount})
                  </Text>
                )}
                {!disableItem && (
                  <Text className="text-[#121212] w-full text-sm h-9 leading-9 text-center font-semibold font-PingFangSC">
                    {i18n.t("updateMobile.send_token")}
                  </Text>
                )}
              </TouchableOpacity>
            </View>

            {phoneErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("updateMobile.mobile_errMsg1")}
              </Text>
            )}
            {phoneUniqueErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("updateMobile.mobile_errMsg2")}
              </Text>
            )}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
              {i18n.t("updateMobile.token")}
            </Text>
            <TextInput
              placeholder=""
              className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
              value={token}
              onChangeText={setToken}
            ></TextInput>

            {tokenErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("updateMobile.token_error")}
              </Text>
            )}

            <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
              <TouchableOpacity
                className="w-full md:w-auto md:max-w-[125px] h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4"
                onPress={() => updateMobile()}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">
                  {i18n.t("updateMobile.update")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>

      {successOverlay && (
        <View
          className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
          style={{ position: "fixed" }}
        >
          <View className="w-11/12 max-w-xs bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => {
                setSuccessOverlay(false);
                navigation.push("EditProfile");
              }}
            >
              <Image
                className="w-5 h-5"
                source={require("../assets/btn-close.png")}
              />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-8 uppercase font-Crimson">
              {i18n.t("updateMobile.update_success")}
            </Text>

            <View className="text-right flex items-end mb-8">
              <TouchableOpacity
                className="w-full h-8 max-h-8 bg-brandgold mt-4 mr-0"
                onPress={() => {
                  setSuccessOverlay(false);
                  navigation.push("EditProfile");
                }}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                  {i18n.t("updateMobile.done")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      <Footer />
    </SafeAreaView>
  );
};

export default UpdateMobile;
