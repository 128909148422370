export default {
  welcomePage:{
    memberYet: "NOT AN IMPERIAL TREASURE CLUB MEMBER YET?",
    memberBenefit: "As an Imperial Treasure Club member, you can enjoy exclusive privileges, dining rewards and rebates on spending at participating restaurants, and be the first to know of any upcoming events and promotions!",
    memberPrivileges : "Membership Privileges",
    privilege_txt1: "Earn point (i$1 with every CNY10 spent) on your spend at any participating restaurants.",
    privilege_txt2: "Accumulate i$250 to receive CNY250 cash e-voucher for use at any participating restaurants.",
    privilege_txt3: "Receive 2 X CNY238 cash e-voucher upon sign up (valid for 3 months from date of issue).",
    privilege_txt4: "Receive 1 X CNY480 birthday e-voucher every year (valid only during birthday month with a minimum spend of CNY1,500 (exclude service charge) in a single receipt).",
    privilege_txt5: "Sign up with CNY438 and receive vouchers worth CNY956! Renew with CNY180 from 2nd year onwards.",
    signUpNow: "SIGN UP NOW",
    login: "LOGIN",
  },
  login: {
    login: "LOGIN",
    email: "Email",
    mobile: "Mobile",
    mobile2: "MOBILE NO",
    password: "PASSWORD",
    errMsg1: "Please enter email & password",
    errMsg2: "Please enter mobile & password",
    errMsg3: "Your account isn't this region",
    errMsg4: "Incorrect login or password",
    errMsg5: "Incorrect login mobile or password",
    errMsg6: "Your registration is not complete until payment is received.",
    forgotpw: "Forgot password?",
    register: "Register",
    txt: "Not an Imperial Treasure Club member yet? Check out our ",
    txt_2: "Membership Privileges.",
  },
  mbHeader:{
    home: "Home",
    register: "Register",
    aboutUs: "About Us",
    privileges: "Membership Privileges",
    highlights: "Happenings",
    restaurants: "Participating Restaurants",
    reservations: "Reservations",
    eStore: "E-Store",
    faqs: "FAQs",
    termsAndPrivacy: "Terms & Privacy",
    contact: "Contact Us",
    language: "Language Option",
    logout: "Logout",
  },
  header: {
    home: "HOME",
    eStore: "E-STORE",
    PRivileges: "PRivileges",
    register: "register",
    highlights: "highlights",
    faq: "faq",
    tnp: "terms & privacy",
    friendReferral: "Friend Referral",
    profile: "Profile",
  },
  footer: {
    aboutUs: "About Us",
    eStore: "E-STORE",
    faq: "FAQs",
    tnp: "Terms & Privacy",
    pr: "Participating Restaurants",
    profile: "Profile",
    contactUs: "Contact Us",
    copyright: "Copyright © 2023 Imperial Treasure Restaurant Group Pte Ltd.",
  },
  signUp: {
    signUp: "Membership Registration",
    title: "Register as a member of Imperial Treasure Club",
    mustFill: '"*" denotes required fields',
    step1: "Account Details",
    step2: "Security Information",
    step3: "Subscriptions",
    step4: "Reference",
    salutation: "Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    birth: "Birth Date",
    birth_errMsg: "Please select date of birth",
    birth_errMsg2: "You must 18 years old or older",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    emailAddress_errMsg2: "Email already registered",
    confirmEmailAddress: "Confirm Email Address",
    confirmEmailAddress_errMsg: "Please confirm email",
    gender: "Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "Please select language preference",
    password_format: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    password: "*Password",
    password_errMsg: "Please enter password",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please Enter password again",
    step3_consent_opt: "I acknowledge that I have read and agree to <link>the terms and conditions and privacy policy<link> listed here",
    step3_consent_opt2: "I agree to transfer my personal information to overseas affiliated companies of Imperial Treasure Catering (Shanghai) Co., Ltd. located outside mainland China for the purposes",
    tnc_errMsg: "Please agree to Terms & Conditions to proceed",
    step3_title: "By checking the box(es) below, I give my consent to Imperial Treasure F&B Shanghai Co., Ltd ('ITRG') to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club Membership ('Data') for ITRG to share my data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of China.",
    step3_q: "I would like to receive marketing and promotional messages and materials from Imperial Treasure Restaurant Group Pte Ltd",
    step3_q_opt1: "By Email",
    step3_q_opt2: "By Mobile",
    step4_q_err: "Please select where did you hear about our programme",
    step4_q: "Where did you hear about our programme?",
    step4_q_opt1: "Select a reference",
    step4_q_opt2: "Yi Feng Galleria, Fine Chinese Cuisine Shanghai",
    step4_q_opt3: "The Hub Mall, Fine Chinese Cuisine Shanghai",
    step4_q_opt4: "International Grand City, Fine Chinese Cuisine Guangzhou",
    step4_q_opt5: "Taikoo Hui, Fine Teochew Cuisine Guangzhou",
    step4_q_opt16: "Word of Mouth",
    step4_q_opt17: "Social Media",
    step4_q_opt18: "Others",
    promo_code: "Promo Code",
    promo_code_input_msg: "Please enter a valid promo code (if any)",
    promo_code_err_msg: "Please enter valid promo code.",
    promo_code_re_msg: "Promo code is valid!",
    total: "Total:",
    refresh: "Refresh",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    submitForm: "Proceed to payment",
    payment_head: "Payment",
    payment_msg: "Please scan QR code to make payment with Wechat pay.",
    payment_done: "Payment done",
    payment_err1: "Your transaction has failed. Please try again.",
    payment_err2: "Error occured when confirming customer",
    payment_err3: "Your transaction has failed. Please try to register again after a period of 10 minutes."
  },
  aboutUs: {
    aboutUs: "ABOUT US",
    txt1: "IMPERIAL TREASURE RESTAURANT GROUP",
    txt2: "With our vast array of iconic Chinese delicacies and our commitment to impeccable service, Imperial Treasure has become synonymous with authentic fine Chinese cuisine. Since the establishment of our first restaurant in 2004, Imperial Treasure Restaurant Group has won the hearts of gourmands and casual diners alike.",
    txt3: "Today, the Group has more than 20 restaurants under its name, as well as over 50 accolades – including 2-Michelin Stars, Two Diamond in Meituan-Dianping Black Pearl Guide and Top Chinese Restaurants of the World by Ch’ng Poh Tiong, Asia's 50 Best Restaurants.",
    txt4: "Imperial Treasure has restaurants in Singapore, Shanghai, Guangzhou, Hong Kong, Incheon, London and Paris.",
  },
  cn_reservation:{
    store1_name: "Imperial Treasure Fine Chinese Cuisine",
    store1_address: "L402-403, 4/F, Yi Feng Galleria, \nNo. 99 East Beijing Road, Huangpu District, \nShanghai 200002",
    store1_contact: "Contact: (86) 21 5308 1188",
    store1_email: "Email: shanghai@imperialtreasure.com",
    store2_name: "Imperial Treasure Fine Chinese Cuisine",
    store2_address: "L326-329, 3/F, The Hub Mall, \nNo. 688 Shen Chang Road, Min Hang District, \nShanghai 201105",
    store2_contact: "Contact: (86) 21 5498 1108",
    store2_email: "Email: hongqiao_finechinese@imperialtreasure.com",
    store3_name: "Imperial Treasure Fine Chinese Cuisine",
    store3_address: "L514B, 5/F, International Grand City (igc), \nNo. 222 Xing Min Road, Tianhe District, \nGuangzhou 510623",
    store3_contact: "Contact: (86) 20 3885 6382",
    store3_email: "Email: finechinese_igc@imperialtreasure.com",
    store4_name: "Imperial Treasure Fine Teochew Cuisine",
    store4_address: "L307, 3/F, Taikoo Hui, \nNo. 383 Tianhe Road, Tianhe District, \nGuangzhou 510620",
    store4_contact: "Contact: (86) 20 3868 2388",
    store4_email: "Email: fineteochew_taikoo@imperialtreasure.com",
  },
  faqs: {
    faqs: "FAQs",
    general: "General",
    general_q1: "What is the Imperial Treasure Club?",
    general_q1_opt1: "Imperial Treasure Club is a cardless loyalty programme that allows members to enjoy exclusive privileges and rewards whenever they dine at any participating Imperial Treasure restaurants.",
    general_q2: "How do I become a member of Imperial Treasure Club?",
    general_q2_opt1: "You may register online at members.imperialtreasure.com.cn . Alternatively, you can sign up at any participating Imperial Treasure restaurants.",
    general_q3: "Which are the participating restaurants in China?",
    general_q3_opt3: "Imperial Treasure Fine Chinese Cuisine",
    general_q3_opt3_1: "L402-403, 4/F, Yi Feng Galleria, No. 99 East Beijing Road, Huangpu District, Shanghai | Tel: (86) 21 5308 1188",
    general_q3_opt3_2: "L326-329, 3/F, The Hub Mall, No. 688 Shen Chang Road, Min Hang District, Shanghai | Tel: (86) 21 5498 1108",
    general_q3_opt3_3: "L514B, 5/F, International Grand City (igc), No. 222 Xing Min Road, Tianhe District, Guangzhou | Tel: (86) 20 3885 6382",
    general_q3_opt4: "Imperial Treasure Fine Teochew Cuisine",
    general_q3_opt4_1: "L307, 3/F, Taikoo Hui, No. 383 Tianhe Road, Tianhe District, Guangzhou | Tel: (86) 20 3868 2388 ",
    general_q4: "What are the fees to become a member of Imperial Treasure Club?",
    general_q4_opt1: "The membership fee is CNY438 nett.",
    general_q5: "How do I pay for the membership application?",
    general_q5_opt1: "For sign ups at the participating restaurants, payment can be done at the counter. For online sign ups, payment can be done using credit card and Alipay.",
    general_q6: "What is the validity of the membership?",
    general_q6_opt1: "The membership is valid for 1 year from the date of sign up. E.g. If the registration is made on 17 August 2023, the membership will expire on 16 August 2024.",
    general_q7: "Where can I check the expiry date of my membership?",
    general_q7_opt1: "You may log in to the member portal to check the expiry date of your membership.",
    general_q8: "What is the minimum age that I can apply as an Imperial Treasure Club member?",
    general_q8_opt1: "Customers need to be above 18 years of age to apply as an Imperial Treasure Club member.",
    general_q9: "Where do I update my personal particulars?",
    general_q9_opt1: "To update or view your personal particulars, please log in to your account via the members’ portal at https://members.imperialtreasure.com.cn",
    general_q10: "How do I check my i-dollars, e-vouchers and other rewards?",
    general_q10_opt1: "You may log in to https://members.imperialtreasure.com.cn to check your membership privileges and rewards balance.",
    general_q11: "What if I forgotten my password?",
    general_q11_opt1: "To reset your password, visit the members’ portal at https://members.imperialtreasure.com.cn and click on “Forgot Password”. Follow the on screen instructions to reset your password.",
    general_q12: "What should I do if I have questions regarding my membership account?",
    general_q12_opt1: "If you have any questions regarding your membership account, please email us at club.cn@imperialtreasure.com.",
    general_q13: "How do I terminate my membership?",
    general_q13_opt1: "Member may choose to terminate their membership at any time before the expiry date by writing in to us at club.cn@imperialtreasure.com. Kindly be advised that there will be no refunds made and/or early issuance of any unused e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused e-vouchers will be deemed forfeited.",
    membershipTypes: "Membership Types",
    membershipTypes_q1: "What are the types of membership?",
    membershipTypes_q1_opt1: "There are 3 membership types - Silver, Gold and Diamond.",
    membershipTypes_q2: "How to be a Silver / Gold / Diamond member?",
    membershipTypes_q2_opt1: "To become a Silver member, you can sign up with CNY438 online or at any participating restaurants.",
    membershipTypes_q2_opt2: "To become a Gold member, existing members need to accumulate qualifying spend of CNY 50,888* and above within the membership year to qualify.",
    membershipTypes_q2_opt3: "To become a Diamond member, existing members need to accumulate qualifying spend of CNY148,888* and above within the membership year to qualify.",
    membershipTypes_q2_opt4: "*Qualifying spend is calculated excluding 10% service charge.",
    membershipTypes_q3: "How to retain my membership status?",
    membershipTypes_q3_opt1: "To maintain as a Silver member, simply renew your membership with a CNY 180 renewal fee.",
    membershipTypes_q3_opt2: "To maintain as a Gold member, simply accumulate qualifying spend of CNY 50,888* within the membership year to renew your membership status.",
    membershipTypes_q3_opt3: "To maintain as a Diamond member, simply accumulate qualifying spend of CNY 148,888* within the membership year to renew your membership status.",
    membershipTypes_q3_opt4: "*Qualifying spend is calculated excluding 10% service charge.",
    membership: "Membership Privileges & Rewards",
    membership_q1: "What will I get when I sign up for the Imperial Treasure Club membership?",
    membership_q1_opt1: "Upon completion of registration, you will receive,",
    membership_q1_opt2: "2 X CNY238 Sign up e-vouchers (valid for 3 months)",
    membership_q1_opt3: "1 X CNY480 Birthday e-voucher (min. spend of CNY 1,500* (subtotal) in a single bill applies, only valid during birthday month)",
    membership_q1_opt4: "*Qualifying spend is calculated excluding 10% service charge.",
    membership_q2: "What should I do after I have signed up at the participating restaurants?",
    membership_q2_opt1: "You need to log in at  https://members.imperialtreasure.com.cn and complete their registration to receive the remaining e-vouchers.",
    membership_q3: "What are the membership privileges?",
    type_of_privileges: "Type of Privileges",
    sliver: "Sliver",
    gold: "Gold",
    diamond: "Diamond",
    type_of_privileges_1_1: "Earn Points",
    type_of_privileges_1_2: "Yes",
    type_of_privileges_1_3: "Yes",
    type_of_privileges_1_4: "Yes",
    type_of_privileges_2_1: "CNY 250 Cash e-voucher",
    type_of_privileges_2_2: "Yes",
    type_of_privileges_2_3: "Yes",
    type_of_privileges_2_4: "Yes",
    sign_up_privileges: "Sign Up Privileges",
    sign_up_privileges_1_1: "2 X CNY 238 Sign Up e-vouchers",
    sign_up_privileges_1_2: "Yes",
    sign_up_privileges_1_3: "-",
    sign_up_privileges_1_4: "-",
    sign_up_privileges_2_1: "1 X CNY 480 Birthday e-voucher",
    sign_up_privileges_2_2: "Yes",
    sign_up_privileges_2_3: "Yes",
    sign_up_privileges_2_4: "Yes",
    complimentary_treats: "Complimentary Treats",
    complimentary_treats_1_1: "Peking Duck (Whole)",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "Yes",
    complimentary_treats_1_4: "Yes",
    complimentary_treats_2_1: "Suckling Pig (Whole)",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "-",
    complimentary_treats_2_4: "Yes",
    complimentary_treats_3_1: "A set of Pigeons (3pcs)",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "Yes",
    complimentary_treats_3_4: "Yes",
    complimentary_treats_4_1: "CNY Festive Set (seasonal)",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "2 sets",
    complimentary_treats_4_4: "3 sets",
    complimentary_treats_5_1: "Rice Dumpling Set (seasonal) ",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "2 sets",
    complimentary_treats_5_4: "3 sets",
    complimentary_treats_6_1: "Mooncake Set (seasonal) ",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "2 sets",
    complimentary_treats_6_4: "3 sets",
    membership_q4: "Can the member use the 2 X CNY238 Sign up e-voucher in a single bill?",
    membership_q4_opt1: "No, CNY 238 Sign up e-voucher cannot be combined for use in a single bill, ie. only ONE Sign up e-voucher can be used per bill.",
    membership_q5: "When will CNY480 Birthday e-voucher be issued?",
    membership_q5_opt1: "It will be issued on the first day of the birthday month and valid for use during their birthday month, e.g. If your birthday is 10th Oct, e-voucher will be issued on 1st Oct and valid till 31st Oct. ",
    membership_q6: "How do I know the expiry of my privileges?",
    membership_q6_opt1: "To check the expiry dates of your privileges, simply log in to the member’s portal and view the details reflected under the ‘Vouchers’ section.",
    membership_q7: "Can I combine the e-vouchers for redemption?",
    membership_q7_1_1: "eVoucher Type",
    membership_q7_1_2: "CNY 238 Sign up / CNY 238 Renewal",
    membership_q7_1_3: "CNY 480 Birthday",
    membership_q7_1_4: "Complimentary Item (Eg. Peking Duck, Suckling Pig and pigeon etc.)",
    membership_q7_yes: "Yes",
    membership_q7_no: "No",
    membership_q7_opt1: "Click here for terms and conditions of e-Voucher.",
    membership_q8: "Why should I register my details at Imperial Treasure Club?",
    membership_q8_opt1: "Registering your details at https://members.imperialtreasure.com.cn will enable you to receive the latest news on upcoming members’ promotions, rewards, invitations to events and more!",
    membership_q9: "How do I identify myself as an Imperial Treasure Club member？",
    membership_q9_opt1: "To identify yourself as a member, simply provide your mobile number to our service staff. ",
    membership_q10: "How do I enjoy my complimentary treats? ",
    membership_q10_opt1: "For redemption of Peking Duck, one (01) day advance order is required and available at Imperial Treasure Fine Chinese Cuisine.",
    membership_q10_opt2: "For redemption of Suckling Pig, one (01) day advance order is required and available at all Imperial Treasure Fine Chinese Cuisine.",
    membership_q10_opt3: "For redemption of Roasted Pigeons, one (01) day advance order is required and available at all Imperial Treasure Fine Chinese Cuisine.",
    membership_q10_opt4: "For redemption of seasonal items, it will only be available during related festive periods.",
    membership_q10_opt5: "Members who qualify for the complimentary treats will receive an email notification closer to the festive periods for their redemption at participating restaurants. Member must be physically present (proof of identification required) in order to redeem any membership privileges.",
    membership_q11: "Can I as a corporate customer enjoy corporate discount with the Imperial Treasure Club membership?",
    membership_q11_opt1: "No, corporate discount is not eligible for use together with the Imperial Treasure Club membership. Corporate customers can only choose either corporate discount or the Imperial Treasure Club membership for each receipt.",
    membership_q12: "Can I enjoy my Imperial Treasure Club membership privileges in another country where there is an Imperial Treasure restaurant?",
    membership_q12_opt1: "The Imperial Treasure Club membership privileges are currently available for participating restaurants in Mainland China only. For overseas privileges, please follow us to receive the updates.",
    membership_q13: "Is my Imperial Treasure Club membership transferrable? ",
    membership_q13_opt1: "No. Membership is not transferrable.",
    earning: "Earning & Redemption of Points",
    earning_q1: "How do I earn points?",
    earning_q1_opt1: "Earn points for every CNY10* spent on total bill at participating restaurants. (*Exclude service charge, e-vouchers and festive items). Member must be physically present (proof of identification required) at the point of dining. Requests on points accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, e-vouchers and points earned. Combining multiple membership accounts or splitting the bill to earn points is strictly not allowed points accrued shall expire upon expiry of membership. Upon renewal of membership, any points accrued shall be brought forward to the next membership year.",
    earning_q2: "How do I redeem my points?",
    earning_q2_opt1: "For every 250 points earned, members will automatically receive* a CNY 250 Cash e-voucher valid for use within the membership year. Members can use the CNY250 cash e-vouchers to offset their bills when dining at any participating restaurants. Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Combining multiple membership accounts or splitting the bill to redeem membership privileges is not allowed. \n(*System automatically converts balance points at a stipulated time daily)",
    earning_q3: "Will my points expire?",
    earning_q3_opt1: "Yes. Points earned is only valid during the membership period and will expire once the membership expires. Balance points will be brought forward for a grace period of 3 months in the new membership cycle. Unused points not converted to cash e-voucher within the grace period will be forfeited.",
    earning_q4: "Would my points be reflected immediately once I make a purchase?",
    earning_q4_opt1: "Yes. The system will reflect the points earned instantly but do allow for time lapses that may be caused by any delays in the network during unforeseen circumstances.",
    earning_q5: "Can I use multiple membership accounts to earn points and redeem membership privileges?",
    earning_q5_opt1: "No. Combining multiple membership accounts to earn points or redeem membership privileges is not allowed.",
    earning_q6: "Can I split my bill to earn points and/or redeem membership privileges?",
    earning_q6_opt1: "No. Splitting of bill to earn points and/or redeem membership privileges is not allowed.",
    earning_q7: "Can my family and friends earn points or redeem points if I am not present?",
    earning_q7_opt1: "No. Members must be present to enjoy the membership privileges.",
    renewal: "Renewal",
    renewal_q1: "How do I renew my membership?",
    renewal_q1_opt1: "Renewal of membership can be done via the member’s portal at members.imperialtreasure.com.cn.",
    renewal_q2: "How much is the renewal fee?",
    renewal_q2_opt1: "You can renew your membership for CNY 180 nett.",
    renewal_q3: "How do I renew my Gold/Diamond membership?",
    renewal_q3_opt1: "Accumulate a total spend of at least CNY 50,888 and above (Gold) or CNY 148,888 and above (Diamond) within the current membership year to qualify for an automatic renewal upon the expiry of the current membership cycle.",
    renewal_q4: "What will I get when I renew my Imperial Treasure Club membership?",
    renewal_q4_opt1: "1 X CNY 238 Renewal e-voucher (valid for 3 months from date of issue), 1 X CNY 480 Birthday e-voucher (valid during birthday month with minimum spend of CNY 1,500 (subtotal) in a single receipt) will be credited into your account in the new membership cycle.",
    renewal_q5: "Can I renew my Imperial Treasure Club membership before the expiry date?",
    renewal_q5_opt1: "Yes. An email will be sent 1 month ahead to inform our Imperial Treasure Club membership expiry date. Eg. You registered on 17th Aug 2023, we will send email to remind you on 16th July 2023, if you completed renewal, the new membership cycle will commence from 17th Aug 2023.",
  },
  tnc: {
    title: "条款和隐私",
    consent_agree: "注册页面上的同意表述",
    consent_1_1: "□ 我承认我已经阅读并同意此处列出的条款和条件和隐私政策[链接: https://members.imperialtreasure.com.cn/cn/tnc]。",
    consent_1_2: "□ 我同意基于本隐私政策所述的目的，将我的个人信息传输至御宝餐饮（上海）有限公司位于中国大陆外的境外关联公司。",
    consent_opt: "注册页面上的选择加入表述",
    consent_2_1: "通过勾选以下的方框，我同意御宝餐饮（上海）有限公司及其子公司（“ITRG”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方，从而使我能够享受御宝俱乐部会员资格的权益，包括源于ITRG与中国境内外服务提供商之间关系而产生的权益。",
    consent_2_2: "我希望收到来自ITRG的营销/推广信息与材料。",
    consent_2_3: "□ 通过电子邮件    □ 通过邮寄     □ 通过移动电话",
    membership: "御宝忠诚计划条款和条件",
    membership_1: "本御宝忠诚计划条款和条件（“条款和条件”，包括下述《隐私政策》）适用于御宝餐饮（上海）有限公司以及其子公司（“御宝”）于中国境内（为本条款和条件的目的，不包括香港特别行政区、澳门特别行政区以及台湾）的会员服务，对御宝与御宝忠诚计划成员（“会员”）均具有法律约束力。",
    membership_1_1: "1.1 简介",
    membership_1_1_1: "御宝希望向我司尊贵客户提供我司产品和服务的有关资讯与信息，包括但不限于促销、特别优惠和折扣等。我司将根据本条款和条件，为您提供成为会员、并享受由御宝提供的优惠的机会。",
    membership_1_2: "1.2 个人信息的收集和使用",
    membership_1_2_1: "若您成为会员，您需要向御宝提供个人信息（包括您的姓名与详细联系信息，例如电子邮件地址、移动电话号码和出生日期）。您需要向我们提供您的手机号码以便我们识别您。您的移动电话号码将成为御宝忠诚计划会员的用户名或唯一身份。我们任何有关您的个人信息的处理活动都将根据我们的隐私政策进行。",
    membership_1_3: "1.3 直接营销",
    membership_1_3_1: "通过在会员门户网站https://members.imperialtreasure.com.cn（“会员门户网站”）或参与活动的分店（请见第1.7节的定义）提交书面会员申请，您同意御宝及其附属公司、关联实体和代表我们的第三方机构（“商业伙伴”）可能在遵守我们的隐私政策和所有适用于中华人民共和国的法律法规情况下，收集、使用和披露您在会员门户网站或参与分店提供的个人信息，以便向您提供您同意接收的营销材料和特别优惠。请注意，为了提供营销材料和特别优惠，您同意我们可以向商业伙伴披露您的个人信息。",
    membership_1_4: "1.4 会员级别",
    membership_1_4_1: "1.4.1 会员注册、i-dollars 和电子现金券",
    membership_1_4_1_1: "当您注册成为会员时，您需要提供您的手机号码以及其他可能需要的信息以便注册会员。具体方式可能取决于情况而有所不同，可能是在线上进行，也可能由餐厅的工作人员负责。",
    membership_1_4_1_2: "会员资格严格不可转让，只能由会员本人享受。",
    membership_1_4_1_3: "作为会员，当您在任何全球范围内的御宝餐厅（具体范围参见下文）消费时（不包括支付服务费、税费、使用电子现金券以及购买和使用御宝礼品券），您将按照会员计划网站[插入链接: https://members.imperialtreasure.com.cn]所规定的比例获取一个i-dollar。请参考[插入链接: https://members.imperialtreasure.com.cn]中的御宝餐厅列表(“御宝餐厅”)，确认您可以从哪些御宝餐厅获取i-dollar。为了获取i-dollar，会员必须亲自到餐厅就餐，并向服务人员提供注册手机号码。i-dollar应在消费时获取。我们不会受理与过往消费相关的i-dollar获取要求。系统可能需要至多一个工作日以显示交易、电子现金券和获取的i-dollar。在会员资格到期时，所有获取的i-dollar将失效。所有获取的i-dollar将有三个月的有效期，会员必须在此期间将其转换为电子现金券，否则将被视为放弃赚取的i-dollar。通过第三方供应商完成的交易不符合获取i-dollar 的条件。",
    membership_1_4_1_4: "获取的i-dollar将按照会员门户网站中标定的各注册国家的规定比例转换为指定价值的御宝电子现金券（“电子现金券”）。每张电子现金券有效期为自发行之日起十二个月，仅限在会员有效期内使用。电子现金券可用于抵消在任何参与分店用餐时的账单（受限于御宝公司的单方决定和[插入链接: https://members.imperialtreasure.com.cn/cn/tnc]中规定的任何其他条款和条件）。i-dollar和电子现金券均没有任何现金价值，不能转换或兑换为现金。",
    membership_1_4_2: "1.4.2 会员级别",
    membership_1_4_2_1: "除非御宝另行确定，会员在首次注册时将直接成为银卡会员，有效期为会员计划网站[插入链接: members.imperialtreasure.com.cn]上针对其注册国家确定的期限。在此期满时，若会员未升级为金卡会员或钻石卡会员，则御宝有权自行要求会员按照会员计划网站[插入链接: members.imperialtreasure.com.cn]上的会员注册国家规定的费用要求会员续费。",
    membership_1_4_2_2: "会员必须在加入会员计划后的十二个月内，在御宝消费达到网站[插入链接: members.imperialtreasure.com.cn]上指定的金卡消费额，才能被授予金卡会员身份。金卡会员身份有效期为自会员达到相应消费额之日起的十二个月。在此期限届满后，会员需要再次满足金卡会员消费要求才能保持会员身份。若金卡会员资格到期时未满足最低消费要求，则被降级为银卡会员。",
    membership_1_4_2_3: "会员必须在加入会员计划后的十二个月内，在御宝消费达到网站[插入链接: members.imperialtreasure.com.cn]上指定的钻石卡消费额，才能被授予钻石卡会员身份。钻石卡会员身份有效期为自会员达到相应消费额之日起的十二个月。在此期限届满后，会员需要再次满足钻石卡会员消费要求才能保持会员身份。若钻石卡会员资格到期时未满足最低消费要求，则被降级为金卡会员或银卡会员。",
    membership_1_4_2_4: "御宝有权自行决定修改、限制、调整银卡会员、金卡会员和/或钻石卡会员计划规则、条例、福利、成员资格或任何其他要求，或随时自行决定终止银卡会员、金卡会员和/或钻石卡会员计划，并通过会员计划网站和/或电子邮件合理通知。请定期查看会员计划网站以获取更新信息。",
    membership_1_4_3: "1.4.3 会员等级奖励",
    membership_1_4_3_1: "会员可以享受对应级别的某些奖励和特权，其中可能包括其他电子凭证、免费礼品和/或其他用餐福利(“奖励”)。当前等级的奖励如会员计划网站[插入链接: members.imperialtreasure.com.cn]所示。此类奖励的有效期和条款及条件如[插入链接: https://members.imperialtreasure.com.cn/cn/tnc]所示。在向会员通过会员项目网站或电子邮件发送合理通知的情况下，御宝可以单方面修改上述奖励的有效期、条款及条件。奖励的发放取决于实际情况，御宝保留将奖励替换为等值或包含近似价值的另一个奖励的权利。奖励没有任何现金价值，不能转换或兑换成现金。",
    membership_1_4_4: "1.4.4 奖励与电子现金券兑换",
    membership_1_4_4_1: "会员必须亲临餐厅现场（需要身份证明）才能兑换任何会员特权。会员需要在收据上签字确认所兑换的奖励。",
    membership_1_4_4_2: "严禁合并多个会员账户、合并账单或分账以获取i-dollar或兑换奖励和会员特权。会员兑换奖励时需维持有效的会员资格。",
    membership_1_4_5: "1.4.5 其他会员促销活动",
    membership_1_4_5_1: "每一等级会员还可享受由御宝推出的其他促销活动，该等促销活动将由御宝不时向会员介绍及发送。会员可参阅会员门户网站，查询最新会员特权信息。请注意，御宝保留随时修改适用的会员特权的权利。",
    membership_1_5: "1.5 修改个人信息与会员身份终止",
    membership_1_5_1: "您可以通过我司的会员门户网站，随时修改您的个人信息（但不包括您的姓名、移动电话号码以及生日）。若您需要修改您的姓名、移动电话号码或生日，或选择终止会员身份，则您需要通过向cn.club@imperialtreasure.com发送电子邮件，以书面形式通知我司。相关修改需几个工作日才能生效。请注意，在终止会员身份时，我司不会退款和/或提前发放未使用的电子现金券。另外，您的会员身份将在不更新会员身份时自动失效，所有i-dollar和/或未使用的电子现金券均将视为过期。",
    membership_1_6: "1.6 终止与取消",
    membership_1_6_1: "若您不遵守本会员条款和条件，或者您以违反相关法律、法规或规定的方式使用您的会员特权，我司有权单方决定终止您的会员身份。",
    membership_1_7: "1.7 参与分店",
    membership_1_7_1: "所有会员在任何一家御宝消费时都可以获取i-dollars，而所有会员礼券（包括现金电子券和产品券）仅限在以下列出的中国御宝（“参与活动的分店”）用于抵消账单。",
    membership_1_7_1_1: "御宝轩",
    membership_1_7_1_1_1: "上海市黄浦区北京东路99号, 益丰•外滩源4楼L402-403  | (86) 21 5308 1188",
    membership_1_7_1_1_2: "上海市闵行区申长路688号, 虹桥天地购物中心L3-26  | (86) 21 5498 1822",
    membership_1_7_1_1_3: "广州市天河区兴民路222号, 天汇广场IGC五层L514B商铺  | (86) 20 3885 6382",
    membership_1_7_1_2: "御宝阁潮州酒家",
    membership_1_7_1_2_1: "广州市天河区天河路383号，太古汇商场L307 |（86）20 3868 2388",
    membership_2: "2. 法律和法院",
    membership_2_1: "这些条款，包括所有引用的政策和附加条款（“会员协议”），受中国大陆法律的管辖并按照其进行解释。",
    membership_2_2: "任何与会员协议或御宝会员俱乐部有关的争议、纠纷或索赔都应首先通过有关各方友好协商解决。若争议无法通过友好协商解决，则任何争议或索赔均可根据申请仲裁时上海国际仲裁中心的有效仲裁规则，提交给上海国际仲裁中心进行仲裁。仲裁裁决为最终裁决，并对各方具有约束力。",
    membership_3: "3. 同意",
    membership_3_1: "我期望加入御宝会员俱乐部，并同意御宝按照《隐私政策》处理我的个人信息。",
    privacy: "御宝隐私政策",
    privacy_1: "1. 介绍",
    privacy_1_1: "御宝餐饮（上海）有限公司及其子公司（包括Imperial Treasure F&B (Shanghai) Co., Ltd.、广州御宝餐饮有限责任公司、御宝星洲餐饮管理（上海）有限公司和御宝餐饮经营管理（上海）有限公司，以下简称“御宝”、“我们”）致力于保护访问该网站和微信公众号（合称“网站”）的访客的隐私。本隐私政策说明了御宝如何处理您通过网站提供给我们的个人信息。通过访问网站，您同意并接受本隐私政策中描述的个人信息的收集、使用和披露。若您不同意本隐私政策，请不要使用该网站。本隐私政策不适用于链接到的其他网站（“第三方网站”）。",
    privacy_1_2: "本隐私政策还描述了您的个人信息保护权利，包括拒绝御宝进行某些个人信息处理的权利。有关您的权利及如何行使这些权利的更多信息，请参见下文“8.我拥有何种权利”一节。",
    privacy_1_3: "保护客户信息的隐私对我们来说非常重要。我们不向第三方披露、出租或出售我们客户的个人信息（除本隐私政策中“5. 披露个人信息给第三方”的说明外）。",
    privacy_2: "2. 收集与使用个人信息",
    privacy_2_1: "我们为如下目的收集您的个人信息：",
    privacy_2_2_1: "履行合同或与合同相关的步骤：若您从我们这里购买商品或服务，这将与您相关。这包括：",
    privacy_2_2_1_1: "履行您的订单并将商品送到您手中，为此，我们将收集您的地址（包括不同的送货地址）、电话号码、账单地址和订单信息。",
    privacy_2_2_1_2: "为方便您在我们的餐厅或网站上购买并跟踪订单，我们将收集您的姓名、电话号码和订单信息。",
    privacy_2_2_1_3: "为了提供您所需的服务支持，我们将收集您的姓名、电话号码和问题。",
    privacy_2_2_2: "根据御宝开展业务和追求合法利益的需要，我们将以下信息用于以下特定目的：",
    privacy_2_2_2_1: "为了方便您注册成为网站用户，我们将收集您的姓名、国家、出生日期、电子邮件地址和电话号码。",
    privacy_2_2_2_2: "为了提供您所请求的产品和服务，我们将收集您的姓名、地址（包括不同的配送地址）、电话号码、账单地址和订单信息。",
    privacy_2_2_2_3: "若在网站上进行任何购买时使用银行卡付款，我们会使用第三方机构检测您提交的账户号码、卡号等信息的有效性，以防止欺诈行为，为此我们将收集您的信用卡号码。",
    privacy_2_2_2_4: "为了回应评论、查询和反馈，我们将收集您的姓名、电话号码、请求和问题。",
    privacy_2_2_3: "当您访问我们的网站时，为了提供更好的浏览体验、提高我们的服务质量以及履行我们的网络安全义务，我们将收集您的操作系统、浏览器或设备（如电脑、iPad、智能手机）特征，您的 IP 地址以及您与网站内容进行的交互。在交互过程中收集的信息包括页面浏览量、使用的搜索关键词、访问网站的频率以及对广告的点击次数。",
    privacy_2_2_4: "当您向我们给予同意时：",
    privacy_2_2_4_1: "我们将向您提供关于我们的产品的营销和促销材料以及其他相关信息（包括促销和特别优惠），以及我们的关联公司和合作伙伴提供的其他产品和服务",
    privacy_2_2_4_2: "在其他需要征得您同意的场合，我们将根据当时说明的目的使用您的个人信息。",
    privacy_2_2_5: "根据法律要求的目的：回应政府或执法机构的调查请求。",
    privacy_2_3_1: "您的姓名、姓氏、联系电话、出生日期、所在国家代码和电子邮件地址是必需的，以便我们联系您并履行您的任何订单：若缺少任何必要的个人信息，我们将无法履行您的订单。您的其他信息提供是可选的。",
    privacy_2_3_2: "此外，通过网站，我们可以收集有关网站使用的某些聚合和匿名信息，例如网站某些区域的访问频率，包括通过使用cookie、网络信标、Google Analytics和其他技术。",
    privacy_2_4: "我们可能使用 cookie、网络信标、Google Analytics 和其他技术来实现以下的目的：",
    privacy_2_4_1: "确认 Web 服务器出现问题的原因并解决这些问题；",
    privacy_2_4_2: "改进网站内容；",
    privacy_2_4_3: "根据您的个体兴趣或目的自定义网站内容和我们的电子邮件，并为您选择最匹配的页面，包括跟踪您过去的购买记录以提供新的服务或特别促销；",
    privacy_2_4_4: "改进我们的服务和产品的质量并提高您的用户体验；和",
    privacy_2_4_5: "获取匿名的网站访问者和聚合的网站访问统计个人信息。",
    privacy_2_5: "您可以通过配置浏览器来拒绝我们使用 cookie。然而，您可能无法再接收到依赖于 cookie 使用的网站服务或个性化功能。",
    privacy_3: "3. 基于合法利益",
    privacy_3_1: "我们已经上述对基于我们合法利益进行的所有个人信息处理活动进行了权衡测试。您可以通过使用本隐私政策后面列出的详细信息与我们联系，以获取有关任何权衡测试的信息。",
    privacy_4: "4. 撤回同意或拒绝直接营销",
    privacy_4_1: "在我们依赖您的同意进行个人信息处理的情况下，您都可以随时撤回同意。但是，我们可能有其他处理您的个人信息的合法性基础，例如：",
    privacy_4_1_1: "为订立、履行个人作为一方当事人的合同所必需；",
    privacy_4_1_2: "为履行法定职责或者法定义务所必需；",
    privacy_4_1_3: "为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；",
    privacy_4_1_4: "为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；",
    privacy_4_1_5: "依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；",
    privacy_4_1_6: "法律、行政法规规定的其他情形。",
    privacy_4_2: "我们在发送直接营销信息之前会征得您的同意。您有权随时选择退出直接营销或拒绝我们为了进行直接营销的所开展的个人档案分析。若该政策以电子形式发送，您可以按照其中的说明进行操作；若该政策以其他形式发送，您可以通过以下方式与我们联系（参见“11.联系我们”部分）。",
    privacy_5: "5. 披露个人信息给第三方",
    privacy_5_1: "为向您提供服务，我们可能会按照本隐私政策的描述披露和/或转移您的个人信息至中国大陆以外的地区，包括：",
    privacy_5_1_1: "基于履行与您的合同、提供客户服务或会员服务和协助您注册为网站用户的目的，我们可能会与我们的关联公司分享您的个人信息。",
    privacy_5_1_2: "我们可能会允许某些可信赖的第三方在服务期间处理或访问您的个人信息，包括：（1）维护和操作网站及网站上某些功能；（2）为我们的会员提供服务。例如，我们可能使用第三方来托管网站；操作网站上的各种功能；发送电子邮件；分析个人信息；提供搜索结果和链接，并帮助您完成订单，可能会与我们的母公司、子公司、部门和附属公司共享个人信息或其他信息。我们还可能使用第三方的服务来管理会员计划。",
    privacy_5_1_3: "我们可能会雇用第三方服务来收集上述信息，包括个人信息，并代表我们使用其跟踪服务。但是，这些信息是严格保密的，不会与他人共享。",
    privacy_5_1_4: "在与我们的业务全部或部分进行拟议或实际的合并或销售（包括任何破产或破产程序中的披露）或作为公司重组、股票出售或其他控制变更的一部分，我们可能会将个人信息披露为资产。",
    privacy_5_1_5: "我们保留披露信息的权利，包括个人信息，以遵守传票、法院命令、行政或政府命令或任何其他法律要求的情况；或当我们在自己的唯一判断下认为有必要为了保护我们的权利或他人的权利，防止对人或财产造成伤害，打击欺诈和信用风险减少时。我们还可能根据法律规定允许或要求披露和转移个人信息。",
    privacy_5_1_6: "我们可能向第三方披露匿名化后的个人信息和汇总的个人信息。",
    privacy_5_2: "您的个人信息可能会不时被出境至中国大陆以外的国家或地区。上述个人信息出境将按照中国大陆的个人信息保护法实行。个人信息的境外接收方信息如下：",
    privacy_5_3_1_1: "境外接收方名称",
    privacy_5_3_1_2: "Xgate Pte Ltd",
    privacy_5_3_2_1: "联系方式",
    privacy_5_3_2_2: "data.privacy@xgate.com",
    privacy_5_3_3_1: "处理目的与方式",
    privacy_5_3_3_2: "处理目的：向会员提供与会员资格相关的福利和服务。\n\n处理方式：会员的个人信息将被转移到境外接收方，随后，若御宝轩接到会员关于会员福利或服务的请求，境外接收方将使用此类个人信息来验证会员的身份。",
    privacy_5_3_4_1: "个人信息类别",
    privacy_5_3_4_2: "姓名，会员级别，会员有效期",
    privacy_6: "6. 我们如何保护您的个人信息",
    privacy_6_1: "我们致力于保护从您处获得的信息。我们采用合理的物理、技术和管理措施来帮助保护存储在我们系统中的个人信息，包括个人信息的机密性、安全性和完整性。我们相信我们实施的措施可以将安全问题的可能性降至适合所涉及个人信息类型的水平。虽然我们采取合理措施保护从您处获得的信息，但是没有计算机系统是完全安全的，我们不能对未经授权访问或任何个人信息丢失而导致的任何损失承担责任。",
    privacy_7: "7. 第三方网站链接",
    privacy_7_1: "本网站可能包含访问第三方网站和微网站的链接，包括第三方支付网关，其隐私实践可能与我们不同。若您向这些网站提交个人信息，包括付款信息，则这些个人信息不受本隐私政策的约束，而将受到第三方的隐私实践的约束。",
    privacy_7_2: "我们建议您查阅您访问的任何网站的隐私政策。单击或激活此类链接并离开网站，我们将无法再对您在离开网站后向任何其他实体提供的任何个人信息行使控制权，任何对此类其他网站的访问都完全由您自行承担风险。",
    privacy_8: "8. 我拥有何种权利",
    privacy_8_1: "您有权要求我们提供您个人信息的副本，或者纠正、删除或限制（停止所有活动）处理您的个人信息，并以结构化、机器可读格式获取您向我们提供的个人信息，以及要求我们将此个人信息分享（移植）给另一个人信息控制者。您有权通过联系cn.club@imperialtreasure.com注销您的账户。",
    privacy_8_2: "此外，在某些情况下（特别是我们不是基于满足合同或其他法律要求而处理个人信息的情况下），您可以拒绝我们对您的个人信息的处理。",
    privacy_8_3: "这些权利的行使在特定情况下可能会受到限制，例如，当履行您的请求将揭示有关其他人的个人信息并导致对第三方（包括我们的）权利的侵犯，或者您要求我们删除我们根据法律要求保留或基于重大迫切合法利益保留的信息。相关的豁免条款存在于适用的法律法规中。我们将在回复您提出的请求时告知我们所依赖的相关豁免依据。",
    privacy_8_4: "为行使任何这些权利或获取其他信息（例如合法利益平衡测试的副本），您可以联系我们的个人信息保护经理（参见下文“11.联系我们”）。要更正您的个人信息，您可以登录您的用户账户并更新您的个人信息。我们仅会根据访问请求披露个人信息至注册并提交其个人信息给网站的个人，并且只会在确认请求访问的人确实是注册方之后才披露。",
    privacy_8_5: "您还可以联系我们的个人信息保护经理撤回您对我们使用个人信息的同意。",
    privacy_8_6: "我们需要十个工作日以处理您的个人信息请求。",
    privacy_9: "9. 个人信息的存储",
    privacy_9_1: "在您的账户为活跃状态，以及在此状态结束后的六年内，我们将处理您的注册信息。",
    privacy_9_2: "若我们根据您的同意或为营销目的处理个人信息，我们将在您要求停止处理之前以及在您要求停止处理之后的短时间内（以便我们实施您的请求）一直处理此类个人信息。我们还会记录您要求我们不发送直接营销或不处理您的个人信息的情况，以便我们持续遵守您的要求。",
    privacy_9_3: "若我们根据履行合同的需要处理个人信息，我们将在您上一次与我们互动的六年之内保存此类个人信息。",
    privacy_9_4: "若您撤回我们收集、使用或披露您的个人信息的同意，我们可能无法向您提供需要此类个人信息的服务。请注意，根据法律规定，您的个人信息仍可能保存在我们的数据库中。",
    privacy_10: "10. 隐私政策的变更",
    privacy_10_1: "若我们决定更改网站的隐私政策，我们将在此处发布修订后的隐私政策，并在适当的情况下通过电子邮件通知您。请经常查看我们的隐私政策以查看任何更新或更改。",
    privacy_11: "11. 联系我们",
    privacy_11_1: "若您对本隐私政策有任何疑问，请通过 DPO@imperialtreasure.com 联系我们的个人信息安全保护经理。",
  },
  outlets: {
    outlets: "Participating Restaurants",
    restaurant_name: "Restaurant Name",
    address: "Address",
    contact: "Contact",
    email: "Email",
    outlets_1_1: "Imperial Treasure Fine Chinese Cuisine (Yi Feng)",
    outlets_1_2: "L402-403, 4/F, Yi Feng Galleria, \nNo. 99 East Beijing Road, \nHuangpu District, Shanghai 200002",
    outlets_1_3: "(86) 21 5308 1188",
    outlets_1_4: "shanghai@imperialtreasure.com",
    outlets_2_1: "Imperial Treasure Fine Chinese Cuisine (The Hub)",
    outlets_2_2: "L326-329, 3/F, The Hub Mall \nNo. 688 Shen Chang Road,\nMin Hang District, Shanghai 201105",
    outlets_2_3: "(86) 21 5498 1108",
    outlets_2_4: "hongqiao_finechinese@imperialtreasure.com",
    outlets_3_1: "Imperial Treasure Fine Chinese Cuisine (IGC)",
    outlets_3_2: "L514B, 5/F, International Grand City (igc), \nNo. 222 Xing Min Road, Tianhe District, Guangzhou 510623",
    outlets_3_3: "(86) 20 3885 6382",
    outlets_3_4: "finechinese_igc@imperialtreasure.com",
    outlets_4_1: "Imperial Treasure Fine Teochew Cuisine (Taikoo Hui)",
    outlets_4_2: "L307, 3/F, Taikoo Hui \nNo. 383 Tianhe Road \nTianhe District, Guangzhou 510620",
    outlets_4_3: "(86) 20 3868 2388",
    outlets_4_4: "fineteochew_taikoo@imperialtreasure.com",
  },
  privilege: {
    title: "PRIVILEGES",
    privilege: "MEMBERSHIP PRIVILEGES",
    privelege_txt: "Earn i$1 with every CNY10 # spent at participating restaurants.\nAccumulate i$250 to receive a CNY250 cash e-voucher for use at participating restaurants.",
    silver: "SILVER",
    signup: "CNY 438 SIGN UP",
    renewal: "CNY 180 RENEWAL \n(From 2nd Year Onwards)",
    silver_1: "2 X CNY238 Sign Up e-voucher (valid for 3 months)",
    silver_2: "1 X CNY480 Birthday e-voucher (valid only during birthday month with a minimum spend of CNY1,500) ",
    silver_3: "1 X CNY238 Sign Up e-voucher (valid for 3 months)",
    silver_4: "1 X CNY480 Birthday e-voucher (valid only during birthday month with a minimum spend of CNY1,500) ",
    gold: "GOLD",
    gold_1: "Upgrade to GOLD tier when you spend CNY50,800 # and above within the membership year.",
    gold_2: "All of SILVER tier benefits plus:",
    gold_3: "Waiver of Annual Renewal Fee",
    gold_4: "Complimentary treats: \n1 x Peking Duck \n1 x Pigeon (3pcs) \n2 x CNY Festive Cake (seasonal) \n2 x Rice Dumpling Set (seasonal) \n2 x Mid Autumn Mooncake Set (seasonal)",
    diamond: "DIAMOND",
    diamond_1: "Upgrade to DIAMOND tier when you spend CNY 148,888 # and above within the membership year. ",
    diamond_2: "All of GOLD tier benefits plus:",
    diamond_3: "Complimentary treats: \n1 x Suckling Pig  \n1 x CNY Festive Cake (seasonal) \n1 x Rice Dumpling Set (seasonal) \n1 x Mid Autumn Mooncake Set (seasonal)",
    ser_charge_note: "# Does not include service charge (if any), other terms and conditions apply.",
    sign_up_now: "Sign up now",
  },
  resetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    new_password: "*New password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm new password",
    confirm_password_errMsg: "Please confirm password",
    reset_password2: "Reset password",
    has_been_reset: "Password has been reset!",
    login: "Login",
  },
  forgetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    forgot_password: "Forgot password?",
    reset_pw_method: "Please select password reset method",
    by_mobile: "By mobile",
    by_email: "By email",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    reset_password2: "Reset password",
    reset_password_sent_email: "Reset password link has been sent to your email.",
    reset_password_sent_mobile: "Reset password link has been sent to your mobile.",
    next: "Next",
    back: "Back",
  },
  updateEmail: {
    title: "Update Email",
    member_profile: "Member Profile",
    updateEmail: "Update Email",
    new_email: "*New Email Address",
    sent: "Sent",
    send_token: "Send Token",
    email_errMsg1: "Please enter valid email",
    email_errMsg2: "Email already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  updateMobile: {
    title: "Update Mobile",
    member_profile: "Member Profile",
    updateMobile: "Update Mobile",
    new_mobile: "*New Mobile No.",
    sent: "Sent",
    send_token: "Send Token",
    mobile_errMsg1: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  coupon: {
    title: "Vouchers",
    member_profile: "Member Profile",
    coupon: "Vouchers",
    birthdayNote: "Your birthday e-voucher will only be reflected during your birthday month.",
    active: "Active",
    redeemed: "Redeemed",
    expired: "Expired",
    type_of_vouchers_opt1: "Please select type of vouchers",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "Expiry Date:",
    redeemed_store: "Redeemed Outlet:",
    vaild_date: "Vaild Date",
    description: "Description",
    vouchers_code: "Voucher Code",
    done: "Done",
    no_coupon_msg: "No coupons available",
  },
  editProfile: {
    title: "Edit Profile",
    member_profile: "Member Profile",
    editProfile: "Edit Profile",
    general: "General Information",
    security: "Security",
    subscriptions: "Subscriptions",
    salutation: "*Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    gender: "*Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    first_name: "*Given Name",
    first_name_errMsg: "Please enter given name",
    last_name: "*Family Name",
    last_name_errMsg: "Please enter family name",
    birth: "*Date of Birth",
    mobile: "*Mobile No.",
    mobile_change: "Change",
    email: "*Email",
    email_change: "Change",
    language: "*Language",
    language_opt1: "Please select your language preference",
    language_opt2: "English",
    language_opt3: "简体中文",
    update: "Update",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please confirm password",
    subscriptions_txt1: "By checking the box(es) below, I give my consent to Imperial Treasure F&B Shanghai Co., Ltd ('ITRG') to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club Membership ('Data') for ITRG to share my data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of China.",
    subscriptions_txt2: "I would like to receive marketing and promotional messages and materials from Imperial Treasure Restaurant Group Pte Ltd",
    by_email: "By Email",
    by_mobile: "By Mobile",
    update_success: "Update Success!",
    done: "Done",
    password_update_success: "Password Update Success!",
  },
  profile: {
    title: "Member",
    logout: "Logout",
    click_to_renew: "CLICK TO RENEW​",
    activation_now: "ACTIVATE NOW",
    menber_id: "Member ID:",
    member: "member",
    active: "Active",
    expired: "Expired",
    salutation: "Salutation:",
    mobile: "Mobile No.:",
    birth: "Date of Birth:",
    password: "Password:",
    ellipsis: "········",
    change: "Change",
    gender: "Gender:",
    email: "Email:",
    cycle_start_date: "Tier Start Date:",
    first_joined_date: "First Joined Date:",
    optin_email: "Opt-in Email:",
    optin_sms: "Opt-in SMS:",
    cycle_expiry_date: "Cycle Expiry Date:",
    language_preference: "Language Preference:",
    spend: "CNY Spend",
    expires_on: "  Expires on",
    spend_required: "Nett Spend required",
    to_upgrade_to: "to upgrade to",
    to_maintain_to: "to maintain",
    gold_member: " Gold",
    diamond_member: " Diamond",
    total_nett_spend: "Total Nett Spend",
    current_membership: "in current membership year*",
    i_dollar: "i-Dollar",
    last_update: "  Last Update : ",
    i_dollar_earned: "i-Dollar Earned",
    i_dollar_converted: "i-Dollar Converted",
    i_dollar_balance: "i-Dollar Balance",
    i_dollar_automatically: "$250 i-Dollars are automatically converted to CNY250 Cash e-voucher",
    i_dollar_expired_date: "Balance i-dollar will expire on",
    i_dollar_expired_mth: "3 months from current cycle's expiry date",
    type_btn_profile: "PROFILE",
    type_btn_vouchers: "VOUCHERS",
    type_btn_transactions: "TRANSACTIONS",
    type_btn_referral: "FRIEND REFERRAL",
    vouchers: "Vouchers",
    currently_active: "Currently Active",
    cash_vouchers: "Cash Vouchers",
    food_vouchers: "Food/Promotion Vouchers",
    click_to_view: "CLICK TO VIEW",
    transaction: "Transactions",
    this_month: "This Month",
    last_3_months: "Last 3 Months",
    last_12_months: "Last 12 Months",
    transaction_details: "Transaction Details",
    transaction_date: "Transaction Date",
    transaction_time: "Transaction Time",
    outlet: "Outlet",
    receipt: "Receipt No.",
    payment_method: "Payment Method",
    receipt_details: "Receipt Details",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS EARNED:",
    total: "Total :",
    done: "Done",
    thankyou_msg: "Thank you for dining with us!",
    activate_membership: "ACTIVATE MEMBERSHIP",
    activate_membership_1: "Activate your membership with just CNY108 to enjoy the following privileges:",
    activate_membership_2: "Earn i$1 with every CNY10 spent. Accumulate i$250 to receive a CNY250 cash e-voucher",
    activate_membership_3: "1 X CNY238 Sign Up e-voucher (valid for 3 months)",
    activate_membership_4: "1 X CNY480 Birthday e-voucher (valid only during birthday month with a minimum spend of CNY1,500) ",
    activate_membership_7: "Be the first to receive latest updates and exclusive invitations to private events!",
    renew_membership: "RENEW MEMBERSHIP",
    renew_membership_1: "Renew your membership with just CNY108 to enjoy the following privileges:",
    renew_membership_2: "Earn i$1 with every CNY10 spent. Accumulate i$250 to receive a CNY250 cash e-voucher",
    renew_membership_3: "1 X CNY238 Sign Up e-voucher (valid for 3 months)",
    renew_membership_4: "1 X CNY480 Birthday e-voucher (valid only during birthday month with a minimum spend of CNY1,500) ",
    renew_membership_5: "Be the first to receive latest updates and exclusive invitations to private events! ",
    confirm_to_renew: "Confirm To Renew",
    confirm_to_activate: "Confirm To Activate",
    change_password: "Change Password",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter & confirm password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)​",
    confirm_password: "*Confirm Password",
    close: "X CLOSE",
    member_activated: "Member activated",
    password_updated: "Password updated",
    friend_referral: "Friend Referral",
    view_more: "VIEW MORE",
    add_friend: "ADD",
    silver_tier: "Silver ",
    gold_tier: "Gold ",
    diamond_tier: "Diamond ",
    spend_date_format: "Expired year",
  },
  friendReferral: {
    title: "Friend Referral",
    email: "EMAIL",
    referee_email: "Referee's Email",
    emailAddress_errMsg: "Please enter valid email.",
    mobile: "MOBILE NUMBER",
    referee_mobile: "Referee’s Mobile",
    mobile_errMsg: "Please enter valid mobile",
    mobile_errMsg2: "Phone already registered",
    name: "NAME",
    referee_name: "Referee's Name",
    name_errMsg: "Please enter referee's name.",
    referral_sucMsg: "You have successfully referred your friend!",
    referral_errMsg: "Referee is an existing registered Imperial Treasure Club member.",
    submit: "Submit",
  },
  transactionDetails: {
    transaction_date: "Transaction Date",
    outlet: "Outlet",
    receipt_no: "Check/Receipt No",
    amount_spend: "Total Paid",
    amount_qualified_for_i_dollar: "Amount Qualified For i-Dollar",
    i_dollars: "i-Dollars",
    selected_start_date: "Selected start date",
    selected_end_date: "Selected end date",
    no_tranMsg: "No transaction records during the selected period.",
    action: "Action",
    apply: "Apply",
    view: "View",
  },
  contactUs: {
    title: "Contact Us",
    title_message: "Please complete and submit your feedback below and we will get back to you within 3-5 working days.",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    type: "Type",
    type_opt1: "Please enter your feedback message type :",
    type_opt2: "Options",
    type_opt3: "Enquiry",
    type_opt4: "Feedback",
    type_opt5: "Newsletter Subscription",
    type_errMsg: "Please enter your feedback message type",
    message: "Message",
    message_errMsg: "Please enter your feedback message",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    feedback_sucMsg: "Thank you for reaching out and providing us with valuable feedback. \nWe will respond to you very soon.",
  },
  languageSetting: {
    title: "Language Setting",
    setting: "Setting",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "Please select language preference",
    submit: "Submit",
  },
};