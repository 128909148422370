import AsyncStorage from "../components/AsyncStorage"
import queryString from "query-string";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";

const StorageKey = 'wechatPayStorage'
// const OrderStorageKey = 'wechatOrder'
// const appId = 'wxeca2e361be1de14c'
const isTest = false
const PaymentReturnParameterKey = "payStatus"
const PaymentSuccess = "ok"
const MiddlePageOfPayment = 'https://loyalty.xgatecorp.com/wechat/payment/jsApi/pay'
export const WechatPaySuccess = 'success'
export const WechatPayFaild = 'failed'

//   data:{
//     amount: "0.01",
//     data: {
//       appId: "wxeca2e361be1de14c", 
//       nonceStr: "25888e4afc450c64fdf4835925337802", 
//       package: "prepay_id=wx291831192802564d53fe937ce3b41f0000", 
//       paySign: "B4ED09EB2036341D73CC4D92A27D69E3", 
//       signType: "MD5",
//       timeStamp: 1669717879369
//     },
//     description: "Imperial Silver Member",
//     invoiceno: "202211291831008",
//     status: "success"
//   },

const _StorageData = {
  token: '',
  orderinfo: {},
  isNeedCheckPaymend: false
}
const _StorageDataHandler = {
  get: function (target, key) {
    return target[key]; // 不是target.key
  },
  set: function (target, key, value) {
    target[key] = value;
    AsyncStorage.storeData(StorageKey, JSON.stringify(target))
    return true
  }
}

/**
 * @typedef JSBridgeConfig
 * @property  {string} appId 
 * @property  {string} nonceStr 
 * @property  {string} package 
 * @property  {string} paySign 
 * @property  {string} signType 
 * @property  {number} timeStamp 
 * 
 * @typedef Orderinfo
 * @property  {string} amount 
 * @property  {string} description 
 * @property  {string} invoiceno 
 * @property  {string} status 
 * @property  {JSBridgeConfig} data 
 * 
 * @typedef StorageData
 * @property  {string} token 
 * @property  {Orderinfo} orderinfo 
 * @property  {boolean} isNeedCheckPaymend 
 * 
 * @param {StorageData} data
 */
function forType(data, handler) {
  return new Proxy(data, handler)
}

export const StorageData = forType(_StorageData, _StorageDataHandler)

export const WechatPay = {
  openid: null, // 用户openid
  wechatFlag: false, // 是否是微信环境
  JSBridgeConfig: {}, // 支付配置
  orderinfo: {},
  StorageData,
  /**
   * 从链接获取openid
   */
  getOpenidFromQuery() {
    const parsed = queryString.parse(location.search);
    console.log('query:', parsed)
    if (parsed.token) {
      try {
        let openid = global.atob(parsed.token)
        console.log(openid)
        if (openid) {
          this.openid = openid
          StorageData.token = parsed.token
        }
      } catch (error) {
        console.log(error)
      }

    }
  },
  /**
   * 从storage获取openid
   */
  async getOpenidByStorage() {
    let token = StorageData.token
    let openid = ""
    console.log('storage token :', token)
    if (!token) {
      await this.initStoreData()
      token = StorageData.token
    }
    if (token) {
      try {
        openid = global.atob(token)
        console.log('storage openid :', token)
      } catch (error) {

      }
    }
    return openid
  },
  /**
   * 判断是否为微信内部登录
   */
  isWeChat() {
    //判断是否为微信内部登录
    var ua = window.navigator.userAgent.toLowerCase();
    //console.log(ua);
    this.wechatFlag = false
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.wechatFlag = true;//是微信内部
    }
    return this.wechatFlag;
  },
  /**
   * 获取链接上的加密openid， 页面初始化完成后使用
   */
  async init() {
    this.wechatFlag = this.isWeChat();//本人项目提交时需要判断区分，所以就定义了全局
    await this.initStoreData()
    console.log('iswechat:', this.wechatFlag)
    if (this.wechatFlag) {
      //当前在微信内部 do someThing
      this.getOpenidFromQuery()
    } else {
      isTest && this.getOpenidFromQuery() // just for test
      //外部浏览器 do someThing
      console.log('请在微信公众号中打开该页面')
    }
  },
  async initStoreData() {
    let re = await AsyncStorage.getData(StorageKey)
    if (re) {
      try {
        Object.assign(_StorageData, JSON.parse(re))
      } catch (error) {

      }
    }
    return StorageData
  },
  /**
   * 获取微信支付配置
   * @param {string} memberCode 
   * @param {(status)=>void} cb 
   */
   async getConfig(memberCode, isRenew, cb) {
    console.log('memberCode', memberCode)
    if (!this.wechatFlag) {
      /* Qrcode payment flow */

      let formData = new FormData();
      formData.set("method", "payment");
      formData.set("region", API_REGION);
      formData.set("code", memberCode);
      formData.set("wechat", "0");

      if (isRenew) {
        formData.set("type", "renew");
      }
      
      let re = await axios.post(API_MIDDLEWARE, formData)
      let orderinfo = re.data
      StorageData.orderinfo = orderinfo

      if (orderinfo.status == "success") {
        cb("qrcode", orderinfo.data.qrCode)
        return 
      } else {
        cb(WechatPayFaild)
        return 
      }

      // alert('请在微信公众号中打开该页面')
      // if (!isTest) {
      //   cb('other')
      //   return
      // }
    }
    if (!this.openid) {
      this.openid = await this.getOpenidByStorage()
      console.log('this openid :', this.openid)
    }
    if (!isTest && !this.openid) {
      alert('请重新在微信公众号中打开该页面')
      cb('other')
      return
    }
    let formData = new FormData();
    formData.set("method", "payment");
    formData.set("region", API_REGION);
    formData.set("code", memberCode);
    formData.set("openid", this.openid);
    formData.set("wechat", "1");

    let re = await axios.post(API_MIDDLEWARE, formData)
    /**
     * deprecated
     */
    // re = {
    //   config: {},
    //   data: {
    //     amount: "0.01",
    //     data: {
    //       appId: "wxeca2e361be1de14c",
    //       nonceStr: "25888e4afc450c64fdf4835925337802",
    //       package: "prepay_id=wx291831192802564d53fe937ce3b41f0000",
    //       paySign: "B4ED09EB2036341D73CC4D92A27D69E3",
    //       signType: "MD5",
    //       timeStamp: 1669717879369
    //     },
    //     description: "Imperial Silver Member",
    //     invoiceno: "202211291831008",
    //     status: "success"
    //   },
    //   headers: { "content-type": "text/html; charset=UTF-8" },
    //   request: {},
    //   status: 200,
    //   statusText: "OK"
    // }
    /**
     * deprecated
     */
    // re = {
    //   config: {},
    //   data: {
    //     "code": "200",
    //     "message": null,
    //     "data": {
    //       "orderNumber": "20230605001",
    //       "pluginNo": "hipopayWechatMiniProgramPaymentPlugin",
    //       "credential": {
    //         "url": null,
    //         "qrCode": null,
    //         "method": null,
    //         "paymentReqParam": null,
    //         "parameterMap": {
    //           appId: "wxeca2e361be1de14c",
    //           nonceStr: "25888e4afc450c64fdf4835925337802",
    //           package: "prepay_id=wx291831192802564d53fe937ce3b41f0000",
    //           paySign: "B4ED09EB2036341D73CC4D92A27D69E3",
    //           signType: "MD5",
    //           timeStamp: 1669717879369
    //         }
    //       }
    //     }
    //   },
    //   headers: {
    //     "content-type": "text/html; charset=UTF-8"
    //   },
    //   request: {},
    //   status: 200,
    //   statusText: "OK"
    // }

    // re = {
    //   config: {},
    //   data: {
    //     "status": "success",
    //     "data": {
    //       "paymentReqParam": null,
    //       "parameterMap": {
    //         "timeStamp": "1686537191",
    //         "package": "prepay_id=wx12103311348707889403a3561572720000",
    //         "paySign": "mDDpiRFAslzA1/0qZaXMwkYPZTKhOdJuYYE9fChSNgZMiqAN6wKf8G22pBDW69Gb6BBRolASpXxU8bA3WwlAc19uCZeSwzMVUgT7/L4YevyvuCp6RSNP/H8ufV9liMiqIsW6pC9ZCVlArAkrHOQEojJvvCTb7sd6b1ZLie8RKReONoaKFA7ZzfCQBoQqvBd5Zr78D6z0cOjOVVZ4nQB7/mNsrlKuZVrb1hKzJH+lsEUqTuzWHhFrxSDSjz2+hNLu6KptzQ0JMpS9KjSix0qq0r2lIyWpdSfCKfRZcB5zoVdiPjb7EuzEQh5zgyDg22iKFEFPQbaLm4AQG4CLlYjQlQ==",
    //         "appId": "wxeca2e361be1de14c",
    //         "signType": "RSA",
    //         "nonceStr": "VjLuzvMHXiX7xaa1686537191430"
    //       }
    //     },
    //     "invoiceno": "PM20230612103309c07b6e102b7bb7fa60cf717df92de521",
    //     "description": "Imperial Silver Member",
    //     "amount": "0.01"
    //   },
    //   headers: { "content-type": "text/html; charset=UTF-8" },
    //   request: {},
    //   status: 200,
    //   statusText: "OK"
    // }
    let orderinfo = re.data
    StorageData.orderinfo = orderinfo
    if (orderinfo.status !== WechatPaySuccess) {
      cb(WechatPayFaild)
      return
    }
    let data = {}
    try {
      data = orderinfo.data.parameterMap
    } catch (error) {

    }
    this.JSBridgeConfig = {
      "appId": data.appId,   //公众号ID，由商户传入    
      "timeStamp": data.timeStamp,   //时间戳，自1970年以来的秒数    
      "nonceStr": data.nonceStr,      //随机串    
      "package": data.package,
      "signType": data.signType,     //微信签名方式：    
      "paySign": data.paySign
    }
    // this.initJsBridge(cb)
    this.getPaymentPageURL(cb)
  },
  /**
   * 获取支付中间页面链接
   * @param {(status)=>void} cb 
   */
  getPaymentPageURL(cb) {
    if (typeof cb === 'function') {
      let self = (location.href.split('?'))[0]
      cb(
        WechatPaySuccess,
        `${MiddlePageOfPayment}?appId=${this.JSBridgeConfig.appId
        }&nonceStr=${this.JSBridgeConfig.nonceStr}&package=${this.JSBridgeConfig.package
        }&paySign=${this.JSBridgeConfig.paySign}&signType=${this.JSBridgeConfig.signType
        }&timeStamp=${this.JSBridgeConfig.timeStamp}&redirectUrl=${self
        }`
      )
    }
  },
  /**
   * 拉起微信支付
   * @param {(status)=>void} cb 
   */
  onBridgeReady(cb) {
    WeixinJSBridge.invoke('getBrandWCPayRequest', this.JSBridgeConfig,
      function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (typeof cb === 'function') {
            cb(WechatPaySuccess)
          }
        } else {
          cb(WechatPayFaild)
        }
      });
  },
  /**
   * 根据环境，使用适当的方式拉起微信支付
   * @param {(status)=>void} cb 
   */
  initJsBridge(cb) {
    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady.bind(this, cb), false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady.bind(this, cb));
        document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady.bind(this, cb));
      }
    } else {
      this.onBridgeReady(cb);
    }
  },
  /**
   * 检查是否是支付成功后返回
   * @param {(payStatus: 'ok'|'failed')} cb 
   */
  checkUrlQuery(cb) {
    if (typeof cb !== 'function') {
      return
    }
    const parsed = queryString.parse(location.search);
    console.log('query:', parsed)
    let status = parsed[PaymentReturnParameterKey]
    if (status && status === PaymentSuccess) {
      cb(WechatPaySuccess)
      return
    }
    cb('other')
  }
}




