import React from "react";
import { View, Text, Image, ImageBackground, TouchableOpacity, SafeAreaView } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Header from "../components/header"; // Web Header
import NewMobileHeader from "../components/newMobileHeader"; // Mobile Header
import Footer from "../components/footer";
import i18n from "../components/i18n";

const Privilege = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-full">
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <NewMobileHeader />
          <Header />
          <MobileHeader title={i18n.t("privilege.title")} />
          <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 md:mb-24 pb-10">
            <View className="hidden md:flex">
              <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">{i18n.t("privilege.privilege")}</Text>
            </View>

            <Text className="text-center text-base text-[#494949]">{i18n.t("privilege.privelege_txt")}</Text>

            <View className="flex flex-col md:flex-row flex-wrap items-start justify-between w-full mb-10 mt-3 mx-auto">
              <View className="w-full md:w-[32%] h-auto md:h-full bg-white border border-[#6D6E72] border-b-[12px] mb-4">
                <Text className="w-full bg-[#6D6E72] h-9 text-center text-white text-base leading-9">{i18n.t("privilege.silver")}</Text>

                <View className="py-6 px-4">
                  <View className="mt-2 mb-4">
                    <Text className="text-[#303030] font-semibold">{i18n.t("privilege.signup")}</Text>
                  </View>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/silver_dollar.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.silver_1")}</Text>
                  </View>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/silver_birthday.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.silver_2")}</Text>
                  </View>

                  <View className="mt-2 mb-4">
                    <Text className="text-[#303030] font-semibold">{i18n.t("privilege.renewal")}</Text>
                  </View>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/silver_dollar.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.silver_3")}</Text>
                  </View>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/silver_birthday.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.silver_4")}</Text>
                  </View>
                </View>
              </View>

              <View className="w-full md:w-[32%] h-auto md:h-full bg-white border border-brandgold border-b-[12px] mb-4">
                <Text className="w-full bg-brandgold h-9 text-center text-white text-base leading-9">{i18n.t("privilege.gold")}</Text>
                <View className="py-6 px-4">
                  <Text className="text-sm text-[#494949] mt-4 mb-4">{i18n.t("privilege.gold_1")}</Text>
                  <Text className="text-sm text-[#494949] mt-4 mb-6">{i18n.t("privilege.gold_2")}</Text>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/gold_renewal.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.gold_3")}</Text>
                  </View>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/gold_complimentary.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.gold_4")}</Text>
                  </View>
                </View>
              </View>

              <View className="w-full md:w-[32%] h-auto md:h-full bg-white border border-[#E2E2E4] border-b-[12px] mb-4">
                <Text className="w-full bg-[#E2E2E4] h-9 text-center text-black text-base leading-9">{i18n.t("privilege.diamond")}</Text>

                <View className="py-6 px-4">
                  <Text className="text-sm text-[#494949] mt-4 mb-4">{i18n.t("privilege.diamond_1")}</Text>
                  <Text className="text-sm text-[#494949] mt-4 mb-6">{i18n.t("privilege.diamond_2")}</Text>

                  <View className="mb-6 flex-row">
                    <Image source={require("../assets/icons/diamond_complimentary.png")} className="w-[32px] h-[32px] block my-auto self-center mr-4" />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">{i18n.t("privilege.diamond_3")}</Text>
                  </View>
                </View>
              </View>

              <View className="flex items-center w-full my-4">
                <Text className="text-center text-[#303030]">{i18n.t("privilege.ser_charge_note")}</Text>
              </View>
            </View>

            <TouchableOpacity className="w-full h-10 max-h-10 bg-brandgold mt-4 md:w-[300px] md:m-auto md:block md:text-center md:my-10" onPress={() => navigation.push("SignUp")}>
              <Text className="text-white w-full h-20 leading-10 text-center font-normal">{i18n.t("privilege.sign_up_now")}</Text>
            </TouchableOpacity>
          </View>

          <Footer />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Privilege;
