export default {
  welcomePage: {
    memberYet: "还不是御寶会员？",
    memberBenefit: "成为御寶忠诚计划会员后，在参与分店用餐时，即可享受专属礼遇、奖励和及时掌握最新动态及会员优惠信息。",
    memberPrivileges: "会员礼遇",
    privilege_txt1: "在参与分店每消费10元，即可赚取i$1",
    privilege_txt2: "累积i$250可兑换250元电子现金礼券，仅在参与分店使用;",
    privilege_txt3: "成功注册后获得2张238元电子礼券（有效期为3个月）",
    privilege_txt4: "获得1张480元的生日电子礼券（仅限生日当月，且单笔有效消费满1,500元时可使用）",
    signUpNow: "即时注册",
    login: "登录",
  },
  login: {
    login: "登 录",
    email: "电子邮箱",
    mobile: "手机号码",
    mobile2: "手机号码",
    password: "密码",
    errMsg1: "请输入电子邮箱和密码",
    errMsg2: "请输入手机号码和密码",
    errMsg3: "您的帐户不在此地区",
    errMsg4: "您的登录或密码不正確",
    errMsg5: "您的输入手机号码或密码不正確",
    errMsg6: "您的注册还未完成，请重新登记及完成付款步骤",
    forgotpw: "忘记密码？",
    register: "注 册",
    txt: "还不是御宝会员？查看",
    txt_2: "会员礼遇",
  },
  mbHeader: {
    home: "主页",
    register: "注册",
    aboutUs: "关于御宝",
    privileges: "会员礼遇",
    highlights: "优惠",
    restaurants: "参与分店",
    reservations: "预订",
    eStore: "电子商城",
    faqs: "常见问题",
    termsAndPrivacy: "条款与隐私",
    contact: "联系我们",
    language: "语言选项",
    logout: "登出",
  },
  header: {
    home: "首页",
    eStore: "电子商城",
    PRivileges: "会员礼遇",
    register: "注册",
    highlights: "亮点",
    faq: "常见问题",
    tnp: "条款和隐私",
    friendReferral: "朋友推荐",
    profile: "会员档案",
  },
  footer: {
    aboutUs: "关于御宝",
    eStore: "电子商城",
    faq: "常见问题",
    tnp: "条款与隐私",
    pr: "参与分店",
    profile: "会员档案",
    contactUs: "联系我们",
    copyright: "2023©御宝饮食集团版权所有",
  },
  signUp: {
    signUp: "注册会员",
    title: "注册御宝忠诚计划会员",
    mustFill: "“*” 为必填项",
    step1: "账户资料",
    step2: "安全信息",
    step3: "信息订阅",
    step4: "推荐人",
    salutation: "称谓",
    salutation_opt1: "请选择您的称谓",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "请选择称谓",
    firstname: "名字",
    firstname_errMsg: "请输入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "请输入您的姓氏",
    birth: "出生日期",
    birth_errMsg: "请选择出生日期",
    birth_errMsg2: "您必须年满18岁",
    mobile: "手机号码",
    mobile_errMsg: "请输入有效电话",
    mobile_errMsg2: "手机号码已注册",
    emailAddress: "电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    emailAddress_errMsg2: "电子邮箱已注册",
    confirmEmailAddress: "电子邮箱确认",
    confirmEmailAddress_errMsg: "请确认电子邮箱",
    gender: "性别",
    gender_opt1: "请选择您的性别",
    gender_opt2: "男",
    gender_opt3: "女",
    gender_errMsg: "请选择性别",
    language_preference: "语言选项",
    language_preference_opt1: "请选择您的语言选项",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "请选择语言选项",
    password_format: "密码至少为8个字符，其中需包含大小字母、数字和一个特殊字符 (?!@$%&*)",
    password: "*密码",
    password_errMsg: "请输入密码",
    confirm_password: "*密码确认",
    confirm_password_errMsg: "请再次输入密码",
    step3_consent_opt: "我承认我已经阅读并同意此处列出的<link>条款和条件<link>和隐私政策",
    step3_consent_opt2: "我同意基于本隐私政策所述的目的，将我的个人信息传输至御宝餐饮（上海）有限公司位于中国大陆外的境外关联公司。",
    tnc_errMsg: "请接受条款和条件以继续下一步",
    step3_title: "通过勾选以下的方框，我同意御宝餐饮（上海）有限公司及其子公司（“ITRG”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方，从而使我能够享受御宝俱乐部会员资格的权益，包括源于ITRG与中国境内外服务提供商之间关系而产生的权益。",
    step3_q: "我希望收到来自ITRG的营销/推广信息与材料。",
    step3_q_opt1: "通过电子邮件",
    step3_q_opt2: "通过电话",
    step4_q_err: "请选择如何得知御宝忠诚计划",
    step4_q: "您是如何得知御宝忠诚计划？",
    step4_q_opt1: "请选择",
    step4_q_opt2: "御宝轩（上海 益丰·外滩源）",
    step4_q_opt3: "御宝轩（上海 虹桥天地购物中心）",
    step4_q_opt4: "御宝轩（广州 天汇广场igc）",
    step4_q_opt5: "御宝阁潮州酒家（广州 太古汇商场）",
    step4_q_opt16: "口碑介绍",
    step4_q_opt17: "社交媒体",
    step4_q_opt18: "其他",
    promo_code: "优惠码",
    promo_code_input_msg: "请输入有效的促销代码（如有）",
    promo_code_err_msg: "请输入有效的促销代码",
    promo_code_re_msg: "促销代码有效!",
    total: "总共：",
    refresh: "刷新",
    captcha: "验证码",
    captcha_errMsg: "验证码不正确",
    cancel: "取消",
    next: "下一步",
    back: "上一步",
    submitForm: "立即付款",
    payment_head: "付款",
    payment_msg: "请扫二维码进行微信支付。",
    payment_done: "已完成付款",
    payment_err1: "交易失败，请重试。",
    payment_err2: "确认客户时发生错误",
    payment_err3: "交易失败，请10分钟后尝试重新注册。"
  },
  aboutUs: {
    aboutUs: "关于御宝",
    txt1: "御宝饮食集团",
    txt2: "御宝饮食集团创建于2004年。中华美食源远流长，御宝尊崇博大精深的美食文化，矢志继承此宝贵遗产。因此御宝一直坚持以道地的烹饪手法，为食客奉上美味丰盛的佳肴。加之御宝以服务至上，细腻致贴心，宗门是提供舒适的用餐体验。",
    txt3: "既然如此，御宝集团旗下囊括了二十间店铺，各色美食，应有尽有。在体验领导及顾客支持下，御宝更荣获得超过五十不一样奖品包举米其林指南上海两颗星，美团点评黑珍珠餐厅指南二钻，全球最佳中餐厅，由庄布忠诚发，亚洲“50家最佳”餐厅。",
    txt4: "御宝在新加坡，上海，广州，香港，仁川，伦敦和巴黎设有分店。",
  },
  // Reservation page for CN Region:
  cn_reservation: {
    store1_name: "御宝軒",
    store1_address: "上海市黄浦区北京东路 \n99 号益丰外滩源 4 楼 L402-403，\n邮编：200002",
    store1_contact: "联络: (86) 21 5308 1188",
    store1_email: "电子邮件: shanghai@imperialtreasure.com",
    store2_name: "御宝軒",
    store2_address: "上海市闵行区申长路 688 号\n虹桥天地购物中心 3 楼 L326-329，\n邮编：201105",
    store2_contact: "联络: (86) 21 5498 1108",
    store2_email: "电子邮件: hongqiao_finechinese@imperialtreasure.com",
    store3_name: "御宝軒",
    store3_address: "广州市天河区兴民路 222 号\n天汇广场 (igc) 五楼 L514B，\n邮编：510623",
    store3_contact: "联络: (86) 20 3885 6382",
    store3_email: "电子邮件: finechinese_igc@imperialtreasure.com",
    store4_name: "御宝阁潮州酒家",
    store4_address: "广州市天河区天河路 383 号\n太古汇商场三层 L307，\n邮编：510620 ",
    store4_contact: "联络: (86) 20 3868 2388",
    store4_email: "电子邮件: fineteochew_taikoo@imperialtreasure.com",
  },
  faqs: {
    faqs: "FAQs",
    general: "常见问题",
    general_q1: "什么是御寶忠诚计划？",
    general_q1_opt1: "御寶忠诚计划是一个无卡的奖励项目，当会员在指定餐厅用餐时，即可享受专属礼遇和奖励。",
    general_q2: "如何成为御寶忠诚计划会员？",
    general_q2_opt1: "您可以在https://members.imperialtreasure.com.cn在线注册。或者，您也可以在任何参与的御寶餐馆注册。",
    general_q3: "中国大陆有哪些指定餐厅，具体信息是？",
    general_q3_opt3: "御寶軒",
    general_q3_opt3_1: "上海市益丰•外滩源4楼L402-403 | 电话：(86) 21 5308 1188",
    general_q3_opt3_2: "上海市虹桥天地购物中心L3-26 | 电话：(86) 21 5498 1108",
    general_q3_opt3_3: "广州市天汇广场igc五层L514B | 电话：(86) 20 3885 6382",
    general_q3_opt4: "御寶阁潮州酒家",
    general_q3_opt4_1: "广州市天河路383号, 太古汇商场三层L307铺 | 电话：(86) 20 3868 2388",
    general_q4: "成为会员的费用是多少？",
    general_q4_opt1: "会员费为人民币438元。",
    general_q5: "如何支付会员费？",
    general_q5_opt1: "当在指定门店注册，可直接在柜台付款；若是在线注册，则可以使用信用卡和支付宝进行付款。",
    general_q6: "会员的有效期为多久？",
    general_q6_opt1: "会员身份有效期为自注册月份起的1年内。例如：如果您在2023年8月17日注册，会员身份则将于2024年8月16日到期。",
    general_q7: "可在哪里查询我的会员身份到期日？",
    general_q7_opt1: "您可登录会员专属网页https://members.imperialtreasure.com.cn，查询会员身份的到期日。",
    general_q8: "申请成为会员的最低年龄是多少？",
    general_q8_opt1: "顾客需要年满18周岁才能申请成为会员。",
    general_q9: "如何更新我的个人资料？",
    general_q9_opt1: "可通过会员专属网页https://members.imperialtreasure.com.cn登录账户，查看或更新您的个人资料。",
    general_q10: "如何查询我的积分，电子礼券和其他奖励？",
    general_q10_opt1: "可通过会员专属网页https://members.imperialtreasure.com.cn登录账户，查询您的积分余额，会员优惠及其他奖励。",
    general_q11: "如果我忘记了密码怎么办？",
    general_q11_opt1: "请登录https://members.imperialtreasure.com.cn并点击“忘记密码”，按照屏幕上的提示重置密码。",
    general_q12: "如果我对会员账户有疑问怎么办？",
    general_q12_opt1: "如果您对您的会员账户有任何疑问，请发送电子邮件至club.cn@imperialtreasure.com",
    general_q13: "如何终止我的会员身份？",
    general_q13_opt1: "会员可以选择在失效日期之前的任何时间通过电邮至club.cn@imperialtreasure.com  联系我们以终止其会员资格。请注意，在终止时将不会退款和/或提前发放任何未使用的电子礼券。另一种方式是让您的会员资格自动失效，所有积分和/或未使用的电子礼券将被视为放弃使用。",
    membershipTypes: "会员等级",
    membershipTypes_q1: "会员有哪几种级别？",
    membershipTypes_q1_opt1: "会员级别共有三种，分别为：银，金和钻石。",
    membershipTypes_q2: "如何成为银，金或钻石会员？",
    membershipTypes_q2_opt1: "成为银员，可在任何指参与分店或登入会员专属网站支付CNY438注册；",
    membershipTypes_q2_opt2: "成为金员，需要在会员有效期内累积消费达CNY50,888* 或以上；",
    membershipTypes_q2_opt3: "成为钻石员，需要在会员有效期内累积消费达CNY148,888* 或以上。",
    membershipTypes_q2_opt4: "（*消费金额不包含10%服务费。）",
    membershipTypes_q3: "如何保留我的会员身份？",
    membershipTypes_q3_opt1: "要维持银卡等级，只需支付CNY 180的会员更新费。",
    membershipTypes_q3_opt2: "要维持金卡等级，只需在会员有效期内累积消费达CNY50,888*或以上，即可保留会员身份。",
    membershipTypes_q3_opt3: "要维持钻石卡等级，只需在会员有效期内累积消费CNY148,888*或以上，即可保留会员身份。",
    membershipTypes_q3_opt4: "（*消费金额不包含10%服务费。）",
    membership: "会员专属礼遇&奖励",
    membership_q1: "注册成为会员后，我可以享受什么礼遇？",
    membership_q1_opt1: "成功注册后，您将获得以下专属礼遇：",
    membership_q1_opt2: "2 X CNY238电子现金礼券（有效期为3个月）",
    membership_q1_opt3: "1 X CNY480的生日电子现金礼券（仅限生日当月，且单笔消费满CNY 1,500* 时可使用）",
    membership_q1_opt4: "（*消费金额不包含10%服务费。）",
    membership_q2: "我在餐厅注册后该如何操作？",
    membership_q2_opt1: "您需要登录会员专属网页https://members.imperialtreasure.com.cn 填写完整信息。",
    membership_q3: "会员专属礼遇包括什么？",
    type_of_privileges: "礼遇类型",
    sliver: "银",
    gold: "金",
    diamond: "钻石",
    type_of_privileges_1_1: "赚取积分",
    type_of_privileges_1_2: "享有",
    type_of_privileges_1_3: "享有",
    type_of_privileges_1_4: "享有",
    type_of_privileges_2_1: "CNY 250电子现金礼券",
    type_of_privileges_2_2: "享有",
    type_of_privileges_2_3: "享有",
    type_of_privileges_2_4: "享有",
    sign_up_privileges: "会员注册专属礼遇",
    sign_up_privileges_1_1: "2 X CNY 238电子现金礼券",
    sign_up_privileges_1_2: "享有",
    sign_up_privileges_1_3: "-",
    sign_up_privileges_1_4: "-",
    sign_up_privileges_2_1: "1 X CNY 480生日电子现金礼券",
    sign_up_privileges_2_2: "享有",
    sign_up_privileges_2_3: "享有",
    sign_up_privileges_2_4: "享有",
    complimentary_treats: "敬送礼品",
    complimentary_treats_1_1: "北京式烤鸭（一只）",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "享有",
    complimentary_treats_1_4: "享有",
    complimentary_treats_2_1: "烤乳猪（一只）",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "-",
    complimentary_treats_2_4: "享有",
    complimentary_treats_3_1: "脆皮乳鸽（三只）",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "享有",
    complimentary_treats_3_4: "享有",
    complimentary_treats_4_1: "贺年礼品（季节期间享有）",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "2盒",
    complimentary_treats_4_4: "3盒",
    complimentary_treats_5_1: "端午礼品（季节期间享有）",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "2盒",
    complimentary_treats_5_4: "3盒",
    complimentary_treats_6_1: "中秋礼品（季节期间享有）",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "2盒",
    complimentary_treats_6_4: "3盒",
    membership_q4: "两张238元注册电子礼券可以一起使用吗？",
    membership_q4_opt1: "不可以, 238元注册电子礼券不可并用。E.g. 一张注册电子礼券仅限用于一张帐单。",
    membership_q5: "在什么时候可以使用CNY480生日电子礼券?",
    membership_q5_opt1: "您可以在您生日月份第一天至最后一天使用，例如：您的生日为10月10日，生日电子礼券将于10月1日生效，有效期至10月31日，过期则无效。",
    membership_q6: "如何知道我的会员优惠是否到期？",
    membership_q6_opt1: "要查阅各项礼遇及奖励的有效日期，只需登录会员专属网页https://members.imperialtreasure.com.cn，查询“礼券”，的详细信息。",
    membership_q7: "电子礼券能够叠加使用吗？",
    membership_q7_1_1: "电子礼券类型",
    membership_q7_1_2: "CNY 238 注册券/ CNY 238 更新券",
    membership_q7_1_3: "CNY 480 生日礼券",
    membership_q7_1_4: "赠送礼品（如：烤鸭，烤乳猪和脆皮乳鸽等）",
    membership_q7_yes: "可以",
    membership_q7_no: "不可以",
    membership_q7_opt1: "请点击这里以查阅电子礼券的条款与条件。",
    membership_q8: "为什么注册时需要我的个人资料？",
    membership_q8_opt1: "您将收到最新的会员促销、奖励、活动邀请和更多相关信息。",
    membership_q9: "如何证明自己是会员？",
    membership_q9_opt1: "在参与餐厅用餐时，向我们的服务人员提供您电话号码即可证明会员身份。",
    membership_q10: "如何领取赠送礼品？",
    membership_q10_opt1: "北京烤鸭能在御寶軒领取，需要提前一天预订。",
    membership_q10_opt2: "烤乳猪能在御寶軒领取，需要提前一天预订。",
    membership_q10_opt3: "脆皮乳鸽能在御寶軒领取，需要提前一天预订。",
    membership_q10_opt4: "季节性礼品如：年糕，粽子，月饼等只能在相应节日时领取。",
    membership_q10_opt5: "符合享受赠送礼品的会员将在节日来临之际收到电子邮件通知，以便在指定餐厅领取礼品。",
    membership_q11: "作为一个企业客户，公司折扣能否与会员优惠并用？",
    membership_q11_opt1: "不可以，企业客户只能在公司折扣或会员优惠中二者选其一。",
    membership_q12: "会员在御寶的海外分店享有同样优惠吗？",
    membership_q12_opt1: "会员优惠目前仅限中国大陆境内参与的餐厅，对于海外会员的更多信息，请关注我们以获得最新资讯",
    membership_q13: "会员卡是否可以转让？",
    membership_q13_opt1: "不可以。会员卡不宜转让。",
    earning: "赚取与兑换积分",
    earning_q1: "如何赚取积分？",
    earning_q1_opt1: "在指定门店每消费CNY 10*，便可赚取积分。会员本人必须在场以验证身份，过往消费的单据一律被视作无效。使用多个会员账户和拆分账单以进行积分赚取，是绝对不被允许的，每桌客人的每张单据，只能算进一个会员账户中。（*不包含10%服务费、电子现金礼券和季节性产品）",
    earning_q2: "如何使用积分？",
    earning_q2_opt1: "每赚取了250积分后，会员将在次日凌晨自动收到一张CNY 250的电子现金礼券*（只限会员有效期内使用）。 会员可在任何指定门店用餐时使用现金电子礼券，会员本人必须在场以验证身份，积分将会在账单结算后自动存入会员账户。\n(*系统每天定时自动转换积分为电子现金礼券)",
    earning_q3: "我的积分会过期吗？",
    earning_q3_opt1: "会的。一旦会员资格到期，积分也将过期，未使用的积分将有3个月的宽限期，会员必须在宽限期内将剩余积分转换为电子现金礼券，所有未被使用的积分将被清零。",
    earning_q4: "我的积分会在消费后即时显示出来吗？",
    earning_q4_opt1: "会的。除非在网络故障时，才会有所延迟；但正常情况下系统都能即时显示出赚取的积分。",
    earning_q5: "可以使用多个会员账户赚取和兑换积分吗？",
    earning_q5_opt1: "不可以。",
    earning_q6: "可以拆分账单以赚取积分或兑换积分吗？",
    earning_q6_opt1: "不可以。",
    earning_q7: "如果我不在场，我的家人或朋友可以使用我的会员卡赚取或兑换礼遇吗？",
    earning_q7_opt1: "不可以，会员本人必须在场享受会员礼遇。",
    renewal: "会员身份更新",
    renewal_q1: "如何更新我的会员身份？",
    renewal_q1_opt1: "可通过会员专属网页https://members.imperialtreasure.com.cn进行身份更新，会员也可以在任何参与分店完成会员资格续费。",
    renewal_q2: "更新会员身份的费用是多少？",
    renewal_q2_opt1: "费用为人民币180元。",
    renewal_q3: "如何更新我的金/钻石卡会员身份？",
    renewal_q3_opt1: "只需在会员年份内累积消费达CNY 50,888*或以上（金卡会员）或CNY 148,888*或以上（钻石卡会员），即自动更新会员身份。\n（*消费金额不包含10%服务费。）",
    renewal_q4: "更新会员身份后可享受到什么优惠？",
    renewal_q4_opt1: "1 X CNY 238会员更新电子现金礼券；1 X CNY 480的生日电子现金礼券（仅限生日当月，且单笔消费满CNY 1,500* 时可使用）（*消费金额不包含10%服务费。）",
    renewal_q5: "可以在会员身份到期前续费吗？",
    renewal_q5_opt1: "可以，您可以在到期前1个月内更新会员身份，我们也会在您会员身份到期前一个月以邮件通知您。新会员资格周期将从原来的有效期日开始计算。E.g. 您的会员在223年8月17日注册，您可以在2023年7月17日开始更新会员身份。新的会员身份则将于2023年8月17日开始计算。",
  },
  tnc: {
    title: "条款和隐私",
    consent_agree: "注册页面上的同意表述",
    consent_1_1: "□ 我承认我已经阅读并同意此处列出的条款和条件和隐私政策[链接: https://members.imperialtreasure.com.cn/cn/tnc]。",
    consent_1_2: "□ 我同意基于本隐私政策所述的目的，将我的个人信息传输至御宝餐饮（上海）有限公司位于中国大陆外的境外关联公司。",
    consent_opt: "注册页面上的选择加入表述",
    consent_2_1: "通过勾选以下的方框，我同意御宝餐饮（上海）有限公司及其子公司（“ITRG”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方，从而使我能够享受御宝俱乐部会员资格的权益，包括源于ITRG与中国境内外服务提供商之间关系而产生的权益。",
    consent_2_2: "我希望收到来自ITRG的营销/推广信息与材料。",
    consent_2_3: "□ 通过电子邮件    □ 通过邮寄     □ 通过移动电话",
    membership: "御宝忠诚计划条款和条件",
    membership_1: "本御宝忠诚计划条款和条件（“条款和条件”，包括下述《隐私政策》）适用于御宝餐饮（上海）有限公司以及其子公司（“御宝”）于中国境内（为本条款和条件的目的，不包括香港特别行政区、澳门特别行政区以及台湾）的会员服务，对御宝与御宝忠诚计划成员（“会员”）均具有法律约束力。",
    membership_1_1: "1.1 简介",
    membership_1_1_1: "御宝希望向我司尊贵客户提供我司产品和服务的有关资讯与信息，包括但不限于促销、特别优惠和折扣等。我司将根据本条款和条件，为您提供成为会员、并享受由御宝提供的优惠的机会。",
    membership_1_2: "1.2 个人信息的收集和使用",
    membership_1_2_1: "若您成为会员，您需要向御宝提供个人信息（包括您的姓名与详细联系信息，例如电子邮件地址、移动电话号码和出生日期）。您需要向我们提供您的手机号码以便我们识别您。您的移动电话号码将成为御宝忠诚计划会员的用户名或唯一身份。我们任何有关您的个人信息的处理活动都将根据我们的隐私政策进行。",
    membership_1_3: "1.3 直接营销",
    membership_1_3_1: "通过在会员门户网站https://members.imperialtreasure.com.cn（“会员门户网站”）或参与活动的分店（请见第1.7节的定义）提交书面会员申请，您同意御宝及其附属公司、关联实体和代表我们的第三方机构（“商业伙伴”）可能在遵守我们的隐私政策和所有适用于中华人民共和国的法律法规情况下，收集、使用和披露您在会员门户网站或参与分店提供的个人信息，以便向您提供您同意接收的营销材料和特别优惠。请注意，为了提供营销材料和特别优惠，您同意我们可以向商业伙伴披露您的个人信息。",
    membership_1_4: "1.4 会员级别",
    membership_1_4_1: "1.4.1 会员注册、i-dollars 和电子现金券",
    membership_1_4_1_1: "当您注册成为会员时，您需要提供您的手机号码以及其他可能需要的信息以便注册会员。具体方式可能取决于情况而有所不同，可能是在线上进行，也可能由餐厅的工作人员负责。",
    membership_1_4_1_2: "会员资格严格不可转让，只能由会员本人享受。",
    membership_1_4_1_3: "作为会员，当您在任何全球范围内的御宝餐厅（具体范围参见下文）消费时（不包括支付服务费、税费、使用电子现金券以及购买和使用御宝礼品券），您将按照会员计划网站[插入链接: https://members.imperialtreasure.com.cn]所规定的比例获取一个i-dollar。请参考[插入链接: https://members.imperialtreasure.com.cn]中的御宝餐厅列表(“御宝餐厅”)，确认您可以从哪些御宝餐厅获取i-dollar。为了获取i-dollar，会员必须亲自到餐厅就餐，并向服务人员提供注册手机号码。i-dollar应在消费时获取。我们不会受理与过往消费相关的i-dollar获取要求。系统可能需要至多一个工作日以显示交易、电子现金券和获取的i-dollar。在会员资格到期时，所有获取的i-dollar将失效。所有获取的i-dollar将有三个月的有效期，会员必须在此期间将其转换为电子现金券，否则将被视为放弃赚取的i-dollar。通过第三方供应商完成的交易不符合获取i-dollar 的条件。",
    membership_1_4_1_4: "获取的i-dollar将按照会员门户网站中标定的各注册国家的规定比例转换为指定价值的御宝电子现金券（“电子现金券”）。每张电子现金券有效期为自发行之日起十二个月，仅限在会员有效期内使用。电子现金券可用于抵消在任何参与分店用餐时的账单（受限于御宝公司的单方决定和[插入链接: https://members.imperialtreasure.com.cn/cn/tnc]中规定的任何其他条款和条件）。i-dollar和电子现金券均没有任何现金价值，不能转换或兑换为现金。",
    membership_1_4_2: "1.4.2 会员级别",
    membership_1_4_2_1: "除非御宝另行确定，会员在首次注册时将直接成为银卡会员，有效期为会员计划网站[插入链接: members.imperialtreasure.com.cn]上针对其注册国家确定的期限。在此期满时，若会员未升级为金卡会员或钻石卡会员，则御宝有权自行要求会员按照会员计划网站[插入链接: members.imperialtreasure.com.cn]上的会员注册国家规定的费用要求会员续费。",
    membership_1_4_2_2: "会员必须在加入会员计划后的十二个月内，在御宝消费达到网站[插入链接: members.imperialtreasure.com.cn]上指定的金卡消费额，才能被授予金卡会员身份。金卡会员身份有效期为自会员达到相应消费额之日起的十二个月。在此期限届满后，会员需要再次满足金卡会员消费要求才能保持会员身份。若金卡会员资格到期时未满足最低消费要求，则被降级为银卡会员。",
    membership_1_4_2_3: "会员必须在加入会员计划后的十二个月内，在御宝消费达到网站[插入链接: members.imperialtreasure.com.cn]上指定的钻石卡消费额，才能被授予钻石卡会员身份。钻石卡会员身份有效期为自会员达到相应消费额之日起的十二个月。在此期限届满后，会员需要再次满足钻石卡会员消费要求才能保持会员身份。若钻石卡会员资格到期时未满足最低消费要求，则被降级为金卡会员或银卡会员。",
    membership_1_4_2_4: "御宝有权自行决定修改、限制、调整银卡会员、金卡会员和/或钻石卡会员计划规则、条例、福利、成员资格或任何其他要求，或随时自行决定终止银卡会员、金卡会员和/或钻石卡会员计划，并通过会员计划网站和/或电子邮件合理通知。请定期查看会员计划网站以获取更新信息。",
    membership_1_4_3: "1.4.3 会员等级奖励",
    membership_1_4_3_1: "会员可以享受对应级别的某些奖励和特权，其中可能包括其他电子凭证、免费礼品和/或其他用餐福利(“奖励”)。当前等级的奖励如会员计划网站[插入链接: members.imperialtreasure.com.cn]所示。此类奖励的有效期和条款及条件如[插入链接: https://members.imperialtreasure.com.cn/cn/tnc]所示。在向会员通过会员项目网站或电子邮件发送合理通知的情况下，御宝可以单方面修改上述奖励的有效期、条款及条件。奖励的发放取决于实际情况，御宝保留将奖励替换为等值或包含近似价值的另一个奖励的权利。奖励没有任何现金价值，不能转换或兑换成现金。",
    membership_1_4_4: "1.4.4 奖励与电子现金券兑换",
    membership_1_4_4_1: "会员必须亲临餐厅现场（需要身份证明）才能兑换任何会员特权。会员需要在收据上签字确认所兑换的奖励。",
    membership_1_4_4_2: "严禁合并多个会员账户、合并账单或分账以获取i-dollar或兑换奖励和会员特权。会员兑换奖励时需维持有效的会员资格。",
    membership_1_4_5: "1.4.5 其他会员促销活动",
    membership_1_4_5_1: "每一等级会员还可享受由御宝推出的其他促销活动，该等促销活动将由御宝不时向会员介绍及发送。会员可参阅会员门户网站，查询最新会员特权信息。请注意，御宝保留随时修改适用的会员特权的权利。",
    membership_1_5: "1.5 修改个人信息与会员身份终止",
    membership_1_5_1: "您可以通过我司的会员门户网站，随时修改您的个人信息（但不包括您的姓名、移动电话号码以及生日）。若您需要修改您的姓名、移动电话号码或生日，或选择终止会员身份，则您需要通过向cn.club@imperialtreasure.com发送电子邮件，以书面形式通知我司。相关修改需几个工作日才能生效。请注意，在终止会员身份时，我司不会退款和/或提前发放未使用的电子现金券。另外，您的会员身份将在不更新会员身份时自动失效，所有i-dollar和/或未使用的电子现金券均将视为过期。",
    membership_1_6: "1.6 终止与取消",
    membership_1_6_1: "若您不遵守本会员条款和条件，或者您以违反相关法律、法规或规定的方式使用您的会员特权，我司有权单方决定终止您的会员身份。",
    membership_1_7: "1.7 参与分店",
    membership_1_7_1: "所有会员在任何一家御宝消费时都可以获取i-dollars，而所有会员礼券（包括现金电子券和产品券）仅限在以下列出的中国御宝（“参与活动的分店”）用于抵消账单。",
    membership_1_7_1_1: "御宝轩",
    membership_1_7_1_1_1: "上海市黄浦区北京东路99号, 益丰•外滩源4楼L402-403  | (86) 21 5308 1188",
    membership_1_7_1_1_2: "上海市闵行区申长路688号, 虹桥天地购物中心L3-26  | (86) 21 5498 1822",
    membership_1_7_1_1_3: "广州市天河区兴民路222号, 天汇广场IGC五层L514B商铺  | (86) 20 3885 6382",
    membership_1_7_1_2: "御宝阁潮州酒家",
    membership_1_7_1_2_1: "广州市天河区天河路383号，太古汇商场L307 |（86）20 3868 2388",
    membership_2: "2. 法律和法院",
    membership_2_1: "这些条款，包括所有引用的政策和附加条款（“会员协议”），受中国大陆法律的管辖并按照其进行解释。",
    membership_2_2: "任何与会员协议或御宝会员俱乐部有关的争议、纠纷或索赔都应首先通过有关各方友好协商解决。若争议无法通过友好协商解决，则任何争议或索赔均可根据申请仲裁时上海国际仲裁中心的有效仲裁规则，提交给上海国际仲裁中心进行仲裁。仲裁裁决为最终裁决，并对各方具有约束力。",
    membership_3: "3. 同意",
    membership_3_1: "我期望加入御宝会员俱乐部，并同意御宝按照《隐私政策》处理我的个人信息。",
    privacy: "御宝隐私政策",
    privacy_1: "1. 介绍",
    privacy_1_1: "御宝餐饮（上海）有限公司及其子公司（包括Imperial Treasure F&B (Shanghai) Co., Ltd.、广州御宝餐饮有限责任公司、御宝星洲餐饮管理（上海）有限公司和御宝餐饮经营管理（上海）有限公司，以下简称“御宝”、“我们”）致力于保护访问该网站和微信公众号（合称“网站”）的访客的隐私。本隐私政策说明了御宝如何处理您通过网站提供给我们的个人信息。通过访问网站，您同意并接受本隐私政策中描述的个人信息的收集、使用和披露。若您不同意本隐私政策，请不要使用该网站。本隐私政策不适用于链接到的其他网站（“第三方网站”）。",
    privacy_1_2: "本隐私政策还描述了您的个人信息保护权利，包括拒绝御宝进行某些个人信息处理的权利。有关您的权利及如何行使这些权利的更多信息，请参见下文“8.我拥有何种权利”一节。",
    privacy_1_3: "保护客户信息的隐私对我们来说非常重要。我们不向第三方披露、出租或出售我们客户的个人信息（除本隐私政策中“5. 披露个人信息给第三方”的说明外）。",
    privacy_2: "2. 收集与使用个人信息",
    privacy_2_1: "我们为如下目的收集您的个人信息：",
    privacy_2_2_1: "履行合同或与合同相关的步骤：若您从我们这里购买商品或服务，这将与您相关。这包括：",
    privacy_2_2_1_1: "履行您的订单并将商品送到您手中，为此，我们将收集您的地址（包括不同的送货地址）、电话号码、账单地址和订单信息。",
    privacy_2_2_1_2: "为方便您在我们的餐厅或网站上购买并跟踪订单，我们将收集您的姓名、电话号码和订单信息。",
    privacy_2_2_1_3: "为了提供您所需的服务支持，我们将收集您的姓名、电话号码和问题。",
    privacy_2_2_2: "根据御宝开展业务和追求合法利益的需要，我们将以下信息用于以下特定目的：",
    privacy_2_2_2_1: "为了方便您注册成为网站用户，我们将收集您的姓名、国家、出生日期、电子邮件地址和电话号码。",
    privacy_2_2_2_2: "为了提供您所请求的产品和服务，我们将收集您的姓名、地址（包括不同的配送地址）、电话号码、账单地址和订单信息。",
    privacy_2_2_2_3: "若在网站上进行任何购买时使用银行卡付款，我们会使用第三方机构检测您提交的账户号码、卡号等信息的有效性，以防止欺诈行为，为此我们将收集您的信用卡号码。",
    privacy_2_2_2_4: "为了回应评论、查询和反馈，我们将收集您的姓名、电话号码、请求和问题。",
    privacy_2_2_3: "当您访问我们的网站时，为了提供更好的浏览体验、提高我们的服务质量以及履行我们的网络安全义务，我们将收集您的操作系统、浏览器或设备（如电脑、iPad、智能手机）特征，您的 IP 地址以及您与网站内容进行的交互。在交互过程中收集的信息包括页面浏览量、使用的搜索关键词、访问网站的频率以及对广告的点击次数。",
    privacy_2_2_4: "当您向我们给予同意时：",
    privacy_2_2_4_1: "我们将向您提供关于我们的产品的营销和促销材料以及其他相关信息（包括促销和特别优惠），以及我们的关联公司和合作伙伴提供的其他产品和服务",
    privacy_2_2_4_2: "在其他需要征得您同意的场合，我们将根据当时说明的目的使用您的个人信息。",
    privacy_2_2_5: "根据法律要求的目的：回应政府或执法机构的调查请求。",
    privacy_2_3_1: "您的姓名、姓氏、联系电话、出生日期、所在国家代码和电子邮件地址是必需的，以便我们联系您并履行您的任何订单：若缺少任何必要的个人信息，我们将无法履行您的订单。您的其他信息提供是可选的。",
    privacy_2_3_2: "此外，通过网站，我们可以收集有关网站使用的某些聚合和匿名信息，例如网站某些区域的访问频率，包括通过使用cookie、网络信标、Google Analytics和其他技术。",
    privacy_2_4: "我们可能使用 cookie、网络信标、Google Analytics 和其他技术来实现以下的目的：",
    privacy_2_4_1: "确认 Web 服务器出现问题的原因并解决这些问题；",
    privacy_2_4_2: "改进网站内容；",
    privacy_2_4_3: "根据您的个体兴趣或目的自定义网站内容和我们的电子邮件，并为您选择最匹配的页面，包括跟踪您过去的购买记录以提供新的服务或特别促销；",
    privacy_2_4_4: "改进我们的服务和产品的质量并提高您的用户体验；和",
    privacy_2_4_5: "获取匿名的网站访问者和聚合的网站访问统计个人信息。",
    privacy_2_5: "您可以通过配置浏览器来拒绝我们使用 cookie。然而，您可能无法再接收到依赖于 cookie 使用的网站服务或个性化功能。",
    privacy_3: "3. 基于合法利益",
    privacy_3_1: "我们已经上述对基于我们合法利益进行的所有个人信息处理活动进行了权衡测试。您可以通过使用本隐私政策后面列出的详细信息与我们联系，以获取有关任何权衡测试的信息。",
    privacy_4: "4. 撤回同意或拒绝直接营销",
    privacy_4_1: "在我们依赖您的同意进行个人信息处理的情况下，您都可以随时撤回同意。但是，我们可能有其他处理您的个人信息的合法性基础，例如：",
    privacy_4_1_1: "为订立、履行个人作为一方当事人的合同所必需；",
    privacy_4_1_2: "为履行法定职责或者法定义务所必需；",
    privacy_4_1_3: "为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；",
    privacy_4_1_4: "为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；",
    privacy_4_1_5: "依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；",
    privacy_4_1_6: "法律、行政法规规定的其他情形。",
    privacy_4_2: "我们在发送直接营销信息之前会征得您的同意。您有权随时选择退出直接营销或拒绝我们为了进行直接营销的所开展的个人档案分析。若该政策以电子形式发送，您可以按照其中的说明进行操作；若该政策以其他形式发送，您可以通过以下方式与我们联系（参见“11.联系我们”部分）。",
    privacy_5: "5. 披露个人信息给第三方",
    privacy_5_1: "为向您提供服务，我们可能会按照本隐私政策的描述披露和/或转移您的个人信息至中国大陆以外的地区，包括：",
    privacy_5_1_1: "基于履行与您的合同、提供客户服务或会员服务和协助您注册为网站用户的目的，我们可能会与我们的关联公司分享您的个人信息。",
    privacy_5_1_2: "我们可能会允许某些可信赖的第三方在服务期间处理或访问您的个人信息，包括：（1）维护和操作网站及网站上某些功能；（2）为我们的会员提供服务。例如，我们可能使用第三方来托管网站；操作网站上的各种功能；发送电子邮件；分析个人信息；提供搜索结果和链接，并帮助您完成订单，可能会与我们的母公司、子公司、部门和附属公司共享个人信息或其他信息。我们还可能使用第三方的服务来管理会员计划。",
    privacy_5_1_3: "我们可能会雇用第三方服务来收集上述信息，包括个人信息，并代表我们使用其跟踪服务。但是，这些信息是严格保密的，不会与他人共享。",
    privacy_5_1_4: "在与我们的业务全部或部分进行拟议或实际的合并或销售（包括任何破产或破产程序中的披露）或作为公司重组、股票出售或其他控制变更的一部分，我们可能会将个人信息披露为资产。",
    privacy_5_1_5: "我们保留披露信息的权利，包括个人信息，以遵守传票、法院命令、行政或政府命令或任何其他法律要求的情况；或当我们在自己的唯一判断下认为有必要为了保护我们的权利或他人的权利，防止对人或财产造成伤害，打击欺诈和信用风险减少时。我们还可能根据法律规定允许或要求披露和转移个人信息。",
    privacy_5_1_6: "我们可能向第三方披露匿名化后的个人信息和汇总的个人信息。",
    privacy_5_2: "您的个人信息可能会不时被出境至中国大陆以外的国家或地区。上述个人信息出境将按照中国大陆的个人信息保护法实行。个人信息的境外接收方信息如下：",
    privacy_5_3_1_1: "境外接收方名称",
    privacy_5_3_1_2: "Xgate Pte Ltd",
    privacy_5_3_2_1: "联系方式",
    privacy_5_3_2_2: "data.privacy@xgate.com",
    privacy_5_3_3_1: "处理目的与方式",
    privacy_5_3_3_2: "处理目的：向会员提供与会员资格相关的福利和服务。\n\n处理方式：会员的个人信息将被转移到境外接收方，随后，若御宝轩接到会员关于会员福利或服务的请求，境外接收方将使用此类个人信息来验证会员的身份。",
    privacy_5_3_4_1: "个人信息类别",
    privacy_5_3_4_2: "姓名，会员级别，会员有效期",
    privacy_6: "6. 我们如何保护您的个人信息",
    privacy_6_1: "我们致力于保护从您处获得的信息。我们采用合理的物理、技术和管理措施来帮助保护存储在我们系统中的个人信息，包括个人信息的机密性、安全性和完整性。我们相信我们实施的措施可以将安全问题的可能性降至适合所涉及个人信息类型的水平。虽然我们采取合理措施保护从您处获得的信息，但是没有计算机系统是完全安全的，我们不能对未经授权访问或任何个人信息丢失而导致的任何损失承担责任。",
    privacy_7: "7. 第三方网站链接",
    privacy_7_1: "本网站可能包含访问第三方网站和微网站的链接，包括第三方支付网关，其隐私实践可能与我们不同。若您向这些网站提交个人信息，包括付款信息，则这些个人信息不受本隐私政策的约束，而将受到第三方的隐私实践的约束。",
    privacy_7_2: "我们建议您查阅您访问的任何网站的隐私政策。单击或激活此类链接并离开网站，我们将无法再对您在离开网站后向任何其他实体提供的任何个人信息行使控制权，任何对此类其他网站的访问都完全由您自行承担风险。",
    privacy_8: "8. 我拥有何种权利",
    privacy_8_1: "您有权要求我们提供您个人信息的副本，或者纠正、删除或限制（停止所有活动）处理您的个人信息，并以结构化、机器可读格式获取您向我们提供的个人信息，以及要求我们将此个人信息分享（移植）给另一个人信息控制者。您有权通过联系cn.club@imperialtreasure.com注销您的账户。",
    privacy_8_2: "此外，在某些情况下（特别是我们不是基于满足合同或其他法律要求而处理个人信息的情况下），您可以拒绝我们对您的个人信息的处理。",
    privacy_8_3: "这些权利的行使在特定情况下可能会受到限制，例如，当履行您的请求将揭示有关其他人的个人信息并导致对第三方（包括我们的）权利的侵犯，或者您要求我们删除我们根据法律要求保留或基于重大迫切合法利益保留的信息。相关的豁免条款存在于适用的法律法规中。我们将在回复您提出的请求时告知我们所依赖的相关豁免依据。",
    privacy_8_4: "为行使任何这些权利或获取其他信息（例如合法利益平衡测试的副本），您可以联系我们的个人信息保护经理（参见下文“11.联系我们”）。要更正您的个人信息，您可以登录您的用户账户并更新您的个人信息。我们仅会根据访问请求披露个人信息至注册并提交其个人信息给网站的个人，并且只会在确认请求访问的人确实是注册方之后才披露。",
    privacy_8_5: "您还可以联系我们的个人信息保护经理撤回您对我们使用个人信息的同意。",
    privacy_8_6: "我们需要十个工作日以处理您的个人信息请求。",
    privacy_9: "9. 个人信息的存储",
    privacy_9_1: "在您的账户为活跃状态，以及在此状态结束后的六年内，我们将处理您的注册信息。",
    privacy_9_2: "若我们根据您的同意或为营销目的处理个人信息，我们将在您要求停止处理之前以及在您要求停止处理之后的短时间内（以便我们实施您的请求）一直处理此类个人信息。我们还会记录您要求我们不发送直接营销或不处理您的个人信息的情况，以便我们持续遵守您的要求。",
    privacy_9_3: "若我们根据履行合同的需要处理个人信息，我们将在您上一次与我们互动的六年之内保存此类个人信息。",
    privacy_9_4: "若您撤回我们收集、使用或披露您的个人信息的同意，我们可能无法向您提供需要此类个人信息的服务。请注意，根据法律规定，您的个人信息仍可能保存在我们的数据库中。",
    privacy_10: "10. 隐私政策的变更",
    privacy_10_1: "若我们决定更改网站的隐私政策，我们将在此处发布修订后的隐私政策，并在适当的情况下通过电子邮件通知您。请经常查看我们的隐私政策以查看任何更新或更改。",
    privacy_11: "11. 联系我们",
    privacy_11_1: "若您对本隐私政策有任何疑问，请通过 DPO@imperialtreasure.com 联系我们的个人信息安全保护经理。",
  },
  outlets: {
    outlets: "餐厅",
    restaurant_name: "餐厅名称",
    address: "住址",
    contact: "联系",
    email: "电子邮件",
    outlets_1_1: "御宝轩（益丰·外滩源）",
    outlets_1_2: "上海市黄浦区北京东路\n99 号益丰外滩源 4 楼 L402-403，邮编：200002",
    outlets_1_3: "(86) 21 5308 1188",
    outlets_1_4: "shanghai@imperialtreasure.com",
    outlets_2_1: "御宝軒（虹桥）",
    outlets_2_2: "上海市闵行区申长路 \n688 号虹桥天地购物中心 3 楼 L326-329，邮编：201105 ",
    outlets_2_3: "(86) 21 5498 1108",
    outlets_2_4: "hongqiao_finechinese@imperialtreasure.com",
    outlets_3_1: "御宝轩（天汇igc）",
    outlets_3_2: "广州市天河区兴民路 \n222 号天汇广场 (igc) 五楼 L514B，邮编：510623",
    outlets_3_3: "(86) 20 3885 6382",
    outlets_3_4: "finechinese_igc@imperialtreasure.com",
    outlets_4_1: "御宝阁潮州酒家（太古汇）",
    outlets_4_2: "广州市天河区天河路 \n383 号太古汇商场三层 L307，邮编：510620 ",
    outlets_4_3: "(86) 20 3868 2388",
    outlets_4_4: "fineteochew_taikoo@imperialtreasure.com",
  },
  privilege: {
    title: "会员优惠",
    privilege: "会员优惠",
    privelege_txt: "每消费 CNY10# 可赚取 i$1\n累计 i$250 以换取 CNY250 现金电子礼券",
    silver: "银",
    signup: "CNY438 注册",
    renewal: "CNY180更新 (从第二年更新会员身份起)",
    silver_1: "2 x CNY238 注册电子礼券 (有效期为 3 个月)",
    silver_2: "1 x CNY480 生日电子礼券 (仅限生日月份使用, 须单张消费CNY1500以上 [不包括服务费和消费税])",
    silver_3: "1 x CNY238 更新电子礼券 (有效期为 3 个月)",
    silver_4: "1 x CNY480 生日电子礼券 (仅限生日月份使用, 须单张消费CNY1500以上 [不包括服务费和消费税])",
    gold: "金",
    gold_1: "凡在会员有效期内消费满 CNY50,888#, 便可成为金等级会员。",
    gold_2: "享有银等级会员优惠, 以及以下优惠 : ",
    gold_3: "免费更新会员身份",
    gold_4: "敬送礼品：\n北京式烤鸭一只 \n脆皮乳鸽一份（3只） \n新年糕点两份 (季节性) \n端午粽子两份 (季节性) \n中秋月饼两盒 (季节性)",
    diamond: "钻石",
    diamond_1: "凡在会员有效期内消费满 CNY148,888#, 便可成为钻石等级会员。",
    diamond_2: "享有金等级会员优惠，以及以下优惠 : ",
    diamond_3: "敬送礼品：\n烤乳猪一只 \n新年糕点一份 (季节性) \n端午粽子一份 (季节性) \n中秋月饼一盒 (季节性)",
    ser_charge_note: "# 不包括服务费，消费税，电子礼券兑换，购买或使用御宝礼券和季节食品。",
    sign_up_now: "立即注册",
  },
  resetPassword: {
    title: "忘记密码",
    reset_password: "重置密码",
    email: "*请输入电子邮箱",
    email_input: "请输入电子邮箱",
    email_errMsg: "请输入有效的电子邮箱",
    mobile: "*请输入手机号码",
    mobile_errMsg: "请输入有效电话",
    new_password: "*新密码",
    new_password_errMsg1: "请输入密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）",
    confirm_password: "*确认新密码",
    confirm_password_errMsg: "请确认密码",
    reset_password2: "前去重置密码",
    has_been_reset: "密码已重置！",
    login: "登录",
  },
  forgetPassword: {
    title: "忘记密码",
    reset_password: "重置密码",
    forgot_password: "忘记密码？",
    reset_pw_method: "请选择密码重置方式",
    by_mobile: "通过手机",
    by_email: "通过电子邮件",
    email: "*请输入电子邮箱",
    email_input: "请输入电子邮箱",
    email_errMsg: "请输入有效的电子邮箱",
    mobile: "*请输入手机号码",
    mobile_errMsg: "请输入有效电话",
    reset_password2: "前去重置密码",
    reset_password_sent_email: "重置密码链接已发送到您的电子邮箱。请注意查收！",
    reset_password_sent_mobile: "重置密码链接已发送到您的手机短讯。请注意查收！",
    next: "下一步",
    back: "上一步",
  },
  updateEmail: {
    title: "更新电子邮箱",
    member_profile: "会员档案",
    updateEmail: "更新电子邮箱",
    new_email: "*新电子邮箱",
    sent: "发送",
    send_token: "发送验证码",
    email_errMsg1: "请输入有效的电子邮件",
    email_errMsg2: "电子邮件已注册",
    token: "*验证码",
    token_error: "验证码错误",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手机和电子邮箱都已使用",
    Msg2: "电子邮箱已使用",
  },
  updateMobile: {
    title: "修改手机号码",
    member_profile: "会员档案",
    updateMobile: "修改手机号码",
    new_mobile: "*新手机号码",
    sent: "发送",
    send_token: "发送验证码",
    mobile_errMsg1: "请输入有效电话",
    mobile_errMsg2: "手机已注册",
    token: "*验证码",
    token_error: "令牌错误",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手机和电子邮件都已使用",
    Msg2: "电子邮件已使用",
  },
  coupon: {
    title: "礼劵",
    member_profile: "会员档案",
    coupon: "礼劵",
    birthdayNote: "您的生日电子礼券只会在您的生日月份内显示。",
    active: "有效的",
    redeemed: "已兑换",
    expired: "过期",
    type_of_vouchers_opt1: "请选择礼劵类型",
    type_of_vouchers_opt2: "类型A",
    type_of_vouchers_opt3: "类型B",
    type_of_vouchers_opt4: "类型C",
    type_of_vouchers_opt5: "类型D",
    expiry_date: "到期日期：",
    redeemed_store: "赎回商店：",
    vaild_date: "无效日期",
    description: "说明",
    vouchers_code: "礼劵代码",
    done: "完成",
    no_coupon_msg: "没有可用礼劵",
  },
  editProfile: {
    title: "编辑个人资料",
    member_profile: "会员档案",
    editProfile: "编辑个人资料",
    general: "账户资料",
    security: "安全信息",
    subscriptions: "信息订阅",
    salutation: "*称谓",
    salutation_opt1: "请选择您的称谓",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "请选择称谓",
    gender: "*性别",
    gender_opt1: "请选择您的性别",
    gender_opt2: "男性",
    gender_opt3: "女性",
    gender_errMsg: "请选择性别",
    first_name: "*名字",
    first_name_errMsg: "请输入名字",
    last_name: "*姓氏",
    last_name_errMsg: "请输入姓氏",
    birth: "*出生日期",
    mobile: "*手机号码",
    mobile_change: "修改",
    email: "*电子邮件",
    email_change: "修改",
    language: "*语言",
    language_opt1: "请选择您的语言选项",
    language_opt2: "English",
    language_opt3: "简体中文",
    update: "更新",
    previous_password: "*旧密码",
    previous_password_errMsg: "旧密码密码不正确",
    new_password: "*新密码",
    new_password_errMsg1: "请输入密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）",
    confirm_password: "*确认密码",
    confirm_password_errMsg: "请确认密码",
    subscriptions_txt1: "通过勾选以下的方框，我同意御宝餐饮（上海）有限公司及其子公司（“ITRG”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方，从而使我能够享受御宝俱乐部会员资格的权益，包括源于ITRG与中国境内外服务提供商之间关系而产生的权益。",
    subscriptions_txt2: "我希望收到来自ITRG的营销/推广信息与材料。",
    by_email: "通过电子邮件",
    by_mobile: "通过电话",
    update_success: "更新成功！",
    done: "完成",
    password_update_success: "密码修改成功！",
  },
  profile: {
    title: "会员",
    logout: "登出",
    click_to_renew: "单击以更新​",
    activation_now: "立即激活",
    menber_id: "会员号码:",
    member: "会员",
    active: "有效的",
    expired: "过期",
    salutation: "称谓：",
    mobile: "手机号码：",
    birth: "出生日期：",
    password: "密码：",
    ellipsis: "········",
    change: "更改",
    gender: "性别：",
    email: "电子邮件：",
    cycle_start_date: "等级开始日期：",
    first_joined_date: "首次加入日期：",
    optin_email: "选择电子邮件：",
    optin_sms: "选择短信：",
    cycle_expiry_date: "周期到期日期：",
    language_preference: "语言选项：",
    spend: "CNY 消费",
    expires_on: "  到期时间",
    spend_required: "还需消费",
    to_upgrade_to: "升级为",
    to_maintain_to: "維持为",
    gold_member: "金卡会员",
    diamond_member: "钻石卡会员",
    total_nett_spend: "总净消费金额",
    current_membership: "在当前会员年份*",
    i_dollar: "i-Dollar",
    last_update: "  最新一次更新：",
    i_dollar_earned: "賺取的 i-Dollar",
    i_dollar_converted: "已兌換的 i-Dollar",
    i_dollar_balance: "剩餘的 i-Dollar",
    i_dollar_automatically: "$250 i-Dollars 将自动转换为CNY250现金电子礼劵",
    i_dollar_expired_date: "剩余 i-Dollar 的有效日期为",
    i_dollar_expired_mth: "3 个月为当前周期到期日的剩余月数",
    type_btn_profile: "会员档案",
    type_btn_vouchers: "礼劵",
    type_btn_transactions: "交易记录",
    type_btn_referral: "推荐朋友",
    vouchers: "礼劵",
    currently_active: "当前有效",
    cash_vouchers: "现金礼劵",
    food_vouchers: "食品/促销券",
    click_to_view: "单击查看",
    transaction: "交易记录",
    this_month: "当月记录",
    last_3_months: "最近3个月",
    last_12_months: "过去12个月",
    transaction_details: "交易明细",
    transaction_date: "交易日期",
    transaction_time: "交易时间",
    outlet: "餐厅",
    receipt: "单据编码",
    payment_method: "付款方式",
    receipt_details: "单据明细",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS 赚取:",
    total: "总额：",
    done: "完成",
    thankyou_msg: "谢谢你选择我们的餐厅!",
    activate_membership: "激活会员资格",
    activate_membership_1: "只需 CNY108 即可激活您的会员身份享受以下礼遇*：",
    activate_membership_2: "每消费 CNY10，即可赚取 i$1。累积 i$250 可兑换 CNY250现金电子礼券",
    activate_membership_3: "1 张 CNY238更新电子礼券 (有效期为 3 个月)",
    activate_membership_4: "1 张 CNY480生日电子礼券（仅限生日当月，且单笔有效消费满CNY1500时可使用）",
    activate_membership_7: "率先收到最新信息和私人活动专属邀请！",
    renew_membership: "更新会员资格",
    renew_membership_1: "只需 CNY108 即可更新您的会员身份享受以下礼遇*：",
    renew_membership_2: "每消费 CNY10，即可赚取 i$1。累积 i$250 可兑换 CNY250现金电子礼券",
    renew_membership_3: "1 张 CNY238更新电子礼券 (有效期为 3 个月)",
    renew_membership_4: "1 张 CNY480生日电子礼券（仅限生日当月，且单笔有效消费满CNY1500时可使用）",
    renew_membership_5: "率先收到最新信息和私人活动专属邀请！",
    confirm_to_renew: "确认续订",
    confirm_to_activate: "确认激活",
    change_password: "修改密码",
    previous_password: "*旧密码", 
    previous_password_errMsg: "旧密码不正确",
    new_password: "*新密码",
    new_password_errMsg1: "请输入并确认密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）​",
    confirm_password: "*确认密码",
    close: "X 关闭",
    member_activated: "会员已激活",
    password_updated: "密码修改成功！",
    friend_referral: "推荐朋友",
    view_more: "查看更多",
    add_friend: "添加",
    silver_tier: "銀卡",
    gold_tier: "金卡",
    diamond_tier: "钻石卡",
    spend_date_format: "年过期",
  },
  friendReferral: {
    title: "推荐朋友",
    email: "电子邮箱",
    referee_email: "推荐朋友电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    mobile: "电话号码",
    referee_mobile: "推荐朋友电话号码",
    mobile_errMsg: "请输入有效的电话号码",
    mobile_errMsg2: "手机号码已注册",
    name: "姓名",
    referee_name: "推荐朋友姓名",
    name_errMsg: "请输入推荐朋友姓名",
    referral_sucMsg: "你已成功推荐朋友!",
    referral_errMsg: "推荐朋友已为现有御宝注册会员。",
    submit: "提交",
  },
  transactionDetails: {
    transaction_date: "交易日期",
    outlet: "餐厅",
    receipt_no: "单据编码",
    amount_spend: "消费金额",
    amount_qualified_for_i_dollar: "符合 i-Dollar 金额",
    i_dollars: "i-Dollars",
    selected_start_date: "选定开始日期",
    selected_end_date: "选定结束日期",
    no_tranMsg: "所选期间无交易记录。",
    action: "动作",
    apply: "应用",
    view: "查看",
  },
  contactUs: {
    title: "联系我们",
    title_message: "請填寫並在下面提交您的反饋，我們將在3-5個工作天内回覆您。",
    firstname: "名字",
    firstname_errMsg: "请输入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "请输入您的姓氏",
    emailAddress: "电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    mobile: "手机号码",
    mobile_errMsg: "请输入有效电话",
    type: "类型",
    type_opt1: "请输入您的反馈信息类型 :",
    type_opt2: "联络我们",
    type_opt3: "查询",
    type_opt4: "反馈",
    type_opt5: "电子报",
    type_errMsg: "请输入您的反馈信息类型",
    message: "信息",
    message_errMsg: "请输入您的反饋信息",
    captcha: "验证码",
    captcha_errMsg: "验证码不正确",
    feedback_sucMsg: "感谢您与我们联系并向我们提供宝贵的反馈。 \n我们会尽快回复您。",
  },
  languageSetting: {
    title: "语言设置",
    setting: "设置",
    language_preference: "语言选项",
    language_preference_opt1: "请选择您的语言选项",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "请选择语言选项",
    submit: "提交",
  },
};
