import React, { useState } from "react";
import { View, Text, TouchableOpacity, SafeAreaView, ActivityIndicator, ScrollView, StyleSheet, TextInput } from "react-native";
import MobileHeader from "../components/mHeader";
import InsideHead from "../components/insideHeader";
import Footer from "../components/footer";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import IntlPhoneInput from "../components/IntlPhoneInput";
import i18n from "../components/i18n";
import RNPickerSelect from "react-native-picker-select";
import Captcha from "../components/captcha";

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [resetPwType, setResetPwType] = useState("");
  const [resetPwTypeErr, setResetPwTypeErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [recaptchaErr, setRecaptchaErr] = useState(false);
  const [step, setStep] = useState(1);

  const goStep = (step) => {
    let errors = [];
    setIsLoading(false);
    setResetPwTypeErr(false);
    setPhoneErr(false);
    setEmailErr(false);

    if (step == 2) {
      setIsLoading(true);

      if (resetPwType == "") {
        errors.push("err");
        setResetPwTypeErr(true);
      }

      if (!errors.length) {
        setStep(step);
      }

      setIsLoading(false);
    }

    if (!errors.length) {
      setStep(step);
    }
  };

  const submitForget = () => {
    let errors = [];
    setIsLoading(true);
    setRecaptchaErr(false);
    setResetPwTypeErr(false);
    setPhoneErr(false);
    setEmailErr(false);

if (!recaptchaValid) {
      setRecaptchaErr(true);
      setIsLoading(false);
    } else {
    if (resetPwType == "") {
      errors.push("err");
      setResetPwTypeErr(true);
      setIsLoading(false);
    }

    if (resetPwType == "email") {
      if (!validEmail(email) || email == "") {
        setEmailErr(true);
        setIsLoading(false);
      }

      if (!errors.length) {
        let data = {
          source: "email",
          email: email,
        };

        forgetPassword(data, (forget) => {
          console.log(forget);
          if (forget.status == "success") {
            setStep(3);
            setIsLoading(false);
          } else {
            alert(forget.message);
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    }

    if (resetPwType == "phone") {
      // if (!validPhone) {
      //   errors.push("err");
      //   setPhoneErr(true);
      //   setIsLoading(false);
      // }

      if (!errors.length) {
        let data = {
          source: "mobile",
          mobile: dialCode + phone.replace(/\s/g, ""),
        };

        forgetPassword(data, (forget) => {
          console.log(forget);
          if (forget.status == "success") {
            setStep(3);
            setIsLoading(false);
          } else {
            alert(forget.message);
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    }
    }
  };

  const forgetPassword = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "forgotPass");

    if (resetPwType == "email") {
      formData.set("source", data.source);
      formData.set("email", data.email);
    }

    if (resetPwType == "phone") {
      formData.set("source", data.source);
      formData.set("mobile", data.mobile);
    }

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const onChangeText = ({ dialCode, unmaskedPhoneNumber, phoneNumber, isVerified }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setPhone(unmaskedPhoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const validEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkCaptcha = (status) => {
    setRecaptchaValid(status);
  };

  return (
    <SafeAreaView className="bg-white md:bg-[#F5F5F5] h-full min-h-full" pointerEvents={!isLoading ? "auto" : "none"}>
      {isLoading && (
        <View
          className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          style={{ position: "fixed" }}
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHead />
      <MobileHeader title={i18n.t("forgetPassword.title")} backTo={"Login"} />
      <ScrollView className="w-full flex-1 h-auto">
        <View className="w-full px-4 md:w-4/5 md:max-w-3xl md:h-auto md:m-auto md:bg-white md:p-5 md:mt-6 mb-10">
          <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson">{i18n.t("forgetPassword.reset_password")}</Text>
          <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

          {step == 1 && (
            <>
              <Text className="text-sm text-[#494949] mt-4 mb-3 font-NunitoSans">{i18n.t("forgetPassword.forgot_password")}</Text>

              <RNPickerSelect
                value={resetPwType}
                onValueChange={(value) => setResetPwType(value)}
                items={[
                  {
                    label: i18n.t("forgetPassword.by_mobile"),
                    value: "phone",
                  },
                  {
                    label: i18n.t("forgetPassword.by_email"),
                    value: "email",
                  },
                ]}
                placeholder={{
                  label: i18n.t("forgetPassword.reset_pw_method"),
                  value: "",
                }}
                style={pickerSelectStyles}
                useNativeAndroidPickerStyle={false}
              />

              {resetPwTypeErr && <Text className="text-red-500">{i18n.t("forgetPassword.reset_pw_method")}</Text>}

              <TouchableOpacity className="w-full h-10 max-h-10 bg-brandgold mt-4" onPress={() => goStep(2)}>
                <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">{i18n.t("forgetPassword.next")}</Text>
              </TouchableOpacity>
            </>
          )}

          {step == 2 && (
            <>
              {resetPwType == "email" && (
                <View>
                  <Text className="text-sm text-[#494949] mt-4 mb-3 font-NunitoSans">{i18n.t("forgetPassword.email")}</Text>
                  <TextInput
                    placeholder={i18n.t("forgetPassword.email_input")}
                    className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                    value={email}
                    onChangeText={setEmail}
                  ></TextInput>

                  {emailErr && <Text className="text-red-500 my-1">{i18n.t("forgetPassword.email_errMsg")}</Text>}
                </View>
              )}

              {resetPwType == "phone" && (
                <View>
                  <Text className="text-sm text-[#494949] mt-4 mb-3 font-NunitoSans">{i18n.t("forgetPassword.mobile")}</Text>

                  <IntlPhoneInput
                    onChangeText={onChangeText}
                    defaultCountry={API_REGION}
                    containerStyle={{
                      flex: 1,
                      flexDirection: "row",
                      paddingHorizontal: 0,
                    }}
                    flagStyle={{ display: "none" }}
                    dialCodeTextStyle={{
                      color: "#86909C",
                      lineHeight: 34,
                      borderColor: "#F2F3F5",
                      backgroundColor: "#F2F3F5",
                      paddingHorizontal: 10,
                    }}
                    phoneInputStyle={{
                      color: "#86909C",
                      height: 34,
                      width: "100%",
                      borderWidth: 1,
                      borderColor: "#F2F3F5",
                      backgroundColor: "#F2F3F5",
                      paddingHorizontal: 15,
                    }}
                    placeholder={phone}
                  />

                  {phoneErr && <Text className="color-red-500 my-1">{i18n.t("forgetPassword.mobile_errMsg")}</Text>}
                </View>
              )}

              <View className="mt-5 mb-4">
                <Captcha returnStatus={checkCaptcha} />
                {recaptchaErr && <Text className="color-red-500 my-1">{i18n.t("signUp.captcha_errMsg")}</Text>}
              </View>

              <View className="flex flex-row flex-wrap items-center justify-between">
                <TouchableOpacity className="w-[40%] h-10 max-h-10 bg-gray-300 mt-4" onPress={() => goStep(1)}>
                  <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">{i18n.t("forgetPassword.back")}</Text>
                </TouchableOpacity>

                <TouchableOpacity className="w-[40%] h-10 max-h-10 bg-brandgold mt-4" onPress={() => submitForget()}>
                  <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">
                    {i18n.t("forgetPassword.reset_password2")}
                  </Text>
                </TouchableOpacity>
              </View>
            </>
          )}

          {step == 3 && (
            <>
              {resetPwType == "email" && (
                <Text className="text-sm text-[#494949] mt-4 mb-6 font-NunitoSans">
                  {i18n.t("forgetPassword.reset_password_sent_email")}
                </Text>
              )}

              {resetPwType == "phone" && (
                <Text className="text-sm text-[#494949] mt-4 mb-6 font-NunitoSans">
                  {i18n.t("forgetPassword.reset_password_sent_mobile")}
                </Text>
              )}
            </>
          )}
        </View>
      </ScrollView>
      <Footer />
    </SafeAreaView>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 38,
    fontSize: 15,
    lineHeight: 38,
    width: "100%",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  inputWeb: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
});

export default ForgetPassword;
