import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import i18n from "../components/i18n";
import Header from "../components/header"; // Web Header
import NewMobileHeader from "../components/newMobileHeader";
import Footer from "../components/footer";
import AsyncStorage from "../components/AsyncStorage";

const WelcomePage = () => {
  const navigation = useNavigation();

  // const [imageWidth, setImageWidth] = useState(Dimensions.get("window").width);
  // const [imageHeight, setImageHeight] = useState(Dimensions.get("window").height);

  const [lang, setLang] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // const handleResize = () => {
    //   setImageWidth(Dimensions.get("window").width);
    //   setImageHeight(Dimensions.get("window").height);
    // };

    // Dimensions.addEventListener("change", handleResize);

    AsyncStorage.getData("lang").then((lang) => setLang(lang));
    AsyncStorage.getData("mCode").then((value) => {
      if (value) {
        setIsLoggedIn(true);
      }
    });

    return () => {
      Dimensions.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <View className="h-full">
      {/* Web UI */}
      <View className="hidden md:flex h-screen">
        <ImageBackground
          source={require("../assets/login-bg.png")}
          resizeMode="cover"
          className="flex-1 items-center content-start w-full min-h-full"
        >
          <Header />

          <ImageBackground
            source={require("../assets/welcome-page-img-v2.jpg")}
            resizeMode="contain"
            className="flex h-80 justify-start items-center w-full bg-black"
          />

          {/* <ImageBackground
            source={require("../assets/welcome-page-img-v2.jpg")}
            resizeMode="cover"
            className="w-full h-auto min-h-full"
            style={[styles.image]}
          /> */}

          <View className="min-h-100 bg-[#BD9B60] w-full">

            <View className="max-w-3xl m-auto">
              <View className="flex items-center justify-center mt-6 mb-3 mx-10">
                <Text className="text-white font-semibold text-center text-xl uppercase font-Crimson">
                  {i18n.t("welcomePage.memberYet")}
                </Text>
              </View>

              <View className="flex my-2items-center justify-center my-6 mx-10">
                <Text className="text-white text-center text-normal leading-5 font-Crimson">
                  {i18n.t("welcomePage.memberBenefit")}
                </Text>
              </View>

              <View className="flex mt-3 mb-6 mx-8 items-start text-[#484849]">
                <Text className="text-black font-normal font-Crimson">
                  {i18n.t("welcomePage.memberPrivileges")}:
                </Text>
                <Text className="text-black my-1 font-Crimson">
                  * {i18n.t("welcomePage.privilege_txt1")}
                </Text>
                <Text className="text-black my-1 font-Crimson">
                  * {i18n.t("welcomePage.privilege_txt2")}
                </Text>
                <Text className="text-black my-1 font-Crimson">
                  * {i18n.t("welcomePage.privilege_txt3")}
                </Text>
                <Text className="text-black my-1 font-Crimson">
                  * {i18n.t("welcomePage.privilege_txt4")}
                </Text>
                {lang === "en" && (
                  <Text className="text-black my-1 font-Crimson">
                    * {i18n.t("welcomePage.privilege_txt5")}
                  </Text>
                )}
              </View>

              <View className="flex items-center justify-center">
                <View className="flex items-center justify-center bg-black mx-6 mb-5 w-[200px]">
                  <TouchableOpacity className="w-full h-10" onPress={() => navigation.push("SignUp")}>
                    <Text className="text-white w-full leading-10 text-center font-semibold font-Crimson">
                      {i18n.t("welcomePage.signUpNow")}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View className="flex items-center justify-center bg-black mx-6 mb-10 w-[200px]">
                  <TouchableOpacity className="w-full h-10" onPress={() => navigation.push("Login")}>
                    <Text className="text-white w-full leading-10 text-center font-semibold font-Crimson">
                      {i18n.t("welcomePage.login")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>



            <Footer />
          </View>
        </ImageBackground>
      </View>

      {/* Mobile UI only */}
      <View className="md:hidden flex-1 w-full md:bg-transparent">
        <NewMobileHeader title={i18n.t("profile.title")} />

        <ScrollView className="flex-1">
          <ImageBackground
            source={require("../assets/welcome-page-img-v2.jpg")}
            resizeMode="cover"
            className="flex h-52 justify-start items-center w-full"
          />

          <View className="min-h-100 bg-[#BD9B60]">
            <View className="flex items-center justify-center mt-6 mb-3 mx-2">
              <Text className="text-white font-semibold text-center text-xl uppercase font-Crimson">
                {i18n.t("welcomePage.memberYet")}
              </Text>
            </View>

            <View className="flex my-2 mx-4 items-center justify-center">
              <Text className="text-white text-center text-normal leading-5 font-Crimson">
                {i18n.t("welcomePage.memberBenefit")}
              </Text>
            </View>

            <View className="flex my-6 mx-8 items-start text-[#484849]">
              <Text className="text-black font-normal font-Crimson">
                {i18n.t("welcomePage.memberPrivileges")}:
              </Text>
              <Text className="text-black my-1 font-Crimson">
                * {i18n.t("welcomePage.privilege_txt1")}
              </Text>
              <Text className="text-black my-1 font-Crimson">
                * {i18n.t("welcomePage.privilege_txt2")}
              </Text>
              <Text className="text-black my-1 font-Crimson">
                * {i18n.t("welcomePage.privilege_txt3")}
              </Text>
              <Text className="text-black my-1 font-Crimson">
                * {i18n.t("welcomePage.privilege_txt4")}
              </Text>
              {lang === "en" && (
                <Text className="text-black my-1 font-Crimson">
                  * {i18n.t("welcomePage.privilege_txt5")}
                </Text>
              )}
            </View>

            <View className="flex items-center justify-center bg-black mx-6 mb-5">
              <TouchableOpacity className="w-full h-10" onPress={() => navigation.push("SignUp")}>
                <Text className="text-white w-full leading-10 text-center font-semibold font-Crimson">
                  {i18n.t("welcomePage.signUpNow")}
                </Text>
              </TouchableOpacity>
            </View>
            <View className="flex items-center justify-center bg-black mx-6 mb-10">
              <TouchableOpacity className="w-full h-10" onPress={() => navigation.push("Login")}>
                <Text className="text-white w-full leading-10 text-center font-semibold font-Crimson">
                  {i18n.t("welcomePage.login")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <Footer />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: "start",
    alignItems: "center"
  },
});

export default WelcomePage;
